// import React, { useState, useEffect, useRef } from "react";
// import { useField } from "formik";

// interface Option {
//   id: number;
//   country_name: string;
//   country_code: string;
// }

// interface DropdownProps {
//   options: Option[];
//   name: string; // Add name prop for Formik
// }

// const CustomSearchDropdown: React.FC<DropdownProps> = ({ options, name }) => {
//   const [searchTerm, setSearchTerm] = useState<string>("");
//   const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
//   const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
//   const [highlightedIndex, setHighlightedIndex] = useState<number>(-1); // Track highlighted option
//   const dropdownRef = useRef<HTMLDivElement>(null);

//   const [field, , helpers] = useField(name);
//   const { setValue } = helpers;

//   useEffect(() => {
//     const handleClickOutside = (event: MouseEvent) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
//         setIsDropdownOpen(false);
//         setHighlightedIndex(-1);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const term = e.target.value;
//     setSearchTerm(term);
//     setFilteredOptions(
//       options.filter((option) =>
//         option.country_name.toLowerCase().includes(term.toLowerCase())
//       )
//     );
//     setIsDropdownOpen(true);
//     setHighlightedIndex(-1);
//   };

//   const handleOptionSelect = (option: Option) => {
//     setSearchTerm(option.country_name);
//     setIsDropdownOpen(false);
//     setValue(option.id); // Set Formik's field value to option.id
//   };

//   const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
//     if (e.key === "ArrowDown") {
//       e.preventDefault();
//       setIsDropdownOpen(true);
//       setHighlightedIndex((prevIndex) =>
//         prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0
//       );
//     } else if (e.key === "ArrowUp") {
//       e.preventDefault();
//       setHighlightedIndex((prevIndex) =>
//         prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1
//       );
//     } else if (e.key === "Enter" && highlightedIndex >= 0) {
//       e.preventDefault();
//       handleOptionSelect(filteredOptions[highlightedIndex]);
//     }
//   };

//   return (
//     <div className="dropdown-container" style={{ position: "relative" }} ref={dropdownRef}>
//       <input
//         type="text"
//         value={searchTerm}
//         onChange={handleSearchChange}
//         onKeyDown={handleKeyDown} // Handle key events
//         onFocus={() => setIsDropdownOpen(true)}
//         placeholder="Search..."
//       />
//       {isDropdownOpen && filteredOptions.length > 0 && (
//         <ul className="dropdown-list" >
//           {filteredOptions.map((option, index) => (
//             <li
//               key={option.id}
//               onClick={() => handleOptionSelect(option)}
//               style={{
//                 padding: "8px",
//                 cursor: "pointer",
//                 backgroundColor: index === highlightedIndex ? "#ddd" : "#fff" // Highlight the option
//               }}
//             >
//               {option.country_name}
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// export default CustomSearchDropdown;
// import React, { useState, useEffect, useRef } from "react";
// import { useField } from "formik";

// interface Option {
//   id: number;
//   name: string; // Generic name property
//   [key: string]: any; // Allow for additional properties if needed
// }

// interface DropdownProps {
//   options: Option[];
//   name: string; // Formik field name
//   placeholder?: string; // Optional placeholder for the input field
//   nameField: string; // Field name to display in the dropdown
// }

// const CustomSearchDropdown: React.FC<DropdownProps> = ({
//   options,
//   name,
//   placeholder = "Search...",
//   nameField
// }) => {
//   const [searchTerm, setSearchTerm] = useState<string>("");
//   const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
//   const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
//   const [highlightedIndex, setHighlightedIndex] = useState<number>(-1); // Track highlighted option
//   const dropdownRef = useRef<HTMLDivElement>(null);

//   const [field, , helpers] = useField(name);
//   const { setValue } = helpers;

//   useEffect(() => {
//     const handleClickOutside = (event: MouseEvent) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
//         setIsDropdownOpen(false);
//         setHighlightedIndex(-1);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const term = e.target.value;
//     setSearchTerm(term);
//     setFilteredOptions(
//       options.filter((option) =>
//         option[nameField].toLowerCase().includes(term.toLowerCase())
//       )
//     );
//     setIsDropdownOpen(true);
//     setHighlightedIndex(-1);
//   };

//   const handleOptionSelect = (option: Option) => {
//     setSearchTerm(option[nameField]);
//     setIsDropdownOpen(false);
//     setValue(option.id); // Set Formik's field value to option.id
//   };

//   const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
//     if (e.key === "ArrowDown") {
//       e.preventDefault();
//       setIsDropdownOpen(true);
//       setHighlightedIndex((prevIndex) =>
//         prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0
//       );
//     } else if (e.key === "ArrowUp") {
//       e.preventDefault();
//       setHighlightedIndex((prevIndex) =>
//         prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1
//       );
//     } else if (e.key === "Enter" && highlightedIndex >= 0) {
//       e.preventDefault();
//       handleOptionSelect(filteredOptions[highlightedIndex]);
//     }
//   };

//   return (
//     <div className="dropdown-container" style={{ position: "relative" }} ref={dropdownRef}>
//       <input
//         type="text"
//         value={searchTerm}
//         onChange={handleSearchChange}
//         onKeyDown={handleKeyDown} // Handle key events
//         onFocus={() => setIsDropdownOpen(true)}
//         placeholder={placeholder}
//       />
//       {isDropdownOpen && filteredOptions.length > 0 && (
//         <ul className="dropdown-list" >
//           {filteredOptions.map((option, index) => (
//             <li
//               key={option.id}
//               onClick={() => handleOptionSelect(option)}
//               style={{
//                 padding: "8px",
//                 cursor: "pointer",
//                 backgroundColor: index === highlightedIndex ? "#ddd" : "#fff" // Highlight the option
//               }}
//             >
//               {option[nameField]}
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// export default CustomSearchDropdown;


import React from "react";
import Select ,{SingleValue} from "react-select";
import { useField, useFormikContext } from "formik";

interface Option {
  value: string | number;
  label: string;
}

interface CustomSelectProps {
  label?: string;
  name: string;
  options: Option[];
  className?: string;
  value?: SingleValue<Option>;
  onChange?: (
    selectedOption: SingleValue<Option>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => void;
}

const FormikCustomSearchDropdown: React.FC<CustomSelectProps> = ({
  label,
  options,
  className,
  onChange,
  value,
  ...props
  
}) => {
  
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  const handleChange = (option: SingleValue<Option>) => {
    setFieldValue(props.name, option ? (option as Option).value : "");
    if (onChange) {
      onChange(option, setFieldValue);
    }
  };
  const defaultValue = options.find(option => option.value === 101);
  const selectedOption = options.find(option => option.value === field.value);
  
  return (
    <div>
      {label && <label>{label}</label>}
      <Select
        {...field}
        {...props}
        options={options}
        className={className}
        onChange={handleChange}
        onBlur={() => field.onBlur({ target: { name: props.name } })}
        // value={field.value || defaultValue ? defaultValue : field.value }
        value={selectedOption || defaultValue  || value || null} 

        styles={{
            control: (provided) => ({
              ...provided,
              minHeight: '45px',  // Increase the minimum height
              fontSize: '16px',   // Increase font size
            }),
          // valueContainer: (provided) => ({
          //   ...provided,
          //   minHeight: '30px',  // Ensure the value container has the same height
          // }),
          // singleValue: (provided) => ({
          //   ...provided,
          //   fontSize: '30px',   // Increase font size for the selected value
          // }),
          // input: (provided) => ({
          //   ...provided,
          //   minHeight: '30px',  // Ensure the input area has the same height
          //   fontSize: '16px',   // Increase font size for the input text
          // }),
          // placeholder: (provided) => ({
          //   ...provided,
          //   fontSize: '16px',   // Increase font size for the placeholder text
          // }),
        }}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default FormikCustomSearchDropdown;