import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { formatDate } from "../../../common/SharedFunction";
import { companyTeamListRemove, fetchCompanyTeamApi, ICompanyTeam } from "./ListCompanyController";
import ConfirmationModal from "../../../components/model/ConfirmationModal";

interface IPropsCompany {
  isCompanyOpen: boolean;
  closeCompany: () => void;
  companyInfo: any;
}

const MyCompanyList = ({
  isCompanyOpen,
  closeCompany,
  companyInfo,
}: IPropsCompany) => {
  //   const { darkMode, toggleTheme } = useTheme();
  const dropdownCompanyListRef = useRef<
    Record<number, HTMLUListElement | null>
  >({});
  const [companyTeamLists, setCompanyTeamLists] = useState<ICompanyTeam[]>([]);
  const [companyTeamListDropdownOpen, setCompanyTeamListDropdownOpen] =
    useState<any>(null);
  const [hasOneData, setHasOneData] = useState<number>();
  const [isRemoveCompanyTeamListConfirmation, setIsRemoveCompanyTeamListConfirmation] = useState(false);
  const [companyTeamListId, setCompanyTeamListId] = useState<number>();
  //   const [sourceOfTypeInput, setSourceOfTypeInput] = useState("");
  //   const [sourceOfTypesHexColorInput, setSourceOfTypesHexColorInput] =
  //     useState("");
  //   //   const sourceOfTypesRefDropdown = useRef<HTMLButtonElement>(null);
  //   const [loading, setLoading] = useState(false);
  //   const [refersh, setRefresh] = useState(false);
  //   const [isJoinConfirmation, setIsJoinConfirmation] = useState(false);
  //   const [showCompany, setShowCompany] = useState(false);
  //   const [isCreateCompany, setIsCreateCompany] = useState(false);

  useEffect(() => {
    // if (isSourceOfTypeOpen) {
    fetchCompanyTeamApi(setCompanyTeamLists, companyInfo.id);
    // }
  }, [companyInfo.id]);

  const toggleDropdownCompanyList = (id: number) => {
    setHasOneData(id);
    setCompanyTeamListDropdownOpen((prevId: any) =>
      prevId === id ? null : id
    );
  };
  const handleClickOutside = (event: { target: any }) => {
    const clickedOutside = Object.values(dropdownCompanyListRef.current).every(
      (ref) => ref && !ref.contains(event.target)
    );
    if (clickedOutside) {
      setCompanyTeamListDropdownOpen(null);
    }
  };
  useEffect(() => {
    if (companyTeamListDropdownOpen !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [companyTeamListDropdownOpen]);
  const handelRemoveCompanyTeamList = (id:number) => {
    setCompanyTeamListId(id)
    setIsRemoveCompanyTeamListConfirmation(true)
  } 
  return (
    <>
      {isCompanyOpen ? (
        <>
          <div
            className="notifications animate__animated animate__fadeInLeft"
            id="notifications"
          >
            {/* <!-- Header --> */}

            <div className="header-Chat justify-content-between">
              {/* <!-- Icons --> */}
              <div className="d-flex ">
                <div className="ICON">
                  <div
                    aria-disabled="false"
                    role="button"
                    className="icons"
                    data-tab="2"
                    title="New chat"
                    aria-label="New chat"
                    onClick={closeCompany}
                  >
                    <span data-testid="chat" data-icon="chat" className="">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className=""
                      >
                        <path
                          fill="currentColor"
                          d="m12 4 1.4 1.4L7.8 11H20v2H7.8l5.6 5.6L12 20l-8-8 8-8z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>

                <div className="newText">
                  <h2> {companyInfo.company_name} Team List  <p style={{fontSize:"14px" , fontWeight:"300"}}> key : {companyInfo.invitation_key}</p></h2>
                 
                </div>
              </div>
              {/* <div
                className="newText text-end"
                data-tab="2"
                role="button"
                // onClick={() => setIsJoinConfirmation(true)}
              >
                <h2> Join Company</h2>
              </div> */}
            </div>

            {/* <!-- Chats --> */}
            <div className="chats-notifications">
              {/* <!-- Chats 1 --> */}
              <div className="block">
                {/* <!-- Text --> */}
                <div className="h-text">
                  <div className="chats">
                    <>
                      {/* {loading
                        ? // Render skeleton placeholders when loading
                          Array.from({ length: 12 }).map((_, index) => (
                            <div className="block chat-list" key={index}>
                              <Skeleton
                                width={50}
                                height={50}
                                circle={true}
                                duration={5}
                                style={{ opacity: darkMode ? "" : 0.5 }}
                              />
                              <div className="h-text">
                                <div className="head">
                                  <h4>
                                    <Skeleton
                                      style={{
                                        marginLeft: "10px",
                                        opacity: darkMode ? "" : 0.5,
                                      }}
                                      width={100}
                                    />
                                  </h4>
                                  <p className="time">
                                    <Skeleton
                                      width={80}
                                      style={{ opacity: darkMode ? "" : 0.5 }}
                                      height={10}
                                    />
                                  </p>
                                </div>
                                <div className="message-chat">
                                  <div className="chat-text-icon">
                                    <span className="thanks">
                                      <Skeleton
                                        style={{
                                          marginLeft: "10px",
                                          opacity: darkMode ? "" : 0.5,
                                        }}
                                        width={100}
                                      />
                                    </span>
                                    <div className="icon-more">
                                      <Skeleton
                                        width={40}
                                        style={{
                                          opacity: darkMode ? "" : 0.5,
                                        }}
                                        height={10}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : // Render actual user data when not loading */}
                      {companyTeamLists &&
                        companyTeamLists.map((item, index) => (
                          <>
                            <div>
                              <ul
                                className={`labelDropLeft ${
                                  hasOneData === item.id &&
                                  companyTeamListDropdownOpen
                                    ? "isVisible"
                                    : "isHidden"
                                } `}
                                ref={(el) =>
                                  (dropdownCompanyListRef.current[item.id] = el)
                                }
                              >
                                <li
                                  className="listItem"
                                  role="button"
                                  onClick={() => handelRemoveCompanyTeamList(item.id)}
                                >
                                  Remove
                                </li>
                              </ul>
                            </div>
                            <button
                              // key={index}
                              className="block chat-list"
                              //   onClick={() => openCompanySide(item)}
                            >
                              <div
                                className="imgBox"
                                style={{ backgroundColor: "#CFCFCF" }}
                              >
                                <div
                                  className=" text-uppercase"
                                  style={{ paddingTop: "12px" }}
                                >
                                  {/* {item.person_name[0] +
                                        item.person_name[1]} */}
                                </div>
                              </div>
                              <div className="h-text">
                                <div className="head">
                                  <h4>{item.username}</h4>
                                  <p className="time">
                                    {item.created_date_time
                                      ? formatDate(item.created_date_time)
                                      : ""}
                                  </p>
                                </div>
                                <div className="message-chat">
                                  <div className="tick-icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      width="20"
                                      height="20"
                                      aria-label="read"
                                      className="white-tick"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                                      ></path>
                                    </svg>
                                  </div>
                                  {/* EMAIL,CONTARY,STATE,CITY,NUMBER */}
                                  <div className="chat-text-icon">
                                    {/* <div className="d-flex"> */}
                                    <span className="thanks">
                                      {companyInfo.company_contact}
                                      {companyInfo.company_contact ? "," : ""}
                                    </span>
                                    <span className="thanks">
                                      {companyInfo.company_email}
                                      {companyInfo.company_email ? "," : ""}
                                    </span>
                                    {/* </div> */}
                                  </div>

                                  <button
                                    className="icon-more"
                                    onClick={() =>
                                      toggleDropdownCompanyList(item.id)
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 19 20"
                                      width="19"
                                      height="20"
                                      className="hide animate__animated animate__fadeInUp"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M3.8 6.7l5.7 5.7 5.7-5.7 1.6 1.6-7.3 7.2-7.3-7.2 1.6-1.6z"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </button>
                          </>
                        ))}
                    </>
                    {/* {searchTerm && noDataFound && (
                        <p className="no_found">No data found</p>
                      )} */}
                  </div>
                </div>
              </div>
            </div>
            {/* {isDeleteConfirmation && (
            <ConfirmationModal
              show={isDeleteConfirmation}
              onHide={() => setIsDeleteConfirmation(false)}
              handleSubmit={() =>
                handleDeleteSourceType(
                  hasIdAvail,
                  setIsDeleteConfirmation,
                  setSourceOfTypesLists
                )
              }
              title={"Delete this Source Of Type"}
              message={"Are you sure you want delete is Source Of Type? "}
              btn1="CANCEL"
              btn2="DELETE"
            />
          )} */}
          </div>
          {isRemoveCompanyTeamListConfirmation && (
            <ConfirmationModal
              show={isRemoveCompanyTeamListConfirmation}
              onHide={() => setIsRemoveCompanyTeamListConfirmation(false)}
              handleSubmit={() =>
                companyTeamListRemove(companyTeamListId,setIsRemoveCompanyTeamListConfirmation ,setCompanyTeamLists ,companyInfo.id)
              }
              title={"Delete this Team member"}
              message={"Are You Sure You Want To Remove This Team member?"}
              btn1="CANCEL"
              btn2="DELETE"
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default MyCompanyList;
