import { toast, ToastContainer } from "react-toastify";
import "./App.css";
import { ThemeProvider } from "./components/ThemeContext";
import RoutesIndex from "./Routes/RoutesIndex";
import { useEffect, useState } from "react";
import axiosInstance from "./services/axiosInstance";
import { MESSAGE_UNKNOWN_ERROR_OCCURRED } from "./helpers/AppConstants";
function App() {


  // const [timestamp, setTimestamp] = useState(true);
  // const [checkToken, setCheckToken] = useState(false);

  // useEffect(() => {
    // Update timestamp every second using setTimeout
    // const token = localStorage.getItem("token");
    // const LoginSubmit = async () => {
    //   try {
    //     const response = await axiosInstance.get("onLoad", {
    //       headers: token ? { Authorization: `${token}` } : {},
    //     });
    //     if (response.data.ack === 1) {
    //       setCheckToken(false);
    //     } else {
    //       setCheckToken(true);
    //       localStorage.clear();
    //     }
    //   } catch (error: any) {
    //     if (error.response && error.response.status === 401) {
    //       setCheckToken(true); // Set checkToken to true for 401 Unauthorized
    //       // Optionally, handle any other logic for 401 Unauthorized here
    //     } else {
    //       toast.error(
    //         error.response.data.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
    //       );
    //     }
    //   }
    // };
    // LoginSubmit();
    // if (!token || token === "" || null) {
    //   setCheckToken(true);
    // } else {
    // }

  //   const interval = setInterval(() => {
  //     setTimestamp(false);
  //   }, 1000);

  //   // Clean up the interval to avoid memory leaks
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <>
      <ThemeProvider>
      {/* {timestamp ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="  border ">
            <img
              width={300}
              src={require("./assets/images/smalll_office_log.png")}
              alt=""
            />
          </div>
        </div>
      ) : (
        <> */}
        <div className="body">
          <div className="container main ">
            <RoutesIndex />
          </div>
        </div>
        <ToastContainer />
      </ThemeProvider>
    </>
  );
}

export default App;
