import React, { useState } from "react";
import Select, { SingleValue } from "react-select";

interface Option {
  value: string | number;
  label: string;
}

interface CustomSearchDropdownProps {
  options: Option[];
  value: SingleValue<Option>;
  onChange: (selectedOption: SingleValue<Option>) => void;
  className?: string;
}

const CustomSearchDropdown: React.FC<CustomSearchDropdownProps> = ({
  options,
  value,
  onChange,
  className,
}) => {
  const handleChange = (option: SingleValue<Option>) => {
    onChange(option);
  };

  return (
    <Select
      options={options}
      value={value}
      onChange={handleChange}
      className={className}
      styles={{
        control: (provided) => ({
          ...provided,
          minHeight: "10px", // Increase the minimum height
          fontSize: "16px", // Increase font size
        }),
      }}
    />
  );
};

export default CustomSearchDropdown
