import * as Yup from "yup";
import axiosInstance from "../../../services/axiosInstance";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../helpers/AppConstants";
import { toast } from "react-toastify";
import { TReactSetState } from "../../../helpers/AppType";
import { checkDuplication } from "../../../common/SharedFunction";

export interface ICreateInquiry {
  product_id: string;
  category_id: string;
  description: number | string;
  qty: string;
  source_type_id: string;
  label_id?: string;
  static: string;
}

export const createInquiryInitialValues = (
  inquiryToEdit: ICreateInquiry | undefined
): ICreateInquiry => ({
  product_id: inquiryToEdit?.product_id || "",
  category_id: inquiryToEdit?.category_id || "",
  description: inquiryToEdit?.description || " ",
  qty: inquiryToEdit?.qty || "",
  // label_id: inquiryToEdit?.label_id || "",
  static: inquiryToEdit?.static || "0",
  source_type_id: inquiryToEdit?.source_type_id || "",
});

export const createInquiryValidationSchema = () =>
  Yup.object().shape({
    // product_id: Yup.string()
    //   // .max(70, t("validationEmailMax"))
    //   // .email(t("validationEmail"))
    //   .required("Product  is Required"),
    description: Yup.string()
      // .max(15, "Max mobile number 15")
      // .min(10, "Min mobile number 10")
      .required("Description is Required"),
    // source_type_id:Yup.string()
    // .required("its reef")
    // // country: Yup.string().required("Country is Required"),
    // // state: Yup.string().required("State is Required"),
    // description: Yup.string().email("Please write proper email "),
  });

export const createInquiry = async (
  values: ICreateInquiry,
  setContact:any,
  contact_id: number
) => {
  // if (
  //   !(await checkDuplication(
  //     values.mobile_number,
  //     "contact_masters",
  //     "mobile_number"
  //   ))
  // ) {

  const getUUID = await localStorage.getItem("UUID");
  const requestData = { ...values, a_application_login_id: getUUID };
  // console.log("requestData", requestData);

  try {
    // setContact(false);
    const requestData = {
      table: "inquiries",
      data: `{"product_id":"${values.product_id}","category_id":"${
        values.category_id
      }", "description":"${values.description}","qty":"${
        values.qty
      }","source_type_id": "${values.source_type_id}","static":"${
        values.static
      }","contact_master_id":"${contact_id}","a_application_login_id":${Number(
        getUUID
      )}}`,
    };

    const { data } = await axiosInstance.post("commonCreate", requestData);
    if (data.code === 200) {
      if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {

        setContact(true);
        // console.log("data", data);
      } else {
        toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (error: any) {
    
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
  // } else {
  //   toast.error("Contact number is already used");
  // }
};

export const updateInquiry = async (
  values: ICreateInquiry,
  setContact: any,
  contactId: any,
  contact_id: number
) => {
  const getUUID = await localStorage.getItem("UUID");

  const requestData = {
    table: "inquiries",
    where: `{"id":${contactId?.id}}`,
    data: `{"product_id":"${values.product_id}","category_id":"${
      values.category_id
    }", "description":"${values.description}","qty":"${
      values.qty
    }","source_type_id": "${values.source_type_id}","static":"${
      values.static
    }"}`,
  };
  setContact(false);
  try {
    const data = await axiosInstance.post("commonUpdate", requestData);

    if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
      setContact(true);
    } else {
      toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
