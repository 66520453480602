import { toast } from "react-toastify";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../../helpers/AppConstants";
import { TReactSetState } from "../../../../helpers/AppType";
import axiosInstance from "../../../../services/axiosInstance";

export interface IReminderList {
  id: number;
  reminder_data_time: string;
  remark: string;
  status:number
  person_name:string
  contact_message:string
}

export const fetchReminderApi = async (
  setReminderList: TReactSetState<IReminderList[]>,
  searchDate:string
) => {
  const token = await localStorage.getItem("token");
  const getUUID = await localStorage.getItem("UUID");

  try {
    const { data } = await axiosInstance.post(
      "reminder",
      {
        application_login_id: Number(getUUID),
        searchDate :searchDate
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    if (data.code === 200) {
      if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        setReminderList(data.data.item);
      } else {
        toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
    //   setNoDataFound(data.data.item.length === 0);
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};


export const handleDeleteReminder = async (
  reminderId: number | undefined,
  setIsDeleteConfirmation: TReactSetState<boolean>,
  setReminderList: TReactSetState<IReminderList[]>
) => {
  const requestData = {
    table: "reminder_messages",
    where: `{"id":${reminderId}}`,
    data: `{"isDelete":"1"}`,
  };
  try {
    const data = await axiosInstance.post("commonUpdate", requestData);
    if (data.data.code === 200) {
      if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        setIsDeleteConfirmation(false);
        fetchReminderApi(setReminderList ,"");
      } else {
        toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};



export const createRescheduleReminder = async (
  reminderId:number | undefined,
  insertObj: any,
  setIsReminderConfirmation: TReactSetState<boolean>,
  setLoading:TReactSetState<boolean>,
  setReminderList:TReactSetState<IReminderList[]>
) => {

  const date = new Date(insertObj.dateTime);
  
  const formattedDateTime = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
    date.getHours()
  ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(
    date.getSeconds()
  ).padStart(2, "0")}`;

  const requestData = {
    table: "reminder_messages",
    where: `{"id":"${reminderId}"}`,
    data: JSON.stringify({
      reminder_data_time: formattedDateTime,
      remark: insertObj.remark,
      status:"0"
    }),
  };
  setLoading(false)
  try {
    // setIsReminderConfirmation(false)
    const data = await axiosInstance.post("commonUpdate", requestData);
    if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
      setIsReminderConfirmation(false);
      setLoading(true)
      fetchReminderApi(setReminderList ,"");
    } else {
      toast.error(data.data.ack_msg);
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  } finally {
    setLoading(false);
    // setTimeout(() => {
    //   setLoading(false); // Set loading to false after minimum time
    // }, 1000);
  }
};
