import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTheme } from "../../../components/ThemeContext";
import axios from "axios";
import axiosInstance from "../../../services/axiosInstance";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../helpers/AppConstants";
import { toast } from "react-toastify";
import CustomEditor from "../../../components/CustomEditor";
import EmailCustomEditor from "../../../components/EmailCustomEditor";
import { TReactSetState } from "../../../helpers/AppType";

const EmailSendView = ({
  show,
  onHide,
  title,
  btn1 = "Yes",
  btn2 = "No",
  contactInfo,
  setLoading
}: {
  show: boolean;
  onHide: () => void;
  title: string;
  btn1: string;
  btn2: string;
  contactInfo: any;
  setLoading:TReactSetState<boolean>
}) => {
  const { darkMode } = useTheme();
  const MAX_FILE_SIZE_MB = 1; // Maximum file size in MB
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert MB to bytes
  const modalThemeClass = darkMode ? "modal-light-1" : "modal-light-1";
  const modalThemeClass1 = darkMode ? "modal-dark" : "modal-light-1";
  const [editorContent, setEditorContent] = useState<string>("");
  const [email, setEmail] = useState(contactInfo?.email_id || "");
  const [ccEmail, setCcEmail] = useState(""); // New CC email state
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState<File | null>(null);
  const [status, setStatus] = useState("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const [ccEmailError, setCcEmailError] = useState<string | null>(null);
  console.log("Attachment:", attachment ? attachment.name : "No attachment");

  // Email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Email validation function
  const validateEmail = (
    value: string,
    setError: React.Dispatch<React.SetStateAction<string | null>>
  ) => {
    if (value && !emailRegex.test(value)) {
      setError("Invalid email address");
    } else {
      setError(null);
    }
  };

  // Handle file change
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("rrrrrr", e);

    // return true;
    if (e.target.files && e.target.files[0]) {
      setAttachment(e.target.files[0]); // Save selected file to state
    }
  };

  // Handle form submission
  const handleSubmit1 = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Prepare form data
    setLoading(false)
    if (message.trim()) {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("ccEmail", ccEmail); // Add CC email to form data
      formData.append("subject", subject);
      formData.append("message", message);

      // Check if attachment exists before processing it
      if (attachment) {
        const validTypes = ["image/png", "image/jpeg", "application/pdf"];
        const fileType = attachment.type;

        // Validate attachment type
        if (!validTypes.includes(fileType)) {
          toast.error(
            "Invalid file format. Please upload a PNG, JPG, JPEG, or PDF file."
          );
          return;
        }

        // Validate attachment size
        if (attachment.size > MAX_FILE_SIZE_BYTES) {
          toast.error(
            `File is too large. Please upload a file smaller than ${MAX_FILE_SIZE_MB} MB.`
          );
          return;
        }

        // Add attachment to form data if all validations pass
        formData.append("attachment", attachment);
      }

      try {
        const response = await axios.post(
          "https://backend.smalloffice.in/api/send-email",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200 && message.trim()) {
          const getUUID = localStorage.getItem("UUID");

          if (!getUUID) {
            toast.error("UUID not found, please try again.");
            return;
          }

          // Prepare form data for messageAttachmentsUpload API
          const formData1 = new FormData();
          if (attachment) {
            formData1.append("file", attachment);
          }
          formData1.append("contact_masters_id", contactInfo?.id || "");
          formData1.append("a_application_id", getUUID);
          formData1.append("message_type_id", "1");
          formData1.append("description", message);

          try {
            const uploadResponse = await axios.post(
              "https://backend.smalloffice.in/api/messageAttachmentsUpload",
              formData1,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            if (uploadResponse.status === 200) {
              toast.success("Your mail was sent successfully.");
              // Clear form fields after success
              setLoading(true)
              setEmail("");
              setCcEmail("");
              setSubject("");
              setMessage("");
              setAttachment(null);
              onHide(); // Close modal
            } else {
              toast.error("An unexpected error occurred.");
            }
          } catch (uploadError: any) {
            toast.error(
              uploadError.response?.data?.message || "File upload failed."
            );
          }
        } else {
          toast.error("Message cannot be empty.");
        }
      } catch (emailError) {
        toast.error("Failed to send email.");
      }finally {
        setTimeout(() => {
          setLoading(false); // Set loading to false after minimum time
        }, 1000); // 1000 milliseconds (1 seconds)
      }
    } else {
      toast.error("Please fill Description and Email");
    }
  };

  // When Save is clicked, email sending logic is triggered
  const handleSave = (e: any) => {
    handleSubmit1(e);
  };
  const isSubmitDisabled = emailError !== null || email.trim() === "";
  // const handleEditorChange = (fieldName: string, html: string) => {
  //   if (contactInfo?.id) {
  //     setEditorContent("");
  //   } else {
  //     setEditorContent(html);
  //   }
  // };
  // const handleSend = async (html: string) => {
  //   setMessage(html)
  //   // setIsLoadedMessage(false);
  //   // if (html.trim()) {
  //   //   const getUUID = await localStorage.getItem("UUID");
  //   //   const date = new Date();

  //   //   const formattedDateTime = `${date.getFullYear()}-${String(
  //   //     date.getMonth() + 1
  //   //   ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
  //   //     date.getHours()
  //   //   ).padStart(2, "0")}:${String(date.getMinutes()).padStart(
  //   //     2,
  //   //     "0"
  //   //   )}:${String(date.getSeconds()).padStart(2, "0")}`;
  //   //   const requestData = {
  //   //     table: "contact_message_histories",
  //   //     data: JSON.stringify({
  //   //       message_side: messageSide,
  //   //       a_application_id: Number(getUUID),
  //   //       description: html, // Include the HTML content here
  //   //       contact_masters_id: getData?.id,
  //   //       message_type_id: 0,
  //   //       created_date_time: formattedDateTime,
  //   //     }),
  //   //     request_flag: "msg",
  //   //     // data: `{"message_side":"${insertObj.message_side}","a_application_id":${Number(getUUID)},"description":"${insertObj.description}","contact_masters_id":"${insertObj.contact_masters_id}"}`,
  //   //   };
  //   //   try {
  //   //     const data = await axiosInstance.post("commonCreate", requestData);
  //   //     if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
  //   //       setIsLoadedMessage(true);
  //   //     } else {
  //   //       return false;
  //   //     }
  //   //   } catch (error: any) {
  //   //     toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  //   //   }
  //   // }
  // };
  return (
    <React.Fragment>
      <Modal show={show} onHide={onHide} centered className={modalThemeClass1}>
        <div className={`p-10 m-title ${modalThemeClass}`}>{title}</div>
        <Modal.Body className={`${modalThemeClass}`}>
          <div>
            <label>To Email</label>
            <span className="text-danger">*</span>
            {/* <input
              type="email"
              placeholder="Recipient Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="form-control font-size-15 rounded-1"
            /> */}
            <input
              type="email"
              placeholder="Recipient Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={() => validateEmail(email, setEmailError)} // Validate on blur
              required
              className="form-control font-size-15 rounded-1"
            />
            {/* Display error message */}
            {emailError && <span className="text-danger">{emailError}</span>}
          </div>
          <div>
            {/* <label>CC Email</label>
            <input
              type="email"
              placeholder="CC Email" // CC email input
              value={ccEmail}
              onChange={(e) => setCcEmail(e.target.value)}
              className="form-control font-size-15 rounded-1"
            /> */}
            <label>CC Email</label>
            <input
              type="email"
              placeholder="CC Email"
              value={ccEmail}
              onChange={(e) => {
                setCcEmail(e.target.value);
                validateEmail(e.target.value, setCcEmailError); // Validate on change
              }}
              // onBlur={() => validateEmail(ccEmail, setCcEmailError)} // Validate on blur
              className="form-control font-size-15 rounded-1"
            />
            {/* Display error message */}
            {ccEmailError && (
              <span className="text-danger">{ccEmailError}</span>
            )}
          </div>

          <div>
            <label>Subject</label>
            <input
              type="text"
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              className="form-control font-size-15 rounded-1"
            />
          </div>
          <label>Description</label>
          <span className="text-danger">*</span>
          {/* <div>
            <textarea
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              className="form-control font-size-15 rounded-1"
            />
          </div> */}
          <div>
            <EmailCustomEditor
              text1={message} // Pass current message content to the editor
              setMessage={setMessage} // Function to update message state
              // setAttachment={setAttachment} // Function to handle file attachment
            />
          </div>
          <div style={{border:" 1px solid #dee2e6",
    borderTop: "hidden"}}>
            {/* <div> */}
            {/* <input type="file" onChange={handleFileChange} required /> */}
            {/* </div> */}

            <div className=" col-12 d-flex">
              <div className=" px-2 chat-attach">
                {/* The label will act as a trigger for the hidden file input */}
                <label
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  htmlFor="file-upload" // Match this with the input's id
                >
                  <svg
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#5f6368"
                  >
                    <path d="M330-240q-104 0-177-73T80-490q0-104 73-177t177-73h370q75 0 127.5 52.5T880-560q0 75-52.5 127.5T700-380H350q-46 0-78-32t-32-78q0-46 32-78t78-32h370v80H350q-13 0-21.5 8.5T320-490q0 13 8.5 21.5T350-460h350q42-1 71-29.5t29-70.5q0-42-29-71t-71-29H330q-71-1-120.5 49T160-490q0 70 49.5 119T330-320h390v80H330Z" />
                  </svg>
                </label>

                {/* Hidden file input */}
                <input
                  type="file"
                  id="file-upload"
                  onChange={handleFileChange}
                  style={{ display: "none" }} // Hide the actual file input
                  accept=".png,.jpg,.jpeg,.pdf"
                />
              </div>
               {/* Display selected file name */}
      <div className="ml-2 d-flex align-items-center">
        {attachment ? (
          <span>{attachment.name}</span> // Show file name if a file is selected
        ) : (
          <span>No file selected</span> // Fallback when no file is selected
        )}
      </div>
            </div>
            {/* </button>
                </div>
              </div> */}
            {/* </div> */}
          </div>
          <p>{status}</p>
          {/* <div className="  mt-3    d-flex justify-content-center">

            <div className="mb-3 py-4  ">
              <div className="row  mx-0 px-2 gy-3  d-flex justify-content-center">
                <div className="col-6 col-md-6 ">
                  <div className="form-group">
                    <label className="pb-2 mb-1 form_label">To Email :</label>
                    <input
                      type="email"
                      placeholder="Recipient Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className="form-control font-size-15 rounded-1"
                    />
                  </div>
                </div>
                <div className="col-6 col-md-6 ">
                  <div className="form-group">
                 
                    <label className="pb-2 form_label">CC Email :</label>
            <input
              type="email"
              placeholder="CC Email" // CC email input
              value={ccEmail}
              onChange={(e) => setCcEmail(e.target.value)}
            />
                    
                  </div>
                </div>
                <div className="col-6 col-md-6 ">
                  <div className="form-group">
                  <label className="pb-2 form_label">subject :</label>
            <input
              type="text"
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
                  </div>
                </div>
              
                <div className="col-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="description" className="pb-2 form_label">
                      Description <span className="text-danger">*</span>
                    </label>
                   
                  </div>
                </div>
                
              </div>
            </div>
          </div> */}
          <div className="d-flex justify-content-end m-btn">
            <Button className="mr-2 px-4 btn1" onClick={onHide}>
              {btn1}
            </Button>
            <Button
              className="px-4 ms-2 btn2"
              onClick={handleSave}
              disabled={isSubmitDisabled}
            >
              {btn2}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default EmailSendView;
