import React, { useEffect, useState } from "react";
import "./ConfirmationModal.css";
import { IUserList } from "../../pages/left-side/LeftSideController";
interface CheckBoxModalProps {
  show: boolean;
  onHide: () => void;
  handleSubmit: (contactId: number | undefined, checkedOptions: any[]) => void;
  title: string;
  message: string;
  btn1: string;
  btn2: string;
  options: any[];
  selectedLabelIds?: any;
  contactId: number | undefined;
}

const CheckBoxModal: React.FC<CheckBoxModalProps> = ({
  show,
  onHide,
  handleSubmit,
  title,
  message,
  btn1,
  btn2,
  options,
  selectedLabelIds,
  contactId,
  // contactInfo
}) => {


  //   // Function to filter users by specific labels
  //   const filterUsersByLabels = (labels: number[]) => {
  //     console.log("Filtering for labels:", labels);

  //     return userData.filter(item => {
  //       console.log("User lable:", item.label);

  //       return item.label && labels.every(labela => item.label.includes(labela))});
  //   };
  // console.log(filterUsersByLabels);

  //   // Example usage of the filter function
  //   const selectedUser = filterUsersByLabels([49, 47]);
  // console.log("selectedUser",selectedUser);

  const [checkedOptions, setCheckedOptions] = useState<any[] | undefined>([]);
 
  // useEffect(() => {
  //   // Reset the checkedOptions whenever contactId or selectedLabelIds change
  //   setCheckedOptions(selectedLabelIds);
  // }, [contactId, selectedLabelIds]);
  useEffect(() => {
    // If selectedLabelIds is a string (e.g., "9,12"), split and map it to an array of numbers
    // if (selectedLabelIds && typeof selectedLabelIds === "string") {
      
    // } else if (Array.isArray(selectedLabelIds)) {
    //   setCheckedOptions(selectedLabelIds);
    // }
    const parsedLabelIds = selectedLabelIds ? selectedLabelIds.split(",").map(Number): [];
      setCheckedOptions(parsedLabelIds);
  }, [selectedLabelIds, contactId]);

  const handleCheckboxChange = (optionId: any) => {
    setCheckedOptions((prev) =>
      prev?.includes(optionId)
        ? prev.filter((id) => id !== optionId)
        : [...(prev || []), optionId]
    );
  };

  const onSubmit = () => {
    if (checkedOptions) handleSubmit(contactId, checkedOptions);
  };

  return show ? (
    <div className="modal-overlay">
      <div className="modal-content_label">
        <h2>{title}</h2>
        <p>{message}</p>
        <div className="overflow-auto " style={{ maxHeight: "500px" }}>
          <table className="table table-hover" border={0}>
            <tbody className="text-center">
              {options.map((option) => (
                <tr
                  key={option.id}
                  className="text-left"
                  style={{
                    border: "1px solid white",
                    borderCollapse: "collapse",
                    height: "10px",
                  }}
                >
                  <td className="text-start">
                    <span
                      style={{
                        backgroundColor: option.color
                          ? option.color
                          : "#808080",
                      }}
                      className="badge rounded-pill"
                    >
                      {option.lable_name}
                    </span>
                  </td>
                  <td className="text-end">
                    <input
                    className="custom-checkbox"
                      type="checkbox"
                      id={`checkbox-${option.id}`}
                      checked={checkedOptions?.includes(option.id)}
                      onChange={() => handleCheckboxChange(option.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="modal-buttons">
          <button className="modal-button1" onClick={onHide}>
            {btn1}
          </button>
          <button className="modal-button2" onClick={onSubmit}>
            {btn2}
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default CheckBoxModal;


