import React, { useEffect, useRef, useState } from "react";
import { TOnChangeInput } from "../../../../../helpers/AppType";

import ConfirmationModal from "../../../../../components/model/ConfirmationModal";
import {
  createProduct,
  fetchCategoryApiForProduct,
  fetchProductApi,
  handleDeleteProduct,
  IProductView,
  updateProduct,
} from "./ProductController";
import CustomSearchDropdown from "../../../../../components/CustomSearchDropdown";
import { SingleValue } from "react-select";
import { IOption } from "../../../../../helpers/AppoInterface";

interface IPropsProductView {
  isProductView: boolean;
  closeProductView: () => void;
}
const ProductView = ({
  isProductView,
  closeProductView,
}: IPropsProductView) => {
  const [productLists, setProductList] = useState<IProductView[]>([]);
  const [productInput, setProductInputInput] = useState("");
  const [unitInput, setUnitInput] = useState("");
  // const productRefDropdown = useRef<HTMLButtonElement>(null);
  const dropdownContactRef = useRef<Record<number, HTMLUListElement | null>>(
    {}
  );
  const [productDropdown, setProductDropdown] = useState<any>(null);
  const [hasIdAvail, setHasIdAvail] = useState<number>();
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(false);
  const [editSelectedCategoryId, setEditSelectedCategoryId] = useState("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editProductId, setEditProductId] = useState<number | undefined>(
    undefined
  );

  // const handleCategoryChange = (event: any) => {
  //   setSelectedCategory(event.target.value);
  // };
  const handleCategoryChange = (selectedOption: SingleValue<IOption>) => {
    setSelectedCategory(selectedOption);
  };
  const handelChange = (event: TOnChangeInput) => {
    setProductInputInput(event.target.value);
  };
  const handelChangeUnit = (event: TOnChangeInput) => {
    setUnitInput(event.target.value);
  };

  const handelSubmit = () => {
    if (unitInput && productInput) {
      if (isEditing && editProductId !== null) {
        updateProduct(
          {
            product_name: productInput,
            category_id: selectedCategory.value,
            unit: unitInput,
          },
          setProductList,
          editProductId
        );
      } else {
        createProduct(
          {
            product_name: productInput,
            category_id: selectedCategory.value,
            unit: unitInput,
          },
          setProductList
        );
      }
    }
    setProductInputInput("");
    setSelectedCategory(false);
    setUnitInput("");
    setIsEditing(false);
  };
  const toggleDropdownProduct = (productId: number | undefined) => {
    const checkedId = productLists.find((abv) => abv.id === productId);
    setHasIdAvail(checkedId?.id);
    setProductDropdown(!productDropdown);
  };
  const handleEdit = (item: IProductView) => {
    const selectedCategoryOption = categoryOptions.find(
      (option: { value: string; }) => option.value === item.category_id
  );
    setProductDropdown(null);
    setProductInputInput(item.product_name);
    setUnitInput(item.unit);
    setSelectedCategory(selectedCategoryOption);
    setEditSelectedCategoryId(item.category_id);
    setIsEditing(true);
    setEditProductId(item.id);
  };
  useEffect(() => {
    if (isProductView) {
      fetchProductApi(setProductList);
      fetchCategoryApiForProduct(setCategoryList);
    }
  }, [isProductView]);
  const handleClickOutside = (event: { target: any }) => {
    const clickedOutside = Object.values(dropdownContactRef.current).every(
      (ref) => ref && !ref.contains(event.target)
    );
    if (clickedOutside) {
      setProductDropdown(null);
    }
  };

  useEffect(() => {
    if (productDropdown !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [productDropdown]);
  const categoryOptions = categoryList.map((category: any) => ({
    value: category.id,
    label: category.category_name,
  }));
  return (
    <>
      {isProductView ? (
        <div
          className="notifications animate__animated animate__fadeInLeft"
          id="notifications"
        >
          {/* <!-- Header --> */}
          <div className="header-Chat">
            {/* <!-- Icons --> */}
            <div className="ICON">
              <div
                aria-disabled="false"
                role="button"
                className="icons"
                data-tab="2"
                title="New chat"
                aria-label="New chat"
                onClick={closeProductView}
              >
                <span data-testid="chat" data-icon="chat" className="">
                  <svg viewBox="0 0 24 24" width="24" height="24" className="">
                    <path
                      fill="currentColor"
                      d="m12 4 1.4 1.4L7.8 11H20v2H7.8l5.6 5.6L12 20l-8-8 8-8z"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>

            <div className="newText">
              <h2>Products</h2>
            </div>
          </div>
          {/* <!-- Chats --> */}
          <div className="chats-notifications">
            {/* <!-- Chats 1 --> */}
            <div className="block">
              {/* <!-- Text --> */}
              <div className="h-text">
                <div className="head" style={{ display: "block" }}>
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    <h4>Select category</h4>
                  </label>
                  {/* <div className="col-12 d-flex "> */}
                  <div className="col-12">
                    <div className="">
                      <div className="add-source-of-type-section ">
                        <CustomSearchDropdown
                          options={categoryOptions}
                          value={selectedCategory}
                          onChange={handleCategoryChange}
                          className="w-100  "
                        />
                        {/* <select
                          className={`form-control pb-1 pt-1  mb-0 font-size-12 rounded-1 `}
                          onChange={handleCategoryChange}
                          value={selectedCategory}
                        >
                          <option value="">select category</option>

                          {categoryList &&
                            categoryList.map((categoryItem: any) => (
                              <>
                                <option
                                  selected={
                                    editSelectedCategoryId === categoryItem.id
                                      ? true
                                      : false
                                  }
                                  key={categoryItem.id}
                                  value={categoryItem.id}
                                >
                                  {categoryItem.category_name}
                                </option>
                              </>
                            ))}
                        </select> */}
                      </div>
                    </div>
                  </div>

                  {/* </div> */}
                  <div className="col-12 d-flex">
                    <div className="col-8 ">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h4>Product Name</h4>
                      </label>
                      <div className="search-bar ">
                        <div className="add-source-of-type-section ">
                          <input
                            type="text"
                            title="Product  "
                            placeholder="Add Product Name"
                            value={productInput}
                            onChange={(e) => handelChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-3 ">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h4>Unit Name</h4>
                      </label>
                      <div className="search-bar ">
                        <div className="add-source-of-type-section mx-2  d-flex">
                          <input
                            type="text"
                            title="Unit"
                            placeholder="Unit Name"
                            value={unitInput}
                            onChange={(e) => handelChangeUnit(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-1  d-flex  align-items-center mt-4">
                      <button className="" onClick={handelSubmit}>
                        <span>
                          {isEditing ? (
                            <span>
                              <svg
                                data-name="Layer 1"
                                height={24}
                                id="Layer_1"
                                viewBox="0 0 200 200"
                              >
                                <title />
                                <path
                                  fill="currentColor"
                                  d="M177.68,43.9c-4.5-3.5-10.5-3-14,1.5l-74,89.5-55-40c-4.5-3-10.5-2.5-14,2-3,4.5-2.5,10.5,2,14l62.5,45.5a.49.49,0,0,1,.5.5c.5,0,.5.5,1,.5s.5.5,1,.5.5,0,1,.5h6c.5,0,.5,0,1-.5.5,0,.5-.5,1-.5s.5-.5,1-.5.5-.5,1-.5a.49.49,0,0,0,.5-.5l.5-.5,80-97C182.18,53.9,181.68,47.4,177.68,43.9Z"
                                />
                              </svg>
                            </span>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="26px"
                              viewBox="0 -960 960 960"
                              width="26px"
                              fill="#5f6368"
                              // className="ml-2"
                            >
                              <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                            </svg>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="source-of-type-list-grid-block">
                  <div className="source-of-type-list-grid-main">
                    <table className="table table-hover" border={0}>
                      <thead>
                        <td>
                          <b>Product Name</b>
                        </td>
                        <td>
                          <b>Unit</b>
                        </td>
                        <td>
                          <b>Category</b>
                        </td>
                        {/* <td className=""></td> */}
                      </thead>
                      <tbody className="text-center">
                        {productLists &&
                          productLists.map((item, index) => (
                            <tr
                              key={index}
                              className=""
                              style={
                                {
                                  // border: "1px solid white",
                                  // borderCollapse: "collapse",
                                  // height: "10px",
                                }
                              }
                            >
                              <td className="text-start">
                                <span>{item.product_name}</span>
                              </td>
                              <td className="text-start">
                                <span>{item.unit}</span>
                              </td>
                              <td className="text-start">
                                <span>{item.category_name}</span>
                              </td>
                              <td className="text-start">
                                {item.id === -1 ? (
                                  <span></span>
                                ) : (
                                  <>
                                    <button
                                      className="source-of-type-list-grid-options"
                                      id="source-of-types-options-id"
                                      onClick={() =>
                                        toggleDropdownProduct(item.id)
                                      }
                                      // ref={productRefDropdown}
                                    >
                                      <span>
                                        <svg
                                          viewBox="0 0 24 24"
                                          width="24"
                                          height="24"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M12 7a2 2 0 1 0-.001-4.001A2 2 0 0 0 12 7zm0 2a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 9zm0 6a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 15z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </button>
                                    <ul
                                      className={`source-of-types-options ${
                                        hasIdAvail === item.id &&
                                        productDropdown
                                          ? "isVisible"
                                          : "isHidden"
                                      } `}
                                      id="dropLeft"
                                      ref={(el) =>
                                        (dropdownContactRef.current[item.id] =
                                          el)
                                      }
                                    >
                                      <li
                                        className="listItem"
                                        role="button"
                                        onClick={() =>
                                          setIsDeleteConfirmation(true)
                                        }
                                      >
                                        Delete
                                      </li>
                                      <li
                                        className="listItem"
                                        role="button"
                                        onClick={() => handleEdit(item)}
                                      >
                                        Edit
                                      </li>
                                    </ul>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        {/* ))} */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isDeleteConfirmation && (
            <ConfirmationModal
              show={isDeleteConfirmation}
              onHide={() => setIsDeleteConfirmation(false)}
              handleSubmit={() =>
                handleDeleteProduct(
                  hasIdAvail,
                  setIsDeleteConfirmation,
                  setProductList
                )
              }
              title={"Delete this Product"}
              message={"Are You Sure You Want To Delete This Product?"}
              btn1="CANCEL"
              btn2="DELETE"
            />
          )}
        </div>
      ) : null}
    </>
  );
};

export default ProductView;
