import { toast } from "react-toastify";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../helpers/AppConstants";
import axiosInstance from "../services/axiosInstance";

export const checkDuplication = async (
  value: string |  number,
  tableName: string,
  columnName: string,
  columnName1?:string
) => {
  const getUUID = await localStorage.getItem("UUID");

  const requestData = {
    table: tableName,
    columns: "id",
    where: `{"isDelete":"0","${columnName}":"${value}","a_application_login_id":"${getUUID}"}`,
  };
  try {
    const data = await axiosInstance.post("commonGet", requestData);
    if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
      return true;
    } else {
      return false;
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

// export const formatDate = (date: any) => {

//   const d = new Date(date);
//   const day = d.getDate().toString().padStart(2, "0");
//   const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
//   const year = d.getFullYear();
//   const hours = d.getHours().toString().padStart(2, "0");
//   const minutes = d.getMinutes().toString().padStart(2, "0");
//   const seconds = d.getSeconds().toString().padStart(2, "0");

//   return `${day}-${month}-${year} ${hours}:${minutes}`;
// };

export const formatDate = (date: any) => {
  const d = new Date(date);
  const day = d.getDate().toString().padStart(2, "0");
  const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
  const year = d.getFullYear();
  
  let hours = d.getHours();
  const minutes = d.getMinutes().toString().padStart(2, "0");
  const seconds = d.getSeconds().toString().padStart(2, "0");
  
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight (0 becomes 12)
  
  return `${day}-${month}-${year} ${hours.toString().padStart(2, "0")}:${minutes} ${ampm}`;
};
export const isCurrentDateTime = (reminderDateTime: string): boolean => {
  const currentDateTime = new Date();
  const reminderDate = new Date(reminderDateTime);

  // Comparing date and time
  return (
    currentDateTime.getFullYear() === reminderDate.getFullYear() &&
    currentDateTime.getMonth() === reminderDate.getMonth() &&
    currentDateTime.getDate() === reminderDate.getDate() &&
    currentDateTime.getHours() === reminderDate.getHours() &&
    currentDateTime.getMinutes() === reminderDate.getMinutes()
  );
};
