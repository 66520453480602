import * as Yup from "yup";
import axiosInstance from "../../../services/axiosInstance";
import { toast } from "react-toastify";
import { TReactSetState } from "../../../helpers/AppType";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../helpers/AppConstants";

export interface ILoginValues {
  mobile_number: string;
}
export const LoginInitialValues = {
  mobile_number: "",
};

export const ContactNumberRegex = /^\+?[0-9]{2} ?[0-9]{6,12}$/;
export const LoginValidationSchema = () =>
  Yup.object().shape({
    mobile_number: Yup.string()
      // .matches(ContactNumberRegex, ("Contact Number Match"))
      .max(15, "Max mobile number 15")
      .min(10, "Min mobile number 10")
      .required("Mobile Number Required"),
  });

export const loginSubmit = async (
  values: ILoginValues,
  setShowOtp: TReactSetState<boolean>
) => {
  try {
    const contactNumber = values.mobile_number;

    const response = await axiosInstance.post("login", {
      contact_number: contactNumber,
      request_flag: 0,
    });

    if (response.data.code === 200) {
      if (response.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        setShowOtp(true);
      } else {
        toast.error(response.data.ack_msg);
      }
    } else {
      // setShowOtp(false);
      toast.error(response.data.ack_msg);
    }
  } catch (error: any) {
    toast.error(error?.response.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
