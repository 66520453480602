import React, { useEffect, useState } from "react";
import "./ConfirmationModal.css";
interface CheckBoxModalProps {
  show: boolean;
  onHide: () => void;
  handleSubmit: ( checkedOptions1: any[]) => void;
  title: string;
  message: string;
  btn1: string;
  btn2: string;
  options: any[];
  selectedLabelIds?: any[];
  contactId?: number | undefined;
}

const CheckBoxFilterModal: React.FC<CheckBoxModalProps> = ({
  show,
  onHide,
  handleSubmit,
  title,
  message,
  btn1,
  btn2,
  options,
  selectedLabelIds,
  contactId,
}) => {
  const [checkedOptions, setCheckedOptions] = useState<any[] | undefined>(
    selectedLabelIds
  );
  // console.log("checkedOptions", checkedOptions);

  // useEffect(() => {
  //   // Reset the checkedOptions whenever contactId or selectedLabelIds change
  //   setCheckedOptions(selectedLabelIds);
  // }, [contactId, selectedLabelIds]);

  const handleCheckboxChange = (optionId: any) => {
    // console.log("optionId",optionId);
    
    setCheckedOptions((prev) =>
      prev?.includes(optionId)
        ? prev.filter((id) => id !== optionId)
        : [...(prev || []), optionId]
    );
  };

  const onSubmit = () => {
    if (checkedOptions) handleSubmit( checkedOptions);
  };

  const handleHide = () => {
    // Clear all checkboxes
    // onHide(); // Call the original onHide function
    setCheckedOptions([]);
    if (checkedOptions){ 
      setCheckedOptions([]);
      handleSubmit( checkedOptions)
    }
  };

  return show ? (
    <div className="modal-overlay">
      <div className="modal-content_label">
        <h2>{title}</h2>
        <p>{message}</p>
        <div className="overflow-auto " style={{ maxHeight: "500px"}}>
          <table className="table table-hover" border={0}>
            <tbody className="text-center">
              {options.map((option) => (
                <tr
                  key={option.id}
                  className="text-left"
                  style={{
                    border: "1px solid white",
                    borderCollapse: "collapse",
                    height: "10px" 
                  }}
                >
                  <td className="text-start">
                    <span
                      style={{
                        backgroundColor: option.color
                          ? option.color
                          : "#808080",
                      }}
                      className="badge rounded-pill"
                    >
                      {option.lable_name}
                    </span>
                  </td>
                  <td className="text-end">
                    <input
                      type="checkbox"
                      id={`checkbox-${option.id}`}
                      checked={checkedOptions?.includes(option.id)}
                      onChange={() => handleCheckboxChange(option.id)}
                      className="custom-checkbox"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="modal-buttons">
          <button className="modal-button1" onClick={handleHide}>
            {btn1}
          </button>
          <button className="modal-button2" onClick={onSubmit}>
            {btn2}
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default CheckBoxFilterModal;
