import React from "react";
import ReactDOM from "react-dom/client";
// import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter } from "react-router-dom";

import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import './style/all-privacy-screen.css'
import './style/Contact-info.css'
import './style/group.css'
import './style/intro.css'
// import './style/modal.css'
import './style/newChat.css'
import './style/CustomSearchDropdown.css'
import './style/search-message.css'
import './style/setings.css'
import './style/status.css'
import './style/style.css'
import './style/ToggleButton.css'
import "./style/newChat.css";
import "./style/search-message.css";
import "./style/setings.css";
import "./style/status.css";
import "./style/style.css";
import "./style/modal.css";
import "./style/ToggleButton.css";

import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);
