import React, { useEffect, useState, useRef } from "react";
import { useTheme } from "../../components/ThemeContext";
import ConfirmationModal from "../../components/model/ConfirmationModal";
import Group from "./header/Group";
import Starred from "./header/Starred";
import Setting from "./header/Setting/Setting";
import NewChat from "./header/NewChat";
import RightView from "../right-side/RightView";
import Status from "./header/Status";
import {
  ILoginData,
  IUserList,
  fetchDataIndiaMart,
  fetchDataUser,
  fetchDataUserLabelFilter,
  fetchGetByIdUser,
  fetchWhatsAppApiWebhook,
  upateCheckBox,
} from "./LeftSideController";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LoginView from "../public/login/LoginView";
import CreateContactView from "./create-contact/CreateContactView";
import CheckBoxModal from "../../components/model/CheckBoxModal";
import { fetchLabelApi } from "./header/Setting/label/LabelController";
import CheckBoxFilterModal from "../../components/model/CheckBoxFilterModal";
import { formatDate } from "../../common/SharedFunction";
import ListInquiryView from "../right-side/list-inquiry/ListInquiryView";
import CreateCompanyView from "./create-company/CreateCompanyView";
import ListCompanyView from "./list-company/ListCompanyView";
import ListReminderView from "./header/list-reminder/ListReminderView";
interface IPropsLeftView {
  isVisible: boolean;
}
const LeftSideView = ({ isVisible }: IPropsLeftView) => {
  const token = localStorage.getItem("token");
  const localId = localStorage.getItem("UUID");

  // let clearToken = localStorage.clear()
  const listInnerRef = useRef<HTMLDivElement>(null);
  const { darkMode, toggleTheme } = useTheme();
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const dropdownContactRef = useRef<Record<number, HTMLUListElement | null>>(
    {}
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [labelDropdownOpen, setLabelDropdownOpen] = useState<any>(null);
  const [showListInquiry, setShowListInquiry] = useState(false);
  const [isCloseConfirmation, setIsCloseConfirmation] = useState(false);
  const [isCreateContact, setIsCreateContact] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [showStarred, setshowStarred] = useState(false);
  const [showSetting, setshowSetting] = useState(false);
  const [showNewChat, setshowNewChat] = useState(false);
  const [showRightSide, setShowRightSide] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [user, setUsers] = useState<IUserList[]>([]);
  const [user1, setUsers1] = useState(false);

  const [contInfo, setcontInfo] = useState<IUserList>();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [noDataFound, setNoDataFound] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null
  ); // State to hold the timeout
  const [noDataFound1, setNoDataFound1] = useState(false);
  const [loginById, setLoginById] = useState<ILoginData>();
  const [isLoadContact, setIsLoadContact] = useState(true);
  const [hasOneData, setHasOneData] = useState<number>();
  const [contactId, setContactId] = useState<number>();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isModalFilterVisible, setIsModalFilterVisible] =
    useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [contactSelections, setContactSelections] = useState<
    Record<number, any[]>
  >({});
  const [showListAllInquiry, setShowListAllInquiry] = useState(false);
  const [showListAllReminder, setShowListAllReminder] = useState(false);

  const [showListCompany, setShowListCompany] = useState(false);
  const [selectedLabelIds, setSelectedLabelIds] = useState<string | undefined>(
    ""
  );
  const openGrp = () => {
    setShowGroup(true);
  };
  const openCompany = () => {
    setShowListCompany(true);
  };
  const openRightSide = (singleData: IUserList) => {
    setShowRightSide(true);
    setcontInfo(singleData);
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleDropdownLabel = (id: number) => {
    setHasOneData(id);
    // setLabelDropdownOpen(!labelDropdownOpen);
    setLabelDropdownOpen((prevId: any) => (prevId === id ? null : id));
  };
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const openSettings = () => {
    setshowSetting(true);
  };

  const openFilterLabel = () => {
    // setshowStarred(true);
    setIsModalFilterVisible(true);
  };

  function openStatus() {
    setShowStatus(true);
  }

  const openForm = () => {
    setshowNewChat(true);
  };

  const itemsPerPage: number = 13;
  const handleLogout = () => {
    // setNoDataFound1(  localStorage.clear())
    // if(noDataFound1){
    localStorage.clear();
    setIsCloseConfirmation(false);
    setNoDataFound1(true);
    // }else{
    //   setNoDataFound1(true)
    // }
  };
  const handelRefreshContacts = async () => {
    fetchWhatsAppApiWebhook();
    fetchDataUser(
      0,
      "",
      setUsers,
      itemsPerPage,
      setNoDataFound,
      setLoading,
      token,
      localId,
      setContactId,
      setSelectedLabelIds
    );
    fetchDataIndiaMart();
  };
  useEffect(() => {
    // if (isLoadContact) {
    fetchDataUser(
      0,
      "",
      setUsers,
      itemsPerPage,
      setNoDataFound,
      setLoading,
      token,
      localId,
      setContactId,
      setSelectedLabelIds
    );
    fetchGetByIdUser(token, localId, setLoginById);
    // }
  }, [localId, token, user1, isLoadContact]);
  useEffect(() => {
    if (noDataFound1) {
      handleLogout();
    }
    fetchLabelApi(setOptions);
  }, [noDataFound1]);
  useEffect(() => {
    const handleScroll = () => {
      if (
        listInnerRef.current &&
        listInnerRef.current.scrollTop + listInnerRef.current.clientHeight ===
          listInnerRef.current.scrollHeight
      ) {
        if (user.length < (currentPage + 1) * itemsPerPage + 1) {
          fetchDataUser(
            currentPage + 1,
            searchTerm,
            setUsers,
            itemsPerPage,
            setNoDataFound,
            setLoading,
            token,
            localId,
            setContactId,
            setSelectedLabelIds
          );
        }
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    const listInnerElement = listInnerRef.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (listInnerElement) {
        listInnerElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [currentPage, user.length, searchTerm, token, localId, user1]);

  // Handler for search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value.length >= 3 || value === "") {
      // fetchDataUser(
      //   0,
      //   value,
      //   setUsers,
      //   itemsPerPage,
      //   setNoDataFound,
      //   setLoading
      // );
      // setCurrentPage(0);
      // Clear existing timeout if it exists
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      // Set new timeout to trigger API call after 5 seconds
      setSearchTimeout(
        setTimeout(() => {
          fetchDataUser(
            0,
            value,
            setUsers,
            itemsPerPage,
            setNoDataFound,
            setLoading,
            token,
            localId,
            setContactId,
            setSelectedLabelIds
          );
          setCurrentPage(0); // Reset page to 0 when search term changes
        }, 1000)
      );
    }
  };

  //code enter press code for search
  //   const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
  //     if (event.key === 'Enter') {
  //         event.preventDefault();
  //         getUsers(0, searchTerm);
  //     }
  // };

  const handleModalOpen = (id: number | undefined) => {
    setContactId(id);
    setIsModalVisible(true);
    // setLabelDropdownOpen(false);
  };

  const handleModalClose = () => {
    if (isModalVisible) {
      setIsModalVisible(false);
    } else {
      setIsModalFilterVisible(false);
    }
  };

  // const handleConfirm = (checkedOptions: any[]) => {
  //   // Handle the logic with the checked options
  //   const selectedOptions = checkedOptions.filter((option) => option.id).map((item) => item.id);
  //   console.log("Selected options:", selectedOptions);
  //   setShowDataOptions(selectedOptions)
  //   upateCheckBox(hasOneData,selectedOptions)

  // };
  // const handleConfirm = (contactId: number | undefined, checkedOptions: any[]) => {
  //   if (contactId !== undefined) {
  //     setContactSelections(prev => ({
  //       ...prev,
  //       [contactId]: checkedOptions
  //     }));
  //   }
  //   setIsModalVisible(false);
  // };
  const handleConfirm = async (
    contactId: number | undefined,
    checkedOptions: any[]
  ) => {
    if (contactId === undefined) return;

    await upateCheckBox(contactId, checkedOptions, setLoading);
    fetchDataUser(
      0,
      "",
      setUsers,
      itemsPerPage,
      setNoDataFound,
      setLoading,
      token,
      localId,
      setContactId,
      setSelectedLabelIds
    );
    setContactSelections((prev) => ({
      ...prev,
      [contactId]: checkedOptions,
    }));
    setIsModalVisible(false);
  };

  const handleConfirmFilter = async (filterData: any) => {
    await fetchDataUser(
      0,
      "",
      setUsers,
      itemsPerPage,
      setNoDataFound,
      setLoading,
      token,
      localId,
      setContactId,
      setSelectedLabelIds,
      filterData
    );
    setIsModalFilterVisible(false);
  };

  const getSelectedOptionsForContact = (contactId: number | undefined) => {
    return contactId ? contactSelections[contactId] || [] : [];
  };
  const getSelectedOptionsForContactFilter = (
    contactId: number | undefined
  ) => {
    return contactId ? contactSelections[contactId] || [] : [];
  };
  const handleClickOutside = (event: { target: any }) => {
    const clickedOutside = Object.values(dropdownContactRef.current).every(
      (ref) => ref && !ref.contains(event.target)
    );
    if (clickedOutside) {
      setLabelDropdownOpen(null);
    }
  };

  useEffect(() => {
    if (labelDropdownOpen !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [labelDropdownOpen]);
  return (
    <>
      {noDataFound1 ? (
        <LoginView />
      ) : (
        <>
          {isVisible ? (
            <>
              {showGroup ||
              showStarred ||
              showSetting ||
              showNewChat ||
              showStatus ||
              showListAllReminder ||
              showListAllInquiry ||
              showListCompany ? (
                <>
                  <Group
                    isGroupOpen={showGroup}
                    closeGroup={() => setShowGroup(false)}
                  />
                  <Starred
                    isStarredOpen={showStarred}
                    closeStarred={() => setshowStarred(false)}
                  />
                  <Setting
                    isSettingOpen={showSetting}
                    closeSettings={() => setshowSetting(false)}
                    profileDetail={loginById}
                  />
                  <NewChat
                    isNewChatOpen={showNewChat}
                    closeForm={() => setshowNewChat(false)}
                  />
                  <Status
                    isStatusShow={showStatus}
                    closeStatus={() => setShowStatus(false)}
                  />
                  <ListInquiryView
                    isListInquiry={showListAllInquiry}
                    closeListInquiry={() => setShowListAllInquiry(false)}
                    isModelOpen={"InquiryAllList"}
                  />
                  <ListCompanyView
                    isCompanyOpen={showListCompany}
                    closeCompany={() => setShowListCompany(false)}
                  />
                  <ListReminderView
                    isReminderOpen={showListAllReminder}
                    closeReminder={() => setShowListAllReminder(false)}
                  />
                </>
              ) : (
                <>
                  <div
                    className="leftSide animate__animated animate__fadeInRight"
                    id="leftSid"
                  >
                    <div className="header">
                      <div className="userImg">
                        {loginById?.profile_pic ? (
                          <img
                            src={`https://backend.smalloffice.in/${loginById?.profile_pic}`}
                            alt="Avatar"
                            className="cover"
                          />
                        ) : (
                          <img
                            src={require("../../assets/images/no_image.jpeg")}
                            alt="Avatar"
                            className="cover"
                          />
                        )}
                      </div>

                      <div style={{ width: "85px" }}>
                        <ul
                          className={`dropLeft ${
                            dropdownOpen ? "isVisible" : "isHidden"
                          } `}
                          id="dropLeft"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <li
                            className="listItem"
                            role="button"
                            onClick={openCompany}
                          >
                            My Company
                          </li>
                          {/* <li
                            className="listItem"
                            role="button"
                            onClick={openGrp}
                          >
                            New group
                          </li> */}
                          <li
                            className="listItem"
                            role="button"
                            onClick={openFilterLabel}
                          >
                            Filter label
                          </li>
                          <li
                            className="listItem"
                            role="button"
                            onClick={openSettings}
                          >
                            Settings
                          </li>
                          <li
                            className="listItem"
                            role="button"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModalSec"
                            onClick={() => setIsCloseConfirmation(true)}
                            // onClick={() => setNoDataFound1(true)}
                          >
                            Log out
                          </li>
                        </ul>
                      </div>

                      <div className="ICON">
                        <button
                          className="icons "
                          onClick={() => setShowListAllReminder(true)}
                        >
                          <span>
                            <svg
                              height="24px"
                              viewBox="0 -960 960 960"
                              width="24px"
                              fill="currentColor"
                            >
                              <path d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" />
                            </svg>
                          </span>
                        </button>
                        <button
                          className="icons "
                          onClick={() => setShowListAllInquiry(true)}
                        >
                          <span>
                            <svg
                              height="24px"
                              viewBox="0 -960 960 960"
                              width="24px"
                              fill="currentColor"
                            >
                              <path d="M280-600v-80h560v80H280Zm0 160v-80h560v80H280Zm0 160v-80h560v80H280ZM160-600q-17 0-28.5-11.5T120-640q0-17 11.5-28.5T160-680q17 0 28.5 11.5T200-640q0 17-11.5 28.5T160-600Zm0 160q-17 0-28.5-11.5T120-480q0-17 11.5-28.5T160-520q17 0 28.5 11.5T200-480q0 17-11.5 28.5T160-440Zm0 160q-17 0-28.5-11.5T120-320q0-17 11.5-28.5T160-360q17 0 28.5 11.5T200-320q0 17-11.5 28.5T160-280Z" />
                            </svg>
                          </span>
                        </button>
                        <button
                          className="icons"
                          onClick={handelRefreshContacts}
                        >
                          <svg width="30" height="30" viewBox="0 0 50 50">
                            <path
                              fill="currentColor"
                              d="M25 38c-7.2 0-13-5.8-13-13 0-3.2 1.2-6.2 3.3-8.6l1.5 1.3C15 19.7 14 22.3 14 25c0 6.1 4.9 11 11 11 1.6 0 3.1-.3 4.6-1l.8 1.8c-1.7.8-3.5 1.2-5.4 1.2z"
                            />
                            <path
                              fill="currentColor"
                              d="M34.7 33.7l-1.5-1.3c1.8-2 2.8-4.6 2.8-7.3 0-6.1-4.9-11-11-11-1.6 0-3.1.3-4.6 1l-.8-1.8c1.7-.8 3.5-1.2 5.4-1.2 7.2 0 13 5.8 13 13 0 3.1-1.2 6.2-3.3 8.6z"
                            />
                            <path
                              fill="currentColor"
                              d="M18 24h-2v-6h-6v-2h8z"
                            />
                            <path fill="currentColor" d="M40 34h-8v-8h2v6h6z" />
                          </svg>
                        </button>
                        <button
                          className="icons"
                          onClick={() => setIsCreateContact(true)}
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="26px"
                              viewBox="0 -960 960 960"
                              width="26px"
                              fill="#5f6368"
                              // className="ml-2"
                            >
                              <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                            </svg>
                          </span>
                        </button>
                        {/* <button className="icons" onClick={toggleTheme}>
                          <img
                            src={
                              darkMode
                                ? require("../../assets/images/Moon-1.png")
                                : require("../../assets/images/Sun-1.png")
                            }
                            alt=""
                            id="moon"
                            className="icons"
                            title="Light Mode"
                            aria-label="Light Mode"
                          />
                        </button> */}
                        {/* <button className="icons" onClick={openStatus}>
                          <span className="">
                            <svg
                              version="1.1"
                              x="0"
                              y="0"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              className=""
                            >
                              <path
                                fill="currentColor"
                                d="M12.072 1.761a10.05 10.05 0 0 0-9.303 5.65.977.977 0 0 0 1.756.855 8.098 8.098 0 0 1 7.496-4.553.977.977 0 1 0 .051-1.952zM1.926 13.64a10.052 10.052 0 0 0 7.461 7.925.977.977 0 0 0 .471-1.895 8.097 8.097 0 0 1-6.012-6.386.977.977 0 0 0-1.92.356zm13.729 7.454a10.053 10.053 0 0 0 6.201-8.946.976.976 0 1 0-1.951-.081v.014a8.097 8.097 0 0 1-4.997 7.209.977.977 0 0 0 .727 1.813l.02-.009z"
                              ></path>
                              <path
                                fill="#009588"
                                d="M19 1.5a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"
                              ></path>
                            </svg>
                          </span>
                        </button> */}
                        {/* <button className="icons" onClick={openForm}>
                          <span className="">
                            <svg
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              className=""
                            >
                              <path
                                fill="currentColor"
                                d="M19.005 3.175H4.674C3.642 3.175 3 3.789 3 4.821V21.02l3.544-3.514h12.461c1.033 0 2.064-1.06 2.064-2.093V4.821c-.001-1.032-1.032-1.646-2.064-1.646zm-4.989 9.869H7.041V11.1h6.975v1.944zm3-4H7.041V7.1h9.975v1.944z"
                              ></path>
                            </svg>
                          </span>
                        </button> */}
                        <button
                          id="dropDown2"
                          className="icons"
                          onClick={toggleDropdown}
                          ref={dropdownRef}
                        >
                          <span>
                            <svg viewBox="0 0 24 24" width="24" height="24">
                              <path
                                fill="currentColor"
                                d="M12 7a2 2 0 1 0-.001-4.001A2 2 0 0 0 12 7zm0 2a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 9zm0 6a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 15z"
                              ></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="search-bar">
                      <div>
                        <button className="search">
                          <span className="">
                            <svg
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              className=""
                            >
                              <path
                                fill="currentColor"
                                d="M15.009 13.805h-.636l-.22-.219a5.184 5.184 0 0 0 1.256-3.386 5.207 5.207 0 1 0-5.207 5.208 5.183 5.183 0 0 0 3.385-1.255l.221.22v.635l4.004 3.999 1.194-1.195-3.997-4.007zm-4.808 0a3.605 3.605 0 1 1 0-7.21 3.605 3.605 0 0 1 0 7.21z"
                              ></path>
                            </svg>
                          </span>
                        </button>

                        <span className="go-back">
                          <svg
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className=""
                          >
                            <path
                              fill="currentColor"
                              d="m12 4 1.4 1.4L7.8 11H20v2H7.8l5.6 5.6L12 20l-8-8 8-8z"
                            ></path>
                          </svg>
                        </span>

                        <input
                          type="text"
                          title="Search or start new chat"
                          aria-label="Search or start new chat"
                          placeholder="Search or start new chat"
                          value={searchTerm}
                          onChange={handleSearchChange}
                          // onKeyDown={handleKeyPress}
                        />
                      </div>
                    </div>

                    <div className="chats" ref={listInnerRef}>
                      <>
                        {loading
                          ? // Render skeleton placeholders when loading
                            Array.from({ length: 12 }).map((_, index) => (
                              <div className="block chat-list" key={index}>
                                <Skeleton
                                  width={50}
                                  height={50}
                                  circle={true}
                                  duration={5}
                                  style={{ opacity: darkMode ? "" : 0.5 }}
                                />
                                <div className="h-text">
                                  <div className="head">
                                    <h4>
                                      <Skeleton
                                        style={{
                                          marginLeft: "10px",
                                          opacity: darkMode ? "" : 0.5,
                                        }}
                                        width={100}
                                      />
                                    </h4>
                                    <p className="time">
                                      <Skeleton
                                        width={80}
                                        style={{ opacity: darkMode ? "" : 0.5 }}
                                        height={10}
                                      />
                                    </p>
                                  </div>
                                  <div className="message-chat">
                                    <div className="chat-text-icon">
                                      <span className="thanks">
                                        <Skeleton
                                          style={{
                                            marginLeft: "10px",
                                            opacity: darkMode ? "" : 0.5,
                                          }}
                                          width={100}
                                        />
                                      </span>
                                      <div className="icon-more">
                                        <Skeleton
                                          width={40}
                                          style={{
                                            opacity: darkMode ? "" : 0.5,
                                          }}
                                          height={10}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          : // Render actual user data when not loading
                            user &&
                            user.map((item, index) => (
                              <>
                                <div>
                                  <ul
                                    className={`labelDropLeft ${
                                      hasOneData === item.id &&
                                      labelDropdownOpen
                                        ? "isVisible"
                                        : "isHidden"
                                    } `}
                                    // onClick={(e) => e.stopPropagation()}
                                    ref={(el) =>
                                      (dropdownContactRef.current[item.id] = el)
                                    }
                                  >
                                    <li
                                      className="listItem"
                                      role="button"
                                      onClick={() => handleModalOpen(item.id)}
                                    >
                                      Assign label
                                    </li>
                                    {/* <li
                            className="listItem"
                            role="button"
                            onClick={openStarred}
                          >
                            Starred messages
                          </li> */}
                                    {/* <li
                                      className="listItem"
                                      role="button"
                                      onClick={openSettings}
                                    >
                                      Settings
                                    </li> */}
                                    {/* <li
                                      className="listItem"
                                      role="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModalSec"
                                      onClick={() =>
                                        setIsCloseConfirmation(true)
                                      }
                                      // onClick={() => setNoDataFound1(true)}
                                    >
                                      Log out
                                    </li> */}
                                  </ul>
                                </div>
                                <button
                                  key={index}
                                  className="block chat-list"
                                  onClick={() => openRightSide(item)}
                                >
                                  <div
                                    className="imgBox"
                                    style={{ backgroundColor: "#CFCFCF" }}
                                  >
                                    <div
                                      className="text-uppercase"
                                      style={{ paddingTop: "12px" }}
                                    >
                                      {item.person_name[0] +
                                        item.person_name[1]}
                                    </div>
                                  </div>
                                  <div className="h-text">
                                    <div className="head">
                                      <h4>{item.person_name}</h4>
                                      {/* <span>
                                        {item.label_color
                                          ? item.label_color
                                              .split(",")
                                              .map((color, index) => (
                                                <span
                                                  key={index}
                                                  style={{
                                                    background: color,
                                                    display: "inline-block",
                                                    width: "20px",
                                                    height: "20px",
                                                    marginRight: "5px",
                                                  }}
                                                ></span>
                                              ))
                                          : ""}
                                      </span> */}
                                      <p className="time">
                                        {item.created_date_time
                                          ? formatDate(item.created_date_time)
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="message-chat">
                                      <div className="tick-icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          width="20"
                                          height="20"
                                          aria-label="read"
                                          className="white-tick"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                                          ></path>
                                        </svg>
                                      </div>
                                      {/* EMAIL,CONTARY,STATE,CITY,NUMBER */}
                                      <div className="chat-text-icon">
                                        {/* <div className="d-flex"> */}
                                        <span className="thanks">
                                          {item.mobile_number}
                                          {item.mobile_number ? "," : ""}
                                        </span>
                                        <span className="thanks">
                                          {item.email_id}
                                          {item.email_id ? "," : ""}
                                        </span>
                                        {/* </div> */}
                                      </div>

                                      <button
                                        className="icon-more"
                                        onClick={() =>
                                          toggleDropdownLabel(item.id)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 19 20"
                                          width="19"
                                          height="20"
                                          className="hide animate__animated animate__fadeInUp"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M3.8 6.7l5.7 5.7 5.7-5.7 1.6 1.6-7.3 7.2-7.3-7.2 1.6-1.6z"
                                          ></path>
                                        </svg>
                                      </button>
                                    </div>
                                    <div className="message-chat ">
                                      <div className="chat-text-icon">
                                        <span className="thanks">
                                          {item.country_name}
                                          {item.country_name ? "," : ""}
                                        </span>
                                        <span className="thanks">
                                          {item.state_name}
                                          {item.state_name ? "," : ""}
                                        </span>
                                        <span className="thanks">
                                          {item.city_name}
                                        </span>
                                      </div>
                                      <div>
                                        <span
                                          style={{
                                            backgroundColor:
                                              item.source_name_color
                                                ? item.source_name_color
                                                : "#eeeeee ",
                                          }}
                                          className="badge rounded-pill"
                                        >
                                          {item.source_name}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </button>
                              </>
                            ))}
                      </>
                      {searchTerm && noDataFound && (
                        <p className="no_found">No data found</p>
                      )}
                    </div>
                  </div>
                </>
              )}
              {isCloseConfirmation && (
                <ConfirmationModal
                  show={isCloseConfirmation}
                  onHide={() => setIsCloseConfirmation(false)}
                  handleSubmit={handleLogout}
                  title={"Log Out?"}
                  message={"Are you sure you want to log out?"}
                  btn1="CANCEL"
                  btn2="LOG OUT"
                />
              )}
              {/* {isCreateContact && ( */}
              <CreateContactView
                show={isCreateContact}
                onHide={() => setIsCreateContact(false)}
                setContact={setUsers1}
                headerName={"Create Contact"}
              />

              {/* )} */}
            </>
          ) : null}
          {showStatus ? (
            <span></span>
          ) : (
            <RightView
              isRightView={showRightSide}
              closeRightSide={() => setShowRightSide(false)}
              getData={contInfo}
              contactsReload={setIsLoadContact}
            />
          )}
        </>
      )}
      {/* {isModalVisible && (
                <CheckBoxModal
                  show={isModalVisible}
                  onHide={handleModalClose}
                  handleSubmit={handleConfirm}
                  title="Assign your label"
                  message="Please select the label form below list."
                  btn1="Cancel"
                  btn2="Confirm"
                  options={options}
                  showDataOptions={showDataoptions}
                  contactId={hasOneData}
                />
              )} */}

      <CheckBoxModal
        show={isModalVisible}
        onHide={handleModalClose}
        handleSubmit={handleConfirm}
        title="Assign your label"
        message="Please select the labels for this contact."
        btn1="Cancel"
        btn2="Submit"
        options={options}
        selectedLabelIds={user.find((item) => item.id === contactId)?.lable}
        contactId={contactId}
      />
      <CheckBoxFilterModal
        show={isModalFilterVisible}
        onHide={handleModalClose}
        handleSubmit={handleConfirmFilter}
        title="Filter your label"
        message="Please select the labels for this contact."
        btn1="Clear"
        btn2="Submit"
        options={options}
      />
    </>
  );
};

export default LeftSideView;
