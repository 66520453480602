import { toast } from "react-toastify";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../../../helpers/AppConstants";
import axiosInstance from "../../../../../services/axiosInstance";
import { TReactSetState } from "../../../../../helpers/AppType";
import { checkDuplication } from "../../../../../common/SharedFunction";

export interface ISourceOfTypes {
  source_name: string;
  id: number;
  color: string | undefined | null;
}

export interface ISourceOfTypesCreate {
  source_name: string;
  color: string | undefined | null;
}
interface IAddSourceOfTypesObj {
  source_name: string;
  color: string | undefined | null;
}
export const handleDeleteSourceType = async (
  sourceOfId: number | undefined,
  setIsDeleteConfirmation: TReactSetState<boolean>,
  setSourceOfTypesLists: TReactSetState<ISourceOfTypes[]>
) => {
  const requestData = {
    table: "source_types",
    where: `{"id":${sourceOfId}}`,
    data: `{"isDelete":"1"}`,
  };
  try {
    const data = await axiosInstance.post("commonUpdate", requestData);
    if (data.data.code === 200) {
      if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        setIsDeleteConfirmation(false);
        fetchSourceOfTypesApi(setSourceOfTypesLists);
      } else {
        toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const createSourceOfType = async (
  sourceOfTypeInput: IAddSourceOfTypesObj,
  setSourceOfTypesLists: TReactSetState<ISourceOfTypes[]>
) => {
  if (
    !(await checkDuplication(
      sourceOfTypeInput.source_name,
      "source_types",
      "source_name"
    ))
  ) {
    const getUUID = await localStorage.getItem("UUID");
    const requestData = {
      table: "source_types",
      data: `{"source_name":"${sourceOfTypeInput.source_name}","color":"${
        sourceOfTypeInput.color
      }","a_application_login_id":${Number(getUUID)}}`,
    };
    try {
      const { data } = await axiosInstance.post("commonCreate", requestData);
      if (data.code === 200) {
        if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
          //   console.log("dadad", data);
          fetchSourceOfTypesApi(setSourceOfTypesLists);
        } else {
          toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
        }
      }
    } catch (error: any) {
      toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } else {
    toast.error("Source Of Type already available");
  }
};

export const fetchSourceOfTypesApi = async (
  setSourceOfTypesLists: TReactSetState<ISourceOfTypes[]>
) => {
  const getUUID = await localStorage.getItem("UUID");
  const requestData = {
    table: "source_types",
    columns: "id,source_name,color",
    // where: `{"isDelete":"0","a_application_login_id":"${getUUID}"}`,
    where: ["isDelete=0", `a_application_login_id=${getUUID}||0`],
    order: `{"id":"DESC"}`,
    request_flag: 0,
  };
  try {
    const data = await axiosInstance.post("commonGet", requestData);
    if (data.data.ack !== DEFAULT_STATUS_CODE_SUCCESS) {
      setSourceOfTypesLists([]);
      // toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
    setSourceOfTypesLists(data.data.data);
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const updateSourceOfTypes = async (
  sourceOfTypeInput: ISourceOfTypesCreate,
  setSourceOfTypesLists: TReactSetState<ISourceOfTypes[]>,
  editCategoryId: number | undefined
) => {
  const getUUID = await localStorage.getItem("UUID");
  const requestData = {
    table: "source_types",
    where: `{"id":"${editCategoryId}"}`,
    data: `{"source_name":"${sourceOfTypeInput.source_name}","color":"${
      sourceOfTypeInput.color
    }","a_application_login_id":${Number(getUUID)}}`,
  };
  try {
    const { data } = await axiosInstance.post("commonUpdate", requestData);
    if (data.code === 200) {
      if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        //   console.log("dadad", data);
        setSourceOfTypesLists((prevList) =>
          prevList.map((category) =>
            category.id === editCategoryId ? data.data : category
          )
        );
        
        fetchSourceOfTypesApi(setSourceOfTypesLists);
      } else {
        toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
