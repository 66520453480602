import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTheme } from "../../../components/ThemeContext";
import { formatDate } from "../../../common/SharedFunction";
import {
  fetchInquiryApi,
  IInquiry,
  updateCheckBox,
} from "./ListInquiryController";
import CreateInquiryView from "../create-inquiry/CreateInquiryView";
import SafeHtml from "../../../components/SafeHtml";
import CheckBoxModal from "../../../components/model/CheckBoxModal";
import { fetchLabelApi } from "../../left-side/header/Setting/label/LabelController";
import { upateCheckBox } from "../../left-side/LeftSideController";
import axiosInstance from "../../../services/axiosInstance";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../helpers/AppConstants";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../components/model/ConfirmationModal";
import ReminderModal from "../../../components/model/ReminderModal";
interface IPropsListInquiry {
  isListInquiry: boolean;
  closeListInquiry: () => void;
  contactData?: any;
  isModelOpen: any;
}

const ListInquiryView = ({
  isListInquiry,
  closeListInquiry,
  contactData,
  isModelOpen,
}: IPropsListInquiry) => {
  const listInnerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<Record<number, HTMLUListElement | null>>({});

  let itemsPerPage: number = 13;
  const token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [contactSelections, setContactSelections] = useState<{
    [key: number]: any[];
  }>({});

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [inquiryList, setInquiryList] = useState<IInquiry[]>([]);
  const { darkMode, toggleTheme } = useTheme();
  const [noDataFound, setNoDataFound] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [hasOneData, setHasOneData] = useState<number>();
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);

  const [labelDropdownOpen, setLabelDropdownOpen] = useState<any>(null);
  const [inquiryId, setInquiryId] = useState<number>();
  const [inquiryToEdit, setInquiryToiEdit] = useState<IInquiry>();
  const [editInquiry, setEditInquiry] = useState(false);
  const [editInquiry1, setEditInquiry1] = useState(false);
  const [selectedLabelIds, setSelectedLabelIds] = useState<string | undefined>(
    ""
  );
  // const [contactSelections, setContactSelections] = useState<
  //   Record<number, any[]>
  // >({});
  useEffect(() => {
    const handleScroll = () => {
      if (
        listInnerRef.current &&
        listInnerRef.current.scrollTop + listInnerRef.current.clientHeight ===
          listInnerRef.current.scrollHeight
      ) {
        if (inquiryList.length < (currentPage + 1) * itemsPerPage + 1) {
          fetchInquiryApi(
            currentPage + 1,
            searchTerm,
            setInquiryList,
            itemsPerPage,
            setNoDataFound,
            setLoading,
            token,
            contactData?.id,
            setInquiryId,
            setSelectedLabelIds
          );
        }
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    const listInnerElement = listInnerRef.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (listInnerElement) {
        listInnerElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [currentPage, inquiryList.length, searchTerm, token, itemsPerPage]);

  useEffect(() => {
    // if(editInquiry1 || isListInquiry){
    fetchInquiryApi(
      0,
      "",
      setInquiryList,
      itemsPerPage,
      setNoDataFound,
      setLoading,
      token,
      contactData?.id,
      setInquiryId,
      setSelectedLabelIds
    );
    // }
    if (options) {
      fetchLabelApi(setOptions);
    }
  }, [contactData?.id, itemsPerPage, token, isListInquiry, editInquiry1]);
  useEffect(() => {
    if(contactData?.id ){
      closeListInquiry()
    }else{
      return undefined
    }
  } ,[contactData?.id])
  const toggleDropdownLabel = (id: number) => {
    setInquiryId(id);
    setHasOneData(id);
    // setLabelDropdownOpen(!labelDropdownOpen);
    setLabelDropdownOpen((prevId: any) => (prevId === id ? null : id));
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value.length >= 3 || value === "") {
      // fetchDataUser(
      //   0,
      //   value,
      //   setUsers,
      //   itemsPerPage,
      //   setNoDataFound,
      //   setLoading
      // );
      // setCurrentPage(0);
      // Clear existing timeout if it exists
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      // Set new timeout to trigger API call after 5 seconds
      setSearchTimeout(
        setTimeout(() => {
          fetchInquiryApi(
            0,
            value,
            setInquiryList,
            itemsPerPage,
            setNoDataFound,
            setLoading,
            token,
            contactData?.id,
            setInquiryId,
            setSelectedLabelIds
          );
          setCurrentPage(0); // Reset page to 0 when search term changes
        }, 1000)
      );
    }
  };

  const handleModalClose = () => {
    if (isModalVisible) {
      setIsModalVisible(false);
    }
  };
  const handleModalClose1 = () => {
    
      closeListInquiry()
  };
  const handleConfirm = async (
    contactId: number | undefined,
    checkedOptions: any[]
  ) => {
    if (contactId === undefined) return;


      await updateCheckBox(contactId, checkedOptions);
      fetchInquiryApi(
        0,
        "",
        setInquiryList,
        itemsPerPage,
        setNoDataFound,
        setLoading,
        token,
        contactData?.id,
        setInquiryId,
        setSelectedLabelIds
      );
      setContactSelections((prev) => ({
        ...prev,
        [contactId]: checkedOptions,
      }));
      setIsModalVisible(false);
     
  };
  const handleModalOpen = (id: number | undefined) => {
    if(contactData){
    setInquiryId(id);
    setIsModalVisible(true);
    }
    setInquiryId(id);
    setIsModalVisible(true);
    // setLabelDropdownOpen(false);
  };
  const handelChangeEdit = (inquiryDataById: IInquiry) => {
    setEditInquiry(true);
    setInquiryToiEdit(inquiryDataById);
  };

  const handleClickOutside = (event: { target: any }) => {
    const clickedOutside = Object.values(dropdownRef.current).every(
      (ref) => ref && !ref.contains(event.target)
    );
    if (clickedOutside) {
      setLabelDropdownOpen(null);
    }
  };

  useEffect(() => {
    if (labelDropdownOpen !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [labelDropdownOpen]);


  const handleDeleteInquiry = async () => {
    const requestData = {
      table: "inquiries",
      where: `{"id":${inquiryId}}`,
      data: `{"isDelete":"1"}`,
    };
    try {
      const data = await axiosInstance.post("commonUpdate", requestData);
      if (data.data.code === 200) {
        if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
          setIsDeleteConfirmation(false);
          fetchInquiryApi(
            0,
            "",
            setInquiryList,
            itemsPerPage,
            setNoDataFound,
            setLoading,
            token,
            contactData?.id,
            setInquiryId,
            setSelectedLabelIds
          );
        } else {
          toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
        }
      }
    } catch (error: any) {
      toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  };
  const handelRefreshInquiry = async () => {
    fetchInquiryApi(
      0,
      "",
      setInquiryList,
      itemsPerPage,
      setNoDataFound,
      setLoading,
      token,
      contactData?.id,
      setInquiryId,
      setSelectedLabelIds
    );
  };
  return (
    <>
      {isListInquiry ? (
        <>
          <div className="leftSide " id="search-message">
            {/* <!-- Header --> */}
            <div className="header-Chat">
              {/* <!-- Icons --> */}
              <div className="ICON">
                <button className="icons" onClick={handleModalClose1}>
                  <span className="text-white">
                    <svg
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className=""
                    >
                      <path d="m19.1 17.2-5.3-5.3 5.3-5.3-1.8-1.8-5.3 5.4-5.3-5.3-1.8 1.7 5.3 5.3-5.3 5.3L6.7 19l5.3-5.3 5.3 5.3 1.8-1.8z"></path>
                    </svg>
                  </span>
                </button>
              </div>

              <div className="newText">
                <h2>{contactData ? "My Inquiry List" : "All Inquiry List"}</h2>
              </div>
              <button
                className="icons pP"
                style={{ marginBottom: "50px" }}
                onClick={handelRefreshInquiry}
              >
                <span className="text-white">
                  <svg width="30" height="30" viewBox="0 0 50 50">
                    <path
                      fill="currentColor"
                      d="M25 38c-7.2 0-13-5.8-13-13 0-3.2 1.2-6.2 3.3-8.6l1.5 1.3C15 19.7 14 22.3 14 25c0 6.1 4.9 11 11 11 1.6 0 3.1-.3 4.6-1l.8 1.8c-1.7.8-3.5 1.2-5.4 1.2z"
                    />
                    <path
                      fill="currentColor"
                      d="M34.7 33.7l-1.5-1.3c1.8-2 2.8-4.6 2.8-7.3 0-6.1-4.9-11-11-11-1.6 0-3.1.3-4.6 1l-.8-1.8c1.7-.8 3.5-1.2 5.4-1.2 7.2 0 13 5.8 13 13 0 3.1-1.2 6.2-3.3 8.6z"
                    />
                    <path fill="currentColor" d="M18 24h-2v-6h-6v-2h8z" />
                    <path fill="currentColor" d="M40 34h-8v-8h2v6h6z" />
                  </svg>
                </span>
              </button>
            </div>
            {/* <!-- Search Bar --> */}
            <div className="search-bar">
              <div>
                <button className="search">
                  <span className="">
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className=""
                    >
                      <path
                        fill="currentColor"
                        d="M15.009 13.805h-.636l-.22-.219a5.184 5.184 0 0 0 1.256-3.386 5.207 5.207 0 1 0-5.207 5.208 5.183 5.183 0 0 0 3.385-1.255l.221.22v.635l4.004 3.999 1.194-1.195-3.997-4.007zm-4.808 0a3.605 3.605 0 1 1 0-7.21 3.605 3.605 0 0 1 0 7.21z"
                      ></path>
                    </svg>
                  </span>
                </button>

                <span className="go-back">
                  <svg viewBox="0 0 24 24" width="24" height="24" className="">
                    <path
                      fill="currentColor"
                      d="m12 4 1.4 1.4L7.8 11H20v2H7.8l5.6 5.6L12 20l-8-8 8-8z"
                    ></path>
                  </svg>
                </span>

                <input
                  type="text"
                  title="Search or start new chat"
                  aria-label="Search or start new chat"
                  placeholder="Search Inquiry"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  // onKeyDown={handleKeyPress}
                />
              </div>
            </div>
            {/* <!-- Chats --> */}

            <div
              className="chats"
              style={{ height: "600px" }}
              ref={listInnerRef}
            >
              <>
                {loading
                  ? // Render skeleton placeholders when loading
                    Array.from({ length: 12 }).map((_, index) => (
                      <button key={index} className="block chat-list">
                        <div className="h-text">
                          <div className="head">
                            <h4 className="inquiry-front">
                              <Skeleton
                                style={{
                                  marginLeft: "10px",
                                  opacity: darkMode ? "" : 0.5,
                                }}
                                width={100}
                              />
                            </h4>
                            <h4
                              className="text-end"
                              // style={{ fontSize: "16px" }}
                            >
                              <Skeleton
                                style={{
                                  marginLeft: "10px",
                                  opacity: darkMode ? "" : 0.5,
                                }}
                                width={30}
                                height={10}
                              />
                            </h4>
                          </div>

                          <div className="head">
                            <h4 className="inquiry-front">
                              <Skeleton
                                width={100}
                                style={{
                                  opacity: darkMode ? "" : 0.5,
                                  marginLeft: "10px",
                                }}
                                // height={10}
                              />
                            </h4>
                            <p className="time">
                              <Skeleton
                                width={80}
                                style={{ opacity: darkMode ? "" : 0.5 }}
                                height={10}
                              />
                            </p>
                          </div>
                          <button className="icon-more float-end">
                            <Skeleton
                              style={{
                                marginLeft: "10px",
                                opacity: darkMode ? "" : 0.5,
                              }}
                              width={30}
                            />
                          </button>
                          <div className="head">
                            <h4 className="inquiry-front">
                              <Skeleton
                                style={{
                                  marginLeft: "10px",
                                  opacity: darkMode ? "" : 0.5,
                                }}
                                width={100}
                              />
                            </h4>
                          </div>
                          <div className="head">
                            <Skeleton
                              style={{
                                marginLeft: "10px",
                                opacity: darkMode ? "" : 0.5,
                              }}
                              width={100}
                            />
                          </div>

                          <div className="">
                            <label className="float-start inquiry-front">
                              <Skeleton
                                style={{
                                  marginLeft: "10px",
                                  opacity: darkMode ? "" : 0.5,
                                }}
                                width={100}
                              />
                            </label>
                            <br />
                            <p className=" d-flex justify-content-between text-break text-start inquiry-front">
                              <Skeleton
                                style={{
                                  marginLeft: "10px",
                                  opacity: darkMode ? "" : 0.5,
                                }}
                                width={100}
                              />
                              <div className="">
                                <span className="badge rounded-pill">
                                  <Skeleton
                                    style={{
                                      marginLeft: "10px",
                                      opacity: darkMode ? "" : 0.5,
                                    }}
                                    width={40}
                                  />
                                </span>
                              </div>
                            </p>
                          </div>
                        </div>
                      </button>
                    ))
                  : // Render actual user data when not loading
                    inquiryList &&
                    inquiryList.map((item, index) => (
                      <>
                        <div key={item.id}>
                          <ul
                            ref={(el) => (dropdownRef.current[item.id] = el)}
                            className={`right_label_dropLeft ${
                              inquiryId === item.id && labelDropdownOpen
                                ? "isVisible"
                                : "isHidden"
                            }`}
                          >
                            <li
                              className="listItem"
                              onClick={() => handleModalOpen(item.id)}
                            >
                              Assign label
                            </li>
                            <li
                              className="listItem"
                              role="button"
                              onClick={() => handelChangeEdit(item)}
                            >
                              Edit
                            </li>
                            <li
                              className="listItem"
                              role="button"
                              onClick={() => setIsDeleteConfirmation(true)}
                            >
                              Delete
                            </li>
                          </ul>
                        </div>

                        <button
                          key={index}
                          className="block chat-list"
                          // onClick={() => openRightSide(item)}
                        >
                          {/* <div className="imgBox">
                          <img
                            src={require("../../../assets/images/Avatar-7.jpeg")}
                            alt=""
                            className="cover"
                          />
                        </div> */}
                          <div className="h-text">
                            {/* <h5 className="text-end">
                              <b>#{item.id}</b>
                            </h5> */}
                            <div className="head">
                              <h4 className="inquiry-front">
                                <b> Contact Name </b> :
                                {item.contact_person_name
                                  ? item.contact_person_name
                                  : ""}
                              </h4>
                              <h4
                                className="text-end"
                                style={{ fontSize: "16px" }}
                              >
                                <b>#{item.id}</b>
                              </h4>
                              {/* <p className="time">
                                {item.create_date_time
                                  ? formatDate(item.create_date_time)
                                  : ""}
                              </p> */}
                            </div>
                            <div className="head">
                              <h4 className="inquiry-front">
                                <b> Category Name </b> :
                                {item.category_name ? item.category_name : ""}
                              </h4>
                              {/* <h4
                                className="text-end"
                                style={{ fontSize: "16px" }}
                              >
                                <b>#{item.id}</b>
                              </h4> */}
                              {/* <p className="time">
                                {item.create_date_time
                                  ? formatDate(item.create_date_time)
                                  : ""}
                              </p> */}
                            </div>
                            {/* <button
                              className="icon-more float-end"
                              onClick={() => toggleDropdownLabel(item.id)}
                              // ref={dropdownContactRef}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 19 20"
                                width="19"
                                height="20"
                                className="hide animate__animated animate__fadeInUp"
                              >
                                <path
                                  fill="currentColor"
                                  d="M3.8 6.7l5.7 5.7 5.7-5.7 1.6 1.6-7.3 7.2-7.3-7.2 1.6-1.6z"
                                ></path>
                              </svg>
                            </button> */}
                            <div className="head">
                              <h4 className="inquiry-front">
                                <b>Product Name</b> :
                                {item.product_name ? item.product_name : ""}
                              </h4>
                              <p className="time">
                                {item.create_date_time
                                  ? formatDate(item.create_date_time)
                                  : ""}
                              </p>
                            </div>
                            <button
                              className="icon-more float-end"
                              onClick={() => toggleDropdownLabel(item.id)}
                              // ref={dropdownContactRef}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 19 20"
                                width="19"
                                height="20"
                                className="hide animate__animated animate__fadeInUp"
                              >
                                <path
                                  fill="currentColor"
                                  d="M3.8 6.7l5.7 5.7 5.7-5.7 1.6 1.6-7.3 7.2-7.3-7.2 1.6-1.6z"
                                ></path>
                              </svg>
                            </button>
                            <div className="head">
                              <h4 className="inquiry-front">
                                <b>Required Quantity </b> :
                                {item.qty ? item.qty : ""}
                              </h4>
                            </div>
                            <div className="head">
                              <h4 className="inquiry-front">
                                <b>Requirement Type </b>:
                                {item.static === 0 ? "One Time" : " Recurring"}
                              </h4>
                            </div>

                            <div className="">
                              <label className="float-start inquiry-front">
                                <b>Description : </b>
                              </label>
                              <br />

                              {/*  */}

                              {/* <div> */}
                              <p className=" d-flex justify-content-between text-break text-start inquiry-front">
                                {item.description ? (
                                  <SafeHtml htmlContent={item.description} />
                                ) : (
                                  ""
                                )}
                                <div className="">
                                  <span
                                    style={{
                                      backgroundColor: item.source_name_color
                                        ? item.source_name_color
                                        : "#eeeeee ",
                                    }}
                                    className="badge rounded-pill"
                                  >
                                    {item.source_name}
                                  </span>
                                </div>
                              </p>

                              {/* </div> */}
                            </div>

                            {/* <div className="message-chat ">
                              <div className="chat-text-icon"></div>

                              <div>
                                <span
                                  style={{
                                    backgroundColor: item.source_name_color
                                      ? item.source_name_color
                                      : "#eeeeee ",
                                  }}
                                  className="badge rounded-pill"
                                >
                                  {item.source_name}
                                </span>
                              </div>
                            </div> */}
                          </div>
                        </button>
                      </>
                    ))}
              </>
              {searchTerm && noDataFound && (
                <p className="no_found">No data found</p>
              )}
            </div>
          </div>
        </>
      ) : null}
      {editInquiry && (
        <CreateInquiryView
          show={editInquiry}
          onHide={() => setEditInquiry(false)}
          setContact={setEditInquiry1}
          contactData={inquiryToEdit}
          contact_id={contactData?.id}
          headerName="Edit Inquiry"
        />
      )}
      {contactData ? <CheckBoxModal
        show={isModalVisible}
        onHide={handleModalClose}
        handleSubmit={handleConfirm}
        title="Assign your label"
        message="Please select the labels for this Inquiry."
        btn1="Cancel"
        btn2="Submit"
        options={options}
        selectedLabelIds={inquiryList.find((item) => item.id === inquiryId)?.label_id}
        contactId={inquiryId}
      /> : 
      <CheckBoxModal
        show={isModalVisible}
        onHide={handleModalClose}
        handleSubmit={handleConfirm}
        title="Assign your label"
        message="Please select the labels for this Inquiry."
        btn1="Cancel"
        btn2="Submit"
        options={options}
        selectedLabelIds={inquiryList.find((item) => item.id === inquiryId)?.label_id}
        contactId={inquiryId}
      />
      }
    
      {isDeleteConfirmation && (
        <ConfirmationModal
          show={isDeleteConfirmation}
          onHide={() => setIsDeleteConfirmation(false)}
          handleSubmit={handleDeleteInquiry}
          title={"Delete this Inquiry"}
          message={"Are You Sure You Want To Delete This Inquiry?"}
          btn1="CANCEL"
          btn2="DELETE"
        />
      )}
    </>
  );
};

export default ListInquiryView;
