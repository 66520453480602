import * as Yup from "yup";
import axiosInstance from "../../../services/axiosInstance";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../helpers/AppConstants";
import { toast } from "react-toastify";
import { checkDuplication } from "../../../common/SharedFunction";
import { TReactSetState } from "../../../helpers/AppType";

export interface ICreateCompany {
  id?: number;
  company_name: string;
  company_email: string;
  company_contact: string;
  trade_india_user_id: string;
  trade_india_profile_id: string;
  trade_india_key: string;
  india_mart_api_key: string;
  whatsapp_authkey: string;
  whatsapp_appkey: string;
 
}

function generateRandomNumber(length: number) {
  const characters = "0123456789";
  let otp = "";
  for (let i = 0; i < length; i++) {
    otp += characters[Math.floor(Math.random() * characters.length)];
  }
  return otp;
}
export const createCompanyInitialValues = (
  companyToEdit: ICreateCompany | undefined
): ICreateCompany => ({
  company_name: companyToEdit?.company_name || "",
  trade_india_user_id: companyToEdit?.trade_india_user_id || "",
  trade_india_profile_id: companyToEdit?.trade_india_profile_id || "",
  trade_india_key: companyToEdit?.trade_india_key || "",
  india_mart_api_key: companyToEdit?.india_mart_api_key || "",
  whatsapp_authkey: companyToEdit?.whatsapp_authkey || "",
  whatsapp_appkey: companyToEdit?.whatsapp_appkey || "",
  company_email: companyToEdit?.company_email || "",
  company_contact: companyToEdit?.company_contact || "+91 ",
});

export const createCompanyValidationSchema = () =>
  Yup.object().shape({
    company_name: Yup.string()
      // .max(70, t("validationEmailMax"))
      // .email(t("validationEmail"))
      .required("Name is Required"),
    company_email: Yup.string().email("Please write proper email "),
  });

  export const createCompany = async (
    values: ICreateCompany,
    setRefresh: TReactSetState<boolean>,
    onHide:any
  ) => {
    // const randomNumber = generateRandomNumber(4);
    const currentTimestamp = Date.now();

    if (
      !(await checkDuplication(
        values.company_name,
        "company_masters",
        "company_name"
      ) || await checkDuplication(
        values.company_contact,
        "company_masters",
        "company_contact"
      ))
    ) {
      const getUUID = await localStorage.getItem("UUID");
      const requestData = {
        table: "company_masters",
        data: `{"company_name":"${values.company_name}","company_contact":"${
          values.company_contact
        }", "company_email":"${values.company_email}","trade_india_user_id":"${
          values.trade_india_user_id
        }","trade_india_profile_id":"${values.trade_india_profile_id}",
        "trade_india_key":"${values.trade_india_key}","india_mart_api_key":"${
          values.india_mart_api_key
        }",
        "whatsapp_authkey":"${values.whatsapp_authkey}","whatsapp_appkey":"${
          values.whatsapp_appkey
        }",
        "invitation_key":${currentTimestamp},"a_application_login_id":${Number(
          getUUID
        )}}`,
      };
      setRefresh(false);
      try {
        const { data } = await axiosInstance.post("commonCreate", requestData);
        if (data.code === 200) {
          if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
            onHide()
            setRefresh(true);
            // console.log("data", data);
          } else {
            toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
          }
        }
      } catch (error: any) {
        toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    } else {
      toast.error("Company is already Add");
    }
  };

export const updateCompany = async (
  values: ICreateCompany,
  setRefresh: TReactSetState<boolean>,
  companyId: number | string | undefined,
  onHide:any
) => {
  // if (
  //   !(await checkDuplication(
  //     values.company_name,
  //     "company_masters",
  //     "company_name"
  //   ) || await checkDuplication(
  //     values.company_contact,
  //     "company_masters",
  //     "company_contact"
  //   ))
  // ) {
  const requestData = {
    table: "company_masters",
    where: `{"id":${companyId}}`,
    data: `{"company_name":"${values.company_name}","company_contact":"${
      values.company_contact
    }", "company_email":"${values.company_email}","trade_india_user_id":"${
      values.trade_india_user_id
    }","trade_india_profile_id":"${values.trade_india_profile_id}",
    "trade_india_key":"${values.trade_india_key}","india_mart_api_key":"${
      values.india_mart_api_key
    }",
    "whatsapp_authkey":"${values.whatsapp_authkey}","whatsapp_appkey":"${
      values.whatsapp_appkey
    }"}`,  
  };
  setRefresh(false);
  try {
    const data = await axiosInstance.post("commonUpdate", requestData);

    if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
      onHide()
      setRefresh(true);
    } else {
      toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
  // } else {
  //   toast.error("Company is already Add");
  // }
};
