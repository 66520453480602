import React, { useEffect, useRef, useState } from "react";
import { TOnChangeInput } from "../../../../../helpers/AppType";
import {
  createCategory,
  fetchCategoryApi,
  handleDeleteCategory,
  ICategoryView,
  updateCategory,
} from "./CategoryController";
import ConfirmationModal from "../../../../../components/model/ConfirmationModal";

interface IPropsCategoryView {
  isCategoryView: boolean;
  closeCategoryView: () => void;
}
const CategoryView = ({
  isCategoryView,
  closeCategoryView,
}: IPropsCategoryView) => {
  const [categoryLists, setCategoryList] = useState<ICategoryView[]>([]);
  const [categoryInput, setCategoryInputInput] = useState("");
  const [categoryHexColorInput, setCategoryHexColorInput] = useState("");
  const categoryRefDropdown = useRef<HTMLButtonElement>(null);
  const dropdownContactRef = useRef<Record<number, HTMLUListElement | null>>(
    {}
  );
  const [categoryDropdown, setCategoryDropdown] = useState<any>(null);
  const [hasIdAvail, setHasIdAvail] = useState<number>();
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editCategoryId, setEditCategoryId] = useState<number | undefined>(
    undefined
  );
  const handelChange = (event: TOnChangeInput) => {
    setCategoryInputInput(event.target.value);
  };
  const handelChangeHexColor = (event: TOnChangeInput) => {
    setCategoryHexColorInput(event.target.value);
  };
  const handelSubmit = () => {
    if (categoryInput) {
      if (isEditing && editCategoryId !== null) {
        updateCategory(
          {
            category_name: categoryInput,
            color: categoryHexColorInput,
          },
          setCategoryList,
          editCategoryId
        );
      } else {
        createCategory(
          {
            category_name: categoryInput,
            color: categoryHexColorInput,
          },
          setCategoryList
        );
      }
    }
    setCategoryInputInput("");
    setCategoryHexColorInput("");
    // setCategoryHexColorInput('#000000');
    setIsEditing(false);
    setEditCategoryId(undefined);
  };
  const toggleDropdownCategory = (categoryId: number | undefined) => {
    const checkedId = categoryLists.find((abv) => abv.id === categoryId);
    setHasIdAvail(checkedId?.id);
    setCategoryDropdown(!categoryDropdown);
  };
  const handleClickOutside = (event: { target: any }) => {
    const clickedOutside = Object.values(dropdownContactRef.current).every(
      (ref) => ref && !ref.contains(event.target)
    );
    if (clickedOutside) {
      setCategoryDropdown(null);
    }
  };

  useEffect(() => {
    if (categoryDropdown !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [categoryDropdown]);
  useEffect(() => {
    if (isCategoryView) {
      fetchCategoryApi(setCategoryList);
    }
  }, [isCategoryView]);
  const handleEdit = (item: ICategoryView) => {
    setCategoryDropdown(null);
    setCategoryInputInput(item.category_name);
    setCategoryHexColorInput(item.color || "#eeeeee");
    setIsEditing(true);
    setEditCategoryId(item.id);
  };

  return (
    <>
      {isCategoryView ? (
        <div
          className="notifications animate__animated animate__fadeInLeft"
          id="notifications"
        >
          {/* <!-- Header --> */}
          <div className="header-Chat">
            {/* <!-- Icons --> */}
            <div className="ICON">
              <div
                aria-disabled="false"
                role="button"
                className="icons"
                data-tab="2"
                title="New chat"
                aria-label="New chat"
                onClick={closeCategoryView}
              >
                <span data-testid="chat" data-icon="chat" className="">
                  <svg viewBox="0 0 24 24" width="24" height="24" className="">
                    <path
                      fill="currentColor"
                      d="m12 4 1.4 1.4L7.8 11H20v2H7.8l5.6 5.6L12 20l-8-8 8-8z"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>

            <div className="newText">
              <h2>Category</h2>
            </div>
          </div>
          {/* <!-- Chats --> */}
          <div className="chats-notifications">
            {/* <!-- Chats 1 --> */}
            <div className="block">
              {/* <!-- Text --> */}
              <div className="h-text">
                <div className="head" style={{ display: "block" }}>
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    <h4>Enter Category Name</h4>
                  </label>
                  <div className="col-12 d-flex">
                    <div className="col-10">
                      <div className="search-bar ">
                        <div className="add-source-of-type-section ">
                          <input
                            type="text"
                            title="Category "
                            placeholder="Add category"
                            value={categoryInput}
                            onChange={(e) => handelChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-2 d-flex justify-content-end align-items-center mx-1">
                      <input
                        type="color"
                        value={categoryHexColorInput}
                        className="   mx-1"
                        onChange={(e) => handelChangeHexColor(e)}
                      />
                      <button className="" onClick={handelSubmit}>
                        {/* <span>{isEditing ? 'Update' : 'Add'}</span> */}

                        <span>
                          {isEditing ? (
                          <span>
                          <svg
                            data-name="Layer 1"
                            height={24}
                            id="Layer_1"
                            viewBox="0 0 200 200"
                          >
                            <title />
                            <path
                              fill="currentColor"
                              d="M177.68,43.9c-4.5-3.5-10.5-3-14,1.5l-74,89.5-55-40c-4.5-3-10.5-2.5-14,2-3,4.5-2.5,10.5,2,14l62.5,45.5a.49.49,0,0,1,.5.5c.5,0,.5.5,1,.5s.5.5,1,.5.5,0,1,.5h6c.5,0,.5,0,1-.5.5,0,.5-.5,1-.5s.5-.5,1-.5.5-.5,1-.5a.49.49,0,0,0,.5-.5l.5-.5,80-97C182.18,53.9,181.68,47.4,177.68,43.9Z"
                            />
                          </svg>
                        </span>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="26px"
                              viewBox="0 -960 960 960"
                              width="26px"
                              fill="#5f6368"
                              // className="ml-2"
                            >
                              <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                            </svg>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="source-of-type-list-grid-block">
                  <div className="source-of-type-list-grid-main">
                    {categoryLists &&
                      categoryLists.map((item, index) => (
                        <div
                          key={index}
                          className="source-of-type-list-grid-list"
                        >
                          <span
                            style={{
                              backgroundColor: item.color
                                ? item.color
                                : "#eeeeee",
                            }}
                            className="badge rounded-pill"
                          >
                            {item.category_name}
                          </span>
                          {item.id === -1 ? (
                            <span></span>
                          ) : (
                            <>
                              <button
                                className="source-of-type-list-grid-options"
                                id="source-of-types-options-id"
                                onClick={() => toggleDropdownCategory(item.id)}
                                // ref={categoryRefDropdown}
                              >
                                <span>
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M12 7a2 2 0 1 0-.001-4.001A2 2 0 0 0 12 7zm0 2a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 9zm0 6a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 15z"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                              <ul
                                className={`source-of-types-options ${
                                  hasIdAvail === item.id && categoryDropdown
                                    ? "isVisible"
                                    : "isHidden"
                                } `}
                                id="dropLeft"
                                ref={(el) =>
                                  (dropdownContactRef.current[item.id] = el)
                                }
                              >
                                <li
                                  className="listItem"
                                  role="button"
                                  onClick={() => setIsDeleteConfirmation(true)}
                                >
                                  Delete
                                </li>
                                <li
                                  className="listItem"
                                  role="button"
                                  onClick={() => handleEdit(item)}
                                >
                                  Edit
                                </li>
                              </ul>
                            </>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isDeleteConfirmation && (
            <ConfirmationModal
              show={isDeleteConfirmation}
              onHide={() => setIsDeleteConfirmation(false)}
              handleSubmit={() =>
                handleDeleteCategory(
                  hasIdAvail,
                  setIsDeleteConfirmation,
                  setCategoryList
                )
              }
              title={"Delete this Category"}
              message={"Are You Sure You Want To Delete This Category?"}
              btn1="CANCEL"
              btn2="DELETE"
            />
          )}
        </div>
      ) : null}
    </>
  );
};

export default CategoryView;
