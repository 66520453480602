import { toast } from "react-toastify";
import { DEFAULT_STATUS_CODE_SUCCESS, MESSAGE_UNKNOWN_ERROR_OCCURRED } from "../../../helpers/AppConstants";
import { TReactSetState } from "../../../helpers/AppType";
import axiosInstance from "../../../services/axiosInstance";

export interface IInquiry {
  id: number;
  contact_master_id: 0;
  product_name: string;
  category_name: string;
  product_id:number
  category_id:number
  description: string;
  qty: number;
  source_type_id: number;
  label_id: number;
  inquiry_date_time: string;
  create_date_time: Date;
  a_application_login_id: number;
  company_masters_id: number;
  static: number;
  source_name: string
source_name_color: string
contact_person_name:string
}

export const fetchInquiryApi = async (
  page: number,
  term: string,
  setUsers: TReactSetState<IInquiry[]>,
  itemsPerPage: number,
  setNoDataFound: TReactSetState<boolean>,
  setLoading: TReactSetState<boolean>,
  token: string | null,
  contact_master_id:number,
  setInquiryId:TReactSetState<number | undefined>,
  setSelectedLabelIds:TReactSetState<any>
) => {
  const start: number = page * itemsPerPage;
  const getUUID = await localStorage.getItem("UUID");

  try {
    const { data } = await axiosInstance.post(
      "inquiry",
      {
        ul: start, // Upper limit based on page number
        ll: itemsPerPage, // Lower limit based on page number
        searchTerm: term,
        application_login_id:getUUID,
        contact_master_id:contact_master_id 
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    if (data.code === 200) {
      if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        if (page === 0) {
          setLoading(true);
        //   const transformedUserData = data.data.item.map(
        //     (user: { lable: string }) => ({
        //       ...user,
        //       lable: user.lable
        //         .split(",")
        //         .filter((label) => label !== "")
        //         .map(Number),
        //     })
        //   );

          setUsers(data.data.item);
          setInquiryId(data.data.item[0]?.id)
          setSelectedLabelIds(data.data.item[0]?.label_id); 
        } else {
          setLoading(false);
          setUsers((prevUsers) => [...prevUsers, ...data.data.item]);
        }
      } else {
        toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
    setNoDataFound(data.data.item.length === 0);
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
   finally {
    setTimeout(() => {
      setLoading(false); // Set loading to false after minimum time
    }, 1000); // 1000 milliseconds (1 seconds)
  }
};

export const updateCheckBox = async (
  hasOneData: number | undefined,
  selectedOptions: any
) => {
  const requestData = {
    table: "inquiries",
    where: `{"id":"${hasOneData}"}`,
    data: `{"label_id":"${selectedOptions}"}`,
  };
  try {
    const { data } = await axiosInstance.post("commonUpdate", requestData);
    if (data.code === 200) {
      if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        // setIsDeleteConfirmation(false);

      } else {
        toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
  // Update profile detail or perform necessary actions
};