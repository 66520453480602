import React from "react";
import LeftSideView from "../pages/left-side/LeftSideView";
import Index from "../pages/public/Index";
const RoutesIndex = () => {
  let isGroupOpen;
  return (
    <>
      {/* <LeftSideView isVisible={!isGroupOpen} /> */}
      {/* <LeftSideView  /> */}
      
          <Index />
    </>
  );
};
export default RoutesIndex;
