import React, { useState, useEffect } from "react";
import { OTPSubmit, IOTPVerifyViewProps } from "./OTPVerificationController";
import OTPInput from "react-otp-input";

import RegistrationView from "../resternation/RegistrationView";
import LeftSideView from "../../left-side/LeftSideView";

const OTPVerificationView = ({
  handleSubmit,
  mobileNumber,
  setShowMenu,
  position,
}: IOTPVerifyViewProps) => {
  const [count, setCount] = useState(30);
  const [intervalNumber, setIntervalNumber] = useState<
    NodeJS.Timeout | undefined
  >();
  let isGroupOpen;

  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [countResend, setCountResend] = useState(3);
  const [showMenu1, setShowMenu1] = useState(false);
  const [otp, setOtp] = useState("");
  const [showRegistration, setShowRegistration] = useState(false);


  useEffect(() => {
    startTimer();
    return () => {
      if (intervalNumber) clearInterval(intervalNumber);
    };
  }, []);

  useEffect(() => {
    if (count === 0 || clickCount === countResend) {
      clearInterval(intervalNumber);
      setIsButtonVisible(true);
    }
  }, [count, intervalNumber, clickCount, countResend]);

  const startTimer = () => {
    const interval = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);
    setIntervalNumber(interval);
  };

  const handleButtonClick = () => {
    
    clearInterval(intervalNumber);
    setCount(30);
    setClickCount((prevCount) => prevCount + 1);
    setIsButtonVisible(false);
    restCountDown();
    handleSubmit(false);
  };

  const restCountDown = () => {
    setIsButtonVisible(false);
    const intervals = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);
    setIntervalNumber(intervals);
  };

  const OtpHandleSubmit = async () => {
    OTPSubmit(otp, mobileNumber, setShowMenu, position ,setShowMenu1 );
  };

  return (
    <>
       {showMenu1 ? (
        <LeftSideView isVisible={!isGroupOpen}  />
      ) : (
        <>
      {showRegistration ? (
        <RegistrationView pageRedirect={showRegistration} />
      ) : (
        <div className="col-12 d-flex justify-content-center align-items-center Intro-Left1">
          <div className="row d-flex justify-content-center align-items-center h-100 px-0 mx-0">
            <div>
              <div
                className="row justify-content-center mx-0 px-0 "
                style={{ marginBottom: "22%" }}
              >
                <div className="col-12 ">
                  <div className="px-0 ">
                    <div className="px-5 mx-0">
                    <div className=" text-center" >
                                <img
                                  src={require("../../../assets/images/smalll_office_log.png")}
                                  width={200}
                                  alt=""
                                />
                                </div>
                      <div className="">
                        <div className="">
                          <p className="text-center h2 fw-bold mb-3  mt-4">
                            Verify OTP
                          </p>
                        </div>
                        <div className="">
                          <p
                            className="d-flex align-items-center font-size-17"
                            style={{ color: "#999" }}
                          >
                            Please check your Whatsapp &nbsp;
                            {mobileNumber.replace(/.(?=.{4,}$)/g, "*")}
                            &nbsp;and find Six Digit OTP Code
                          </p>
                        </div>

                        <div className=" text-center  py-3">
                          <label htmlFor="OTP" className="  mb-2 fw-bold">
                            Enter OTP <span className="text-danger">*</span>
                          </label>

                          <span
                            style={{ margin: "8px" }}
                            className="d-flex justify-content-center"
                          >
                            <OTPInput
                              value={otp}
                              onChange={setOtp}
                              numInputs={6}
                              renderSeparator={
                                <span style={{ margin: "5px" }}>-</span>
                              }
                              renderInput={(props) => (
                                <input
                                  {...props}
                                  style={{
                                    height: "45px",
                                    width: "45px",
                                    textAlign: "center",
                                  }}
                                />
                              )}
                            />
                          </span>
                        </div>
                        <div className="d-flex justify-content-center">
                          <p className="font-size-15">Didn't get OTP &nbsp;</p>{" "}
                          {clickCount < countResend && (
                            <div>
                              {isButtonVisible ? (
                                <div>
                                  {" "}
                                  <p
                                    className="text-primary font-size-15 fw-bold rounded-1  cursor_pointer"
                                    onClick={() => handleSubmit(false)}
                                  >
                                    {" "}
                                    Resend | Change Number
                                  </p>{" "}
                                </div>
                              ) : (
                                <p className="user-select-none">
                                  {" "}
                                  Resend Time {count}{" "}
                                </p>
                              )}
                            </div>
                          )}
                        </div>

                        <div className="mt-3">
                          <button
                            className="btn text-light w-100 py-2  rounded-1 fw_500"
                            onClick={OtpHandleSubmit}
                            style={{ backgroundColor: "#f58634" }}
                          >
                            {" "}
                            Verify OTP
                          </button>
                        </div>

                        <div className="d-flex justify-content-center mt-4">
                          Are you new user?
                          <span
                            className=" text-primary font-size-15 fw-bold  cursor_pointer"
                            style={{
                              marginLeft: "5px",
                              color: "blue",
                              fontStyle: "italic",
                            }}
                            onClick={() => setShowRegistration(true)}
                          >
                            Create An account
                          </span>
                        </div>
                        <div className="d-flex justify-content-center mt-2 ">
                          <p>Privacy policy | Contact us</p>
                        </div>
                        <small className="d-flex justify-content-center ">
                          VS 1.0.1{" "}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      </>
        )}
    </>
  );
};

export default OTPVerificationView;
