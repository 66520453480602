import { toast } from "react-toastify";
import * as Yup from "yup";
import axiosInstance from "../../../services/axiosInstance";
import { TReactSetState } from "../../../helpers/AppType";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../helpers/AppConstants";

export interface IOTPVerifyViewProps {
  handleSubmit: (status: boolean) => void;
  mobileNumber: string;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  position: number;
}
export interface IOtpValues {
  OTP: string;
}
export const OTPInitialValues = {
  OTP: "",
};

export const OtpValidationSchema = () =>
  Yup.object().shape({
    OTP: Yup.string()
      .min(6, "validationOTPMin")
      .max(6, "validationOTPMax")
      .required("validationOTPRequired"),
  });

export const OTPSubmit = async (
  otp: string,
  username: string,
  setShowMenu: TReactSetState<boolean>,
  position: number,
  setShowMenu1: TReactSetState<boolean>
) => {
  try {
    const response = await axiosInstance.post("verifyOtp", {
      contact_number: username,
      otp: otp,
      request_flag: position,
    });

    if (response.data.code === 200) {
      if (response.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        const storeId = response?.data?.data.item?.id;
        const storeToken = response.data?.data?.token;
        localStorage.setItem("token", storeToken);
        localStorage.setItem("UUID", storeId);
        setShowMenu(true);
        setShowMenu1(true);
      } else {
        setShowMenu(false);
        toast.error(response.data.ack_msg);
      }
    } else {
      setShowMenu(false);

      toast.error(response.data.ack_msg);
    }
  } catch (error: any) {
    toast.error(error?.response.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
