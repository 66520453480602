// SafeHtml.tsx
import React from 'react';
import DOMPurify from 'dompurify';

interface SafeHtmlProps {
  htmlContent: string;
}

const SafeHtml: React.FC<SafeHtmlProps> = ({ htmlContent }) => {
  // Sanitize HTML content before rendering
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);

  return (
    <span
    style={{wordWrap:"break-word", width:"100%"}}
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    />
  );
};

export default SafeHtml;
