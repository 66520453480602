import React, { useState, useEffect, useRef } from "react";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface IEmailCustomEditorProps {
  text1: string;
  setMessage: (message: string) => void;
//   setAttachment: (attachment: File | null) => void;
}

const createEditorState = (text: string): EditorState => {
  const contentState = stateFromHTML(text);
  return EditorState.createWithContent(contentState);
};

const EmailCustomEditor: React.FC<IEmailCustomEditorProps> = ({
    text1,
  setMessage,
//   setAttachment,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

//   useEffect(() => {
//     setEditorState(createEditorState(text || ""));
//   }, [text]);


  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const html = stateToHTML(editorState.getCurrentContent());
        console.log("html",html);
        
    // Update the message state with the HTML content
    setMessage(html);
  };

  const handleFileChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file1 = event.target.files && event.target.files[0];
    console.log("file",file1);
    
    // setAttachment(file1);
  };

  return (
    <div className="w-100 h-100 border" >
      <Editor
        editorState={editorState}
        toolbar={{
          options: ["inline", "list"],
        }}
        onEditorStateChange={onEditorStateChange}
      />
      {/* <div className="bg-light col-12 d-flex">
        <div className="col-6 d-flex align-items-center">
          <div className="chat-attach">
            <button className="icons Marg" id="chat-popup">
              <div className="form-group1">
                <label htmlFor="input-files">
                  <span data-testid="camera" data-icon="camera" className="">
                    <svg viewBox="0 0 24 24" width="24" height="24">
                      <path
                        fill="currentColor"
                        d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"
                      ></path>
                    </svg>
                  </span>
                </label>
                <input
                  type="file"
                  name="image"
                  className="form-control-file border"
                  onChange={handleFileChange1}
                  style={{ display: "none" }}
                  accept=".png,.jpg,.jpeg,.pdf"
                />
              </div>
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default EmailCustomEditor;
