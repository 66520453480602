import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
// import useTranslation from "../../../hooks/useTranslation";
import {
  ILoginValues,
  LoginInitialValues,
  loginSubmit,
  LoginValidationSchema,
} from "./LoginController";
import OTPVerificationView from "../otp-verification/OTPVerificationView";
import LeftSideView from "../../left-side/LeftSideView";
import RegistrationView from "../resternation/RegistrationView";
interface ILoginProps {
  pageRedirect?: boolean;
}
const LoginView = ({ pageRedirect }: ILoginProps) => {
  let isGroupOpen;
  let position = 1;
  const [showOtp, setShowOtp] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showReg, setShowReg] = useState(false);
  const [saveMobileNumber, setSaveMobileNumber] = useState("");

  const handleSubmit = async (values: ILoginValues) => {
    loginSubmit(values, setShowOtp);
    setSaveMobileNumber(values.mobile_number);
  };

  return (
    <>
      {/* {showMenu ? (
        <LeftSideView isVisible={!isGroupOpen} />
      ) : ( */}
      <>
        {showReg ? (
          <RegistrationView />
        ) : (
          <>
            {showOtp ? (
              <OTPVerificationView
                handleSubmit={() => setShowOtp(true)}
                mobileNumber={saveMobileNumber}
                setShowMenu={setShowMenu}
                position={position}
              />
            ) : (
              <div className="col-12 d-flex justify-content-center align-items-center Intro-Left1">
                <Formik
                  initialValues={LoginInitialValues}
                  validationSchema={LoginValidationSchema()}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    values,
                    setFieldValue,
                  }) => (
                    <Form>
                      <div className="container-fluid px-0 mx-0 vh-100">
                        <div className="row d-flex justify-content-center align-items-center h-100 px-0 mx-0">
                          <div className="">
                            <div
                              className="row justify-content-center mx-0 px-0 "
                              style={{ marginBottom: "22%" }}
                            >
                              <div className="col-12 px-5">
                               
                                <div className="px-0 ">
                                 
                                  <div className="px-5 mx-0">
                                  <div className="text-center pb-2">
                                <img
                                  src={require("../../../assets/images/smalll_office_log.png")}
                                  width={200}
                                  alt=""
                                />
                                </div>
                                    <p className="text-center h2 fw-bold  mt-4">
                                      Log In
                                    </p>
                                    <div className="">
                                      <p
                                        className="text-center "
                                        style={{ color: "#999" }}
                                      >
                                        {" "}
                                        Please enter your login detail.
                                      </p>
                                    </div>
                                    <div>
                                      <div className="mb-4">
                                        <label
                                          htmlFor="mobile_number"
                                          className=" d-flex justify-content-center mb-2 fw-semibold font-size-15"
                                        >
                                          Whatsapp Mobile Number{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="mobile_number"
                                          className={`form-control d-flex justify-content-center pl-10 ${
                                            errors.mobile_number &&
                                            touched.mobile_number &&
                                            "is-invalid input-box-error"
                                          }`}
                                          value={values.mobile_number}
                                          onChange={(e: {
                                            target: { value: string };
                                          }) => {
                                            // Remove non-numeric characters from input
                                            const onlyNumbers =
                                              e.target.value.replace(
                                                /[^0-9]/g,
                                                ""
                                              );
                                            // Update the form field value
                                            setFieldValue(
                                              "mobile_number",
                                              onlyNumbers
                                            );
                                          }}
                                        />
                                        <ErrorMessage
                                          name="mobile_number"
                                          component="div"
                                          className="field-error text-danger"
                                        />
                                      </div>
                                      <div className="d-flex justify-content-center  mb-2 mb-lg-3">
                                        <button
                                          type="submit"
                                          className="btn  text-light w-100 py-2  rounded-1 fw_500"
                                          onClick={() => loginSubmit}
                                          style={{
                                            backgroundColor: "#f58634",
                                          }}
                                        >
                                          Login now
                                        </button>
                                      </div>
                                      <div className="d-flex justify-content-center mt-4">
                                        Are you new user?
                                        <span
                                          className=" text-primary font-size-15 fw-bold  cursor_pointer"
                                          style={{
                                            marginLeft: "5px",
                                            color: "blue",
                                            fontStyle: "italic",
                                          }}
                                          onClick={() => setShowReg(true)}
                                        >
                                          Create An account
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-center mt-2">
                                        <p>Privacy policy | Contact us</p>
                                      </div>
                                      <small className="d-flex justify-content-center ">
                                        VS 1.0.1{" "}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </>
        )}
      </>
      {/* )} */}
    </>
  );
};

export default LoginView;
