import { toast } from "react-toastify";
import { TReactSetState } from "../../../../../helpers/AppType";
import axiosInstance from "../../../../../services/axiosInstance";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../../../helpers/AppConstants";
import { checkDuplication } from "../../../../../common/SharedFunction";

export interface ICategoryView {
  category_name: string;
  id: number;
  color: string | undefined | null;
  created_date_time?: string;
}

export interface ICategoryCreate {
  category_name: string;
  color: string | undefined | null;
  created_date_time?: string;
}

export const handleDeleteCategory = async (
  categoryId: number | undefined,
  setIsDeleteConfirmation: TReactSetState<boolean>,
  setCategoryList: TReactSetState<ICategoryView[]>
) => {
  const requestData = {
    table: "categories",
    where: `{"id":${categoryId}}`,
    data: `{"isDelete":"1"}`,
  };
  try {
    const data = await axiosInstance.post("commonUpdate", requestData);
    if (data.data.code === 200) {
      if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        setIsDeleteConfirmation(false);
        fetchCategoryApi(setCategoryList);
      } else {
        toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const createCategory = async (
  categoryInput: ICategoryCreate,
  setCategoryList: TReactSetState<ICategoryView[]>
) => {
  if (
    !(await checkDuplication(
      categoryInput.category_name,
      "categories",
      "category_name"
    ))
  ) {
    const getUUID = await localStorage.getItem("UUID");
    const requestData = {
      table: "categories",
      data: `{"category_name":"${categoryInput.category_name}","color":"${
        categoryInput.color
      }","a_application_login_id":${Number(getUUID)}}`,
    };
    try {
      const { data } = await axiosInstance.post("commonCreate", requestData);
      if (data.code === 200) {
        if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
          //   console.log("dadad", data);
          fetchCategoryApi(setCategoryList);
        } else {
          toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
        }
      }
    } catch (error: any) {
      toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } else {
    toast.error("Category already available");
  }
};

export const updateCategory = async (
  categoryInput: ICategoryCreate,
  setCategoryList: TReactSetState<ICategoryView[]>,
  editCategoryId: number | undefined
) => {
  const getUUID = await localStorage.getItem("UUID");
  const requestData = {
    table: "categories",
    where: `{"id":"${editCategoryId}"}`,
    data: `{"category_name":"${categoryInput.category_name}","color":"${
      categoryInput.color
    }","a_application_login_id":${Number(getUUID)}}`,
  };
  try {
    const { data } = await axiosInstance.post("commonUpdate", requestData);
    if (data.code === 200) {
      if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        //   console.log("dadad", data);
        setCategoryList((prevList) =>
          prevList.map((category) =>
            category.id === editCategoryId ? data.data : category
          )
        );
        fetchCategoryApi(setCategoryList);
      } else {
        toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
export const fetchCategoryApi = async (
  setCategoryList: TReactSetState<ICategoryView[]>
) => {
  const getUUID = await localStorage.getItem("UUID");
  const requestData = {
    table: "categories",
    columns: "id,category_name,color",
    // where: `{"isDelete":"0","a_application_login_id":"${getUUID}"}`,
    where: ["isDelete=0", `a_application_login_id=${getUUID}||0`],
    request_flag: 0,
    order: `{"id":"DESC"}`,
  };
  try {
    const data = await axiosInstance.post("commonGet", requestData);
    if (data.data.ack !== DEFAULT_STATUS_CODE_SUCCESS) {
      setCategoryList([]);

      // toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
    setCategoryList(data.data.data);
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
