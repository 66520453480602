import React, { useEffect, useState } from "react";
import LeftSideView from "../left-side/LeftSideView";
import LoginView from "./login/LoginView";
import axiosInstance from "../../services/axiosInstance";
import { toast } from "react-toastify";
import { MESSAGE_UNKNOWN_ERROR_OCCURRED } from "../../helpers/AppConstants";

const Index = () => {
  let isGroupOpen;
  const [timestamp, setTimestamp] = useState(true);
  const [checkToken, setCheckToken] = useState(false);
  const [checkToken1, setCheckToken1] = useState(false);
  console.log("checkToken",checkToken);
  
  useEffect(() => {
    // Update timestamp every second using setTimeout
    const token = localStorage.getItem("token");
    
    const LoginSubmit = async () => {
      const getUUID = await localStorage.getItem("UUID");
       
        
      if(!getUUID || getUUID === ""  ){
        console.log("getuuid1",getUUID);
        setCheckToken1(true);
    } else {
      console.log("getuuid2",getUUID);
      setCheckToken1(false);
    }
      try {
        const response = await axiosInstance.get("onLoad", {
          headers: token ? { Authorization: `${token}` } : {},
        });
        if (response.data.ack === 1) {
          setCheckToken(false);
        } else {
          setCheckToken(true);
          localStorage.clear();
        }
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          setCheckToken(true); // Set checkToken to true for 401 Unauthorized
          // Optionally, handle any other logic for 401 Unauthorized here
        } else {
          toast.error(
            error.response.data.message || MESSAGE_UNKNOWN_ERROR_OCCURRED
          );
        }
      }
    };
    LoginSubmit();
    if (!token || token === "" || null) {
      setCheckToken(true);
    } else {
    }

    const interval = setInterval(() => {
      setTimestamp(false);
    }, 1000);

    // Clean up the interval to avoid memory leaks
    return () => clearInterval(interval);
  }, []);

  // const token =   localStorage.getItem("token");
  //     console.log("token", token);
  //     if (token === null) {
  //       setCheckToken(false)
  //     }else{
  //       setCheckToken(true)
  //     }

  return (
    <>
      {timestamp ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="  border ">
            <img
              width={300}
              src={require("../../assets/images/smalll_office_log.png")}
              alt=""
            />
          </div>
        </div>
      ) : (
        <>
          {checkToken || checkToken1? (
            <LoginView />
          ) : (
            <LeftSideView isVisible={!isGroupOpen} />
          )}
        </>
      )}
    </>
  );
};

export default Index;
