import React, { ChangeEvent, useEffect, useState } from "react";
import axiosInstance from "../../../../services/axiosInstance";
import axios from "axios";
import { toast } from "react-toastify";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../../helpers/AppConstants";
interface IPropsProfileSetting {
  isProfileOpen: boolean;
  closeProfile: () => void;
  profileDetail: any;
}
const ProfileSetting = ({
  isProfileOpen,
  closeProfile,
  profileDetail,
}: IPropsProfileSetting) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loginById, setLoginById] = useState<any>();
  const token = localStorage.getItem("token");
  const getUUID = localStorage.getItem("UUID");
  // const [selectedGender, setSelectedGender] = useState(profileDetail.gender);
  const [isUsernameEditMode, setIsUsernameEditMode] = useState(false);
  // const [isGenderEditMode, setIsGenderEditMode] = useState(false);
  const [isEmailEditMode, setIsEmailEditMode] = useState(false);
  const [editedUsername, setEditedUsername] = useState(profileDetail.username);
  const [editedEmail, setEditedEmail] = useState(profileDetail.recovery_email);
  const [emailError, setEmailError] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isLoadApi, setIsLoadApi] = useState(false);
  const [selectedGender, setSelectedGender] = useState(profileDetail.gender);
  const [tempGender, setTempGender] = useState(profileDetail.gender);
  const [isGenderEditMode, setIsGenderEditMode] = useState(false);

  const handleGenderChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTempGender(Number(event.target.value));
  };

  const handleConfirmClickGender = async () => {
    const newGender = tempGender;
    setSelectedGender(newGender);
    const requestData = {
      table: "a_application_logins",
      where: `{"id":"${profileDetail.id}"}`,
      data: `{"gender":"${newGender}"}`,
    };
    try {
      const { data } = await axiosInstance.post("commonUpdate", requestData);
      if (data.code === 200) {
        if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
          // setIsDeleteConfirmation(false);
        } else {
          toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
        }
      }
    } catch (error: any) {
      toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
    setIsGenderEditMode(false);
  };

  const handleCancelClickGender = () => {
    setTempGender(selectedGender);
    setIsGenderEditMode(false);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setSelectedFile(file);
      
      if (!getUUID || !file) {
        return;
      }
      const formData = new FormData();
      formData.append("image", file);
      formData.append("id", getUUID);

      // console.log("formData", formData);

      try {
        
        const response = await axios.post(
          "https://backend.smalloffice.in/api/changeProfile",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Set content type for FormData
            },
          }
        );
        window.location.reload();

        if (response.data.code === 200) {
          // Handle success
         
          console.log("Profile image changed successfully", response.data);

        } else {
          // Handle other status codes or errors
          console.error("Failed to change profile image");
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Error changing profile image:", error);
      }
      // setSelectedFile(file);
    }
  };

  const handleUsernameEditClick = () => {
    setIsUsernameEditMode(true);
  };

  const handleEmailEditClick = () => {
    setIsEmailEditMode(true);
  };

  const handleUsernameSaveClick = async () => {
    // Perform save operation for username (e.g., API call)
    const requestData = {
      table: "a_application_logins",
      where: `{"id":"${profileDetail.id}"}`,
      data: `{"username":"${editedUsername}"}`,
    };
    try {
      const { data } = await axiosInstance.post("commonUpdate", requestData);
      if (data.code === 200) {
        if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
          // setIsDeleteConfirmation(false);
          setIsLoadApi(true);
        } else {
          toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
        }
      }
    } catch (error: any) {
      toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
    setIsUsernameEditMode(false);
    // Update profile detail or perform necessary actions
  };

  const handleEmailSaveClick = async () => {
    // Perform save operation for email (e.g., API call)
    const requestData = {
      table: "a_application_logins",
      where: `{"id":"${profileDetail.id}"}`,
      data: `{"recovery_email":"${editedEmail}"}`,
    };
    try {
      const { data } = await axiosInstance.post("commonUpdate", requestData);
      if (data.code === 200) {
        if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
          // setIsDeleteConfirmation(false);

        } else {
          toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
        }
      }
    } catch (error: any) {
      toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
    setIsEmailEditMode(false);
    // Update profile detail or perform necessary actions
  };

  const handleCancelClick = () => {
    // Reset edited values and toggle off edit mode
    setEditedUsername(profileDetail.username);
    // setEditedEmail(profileDetail.recovery_email);
    setIsUsernameEditMode(false);
    // setIsEmailEditMode(false);
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedUsername(event.target.value);
  };

  const handleEmailChange = (e: any) => {
    const email = e.target.value;
    setEditedEmail(email);

    if (validateEmail(email)) {
      setEmailError("");
      setIsEmailValid(true);
    } else {
      setEmailError("Please enter a valid email address.");
      setIsEmailValid(false);
    }
  };

  const validateEmail = (email: any) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const fetchGetByIdUser = async () => {
    const token = await localStorage.getItem("token");
    const localId = await localStorage.getItem("UUID");
    try {
      const { data } = await axiosInstance.post(
        "loginId",
        {
          loginId: localId,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      if (data.code === 200) {
        if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
          setLoginById(data.data);
        } else {
          toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
        }
      }
      // setNoDataFound(data.data.item.length === 0);
    } catch (error: any) {
      toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  };
  useEffect(() => {
    // if(isLoadApi === true){
    fetchGetByIdUser();
    // }
  }, []);
  return (
    <>
      {isProfileOpen ? (
        <div
          className="profile animate__animated animate__fadeInLeft"
          id="profile"
        >
          {/* <!-- Header --> */}
          <div className="header-Chat">
            {/* <!-- Icons --> */}
            <div className="ICON">
              <div
                aria-disabled="false"
                role="button"
                className="icons"
                data-tab="2"
                title="New chat"
                aria-label="New chat"
                onClick={closeProfile}
              >
                <span data-testid="chat" data-icon="chat" className="">
                  <svg viewBox="0 0 24 24" width="24" height="24" className="">
                    <path
                      fill="currentColor"
                      d="m12 4 1.4 1.4L7.8 11H20v2H7.8l5.6 5.6L12 20l-8-8 8-8z"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>

            <div className="newText">
              <h2>Profile</h2>
            </div>
          </div>
          {/* <!-- Chats --> */}
          <div className="chats-profile">
            {/* <!-- Profile --> */}
            <div className="top">
              <div className="imgBox">
                {profileDetail?.profile_pic || loginById?.profile_pic ? (
                  <img
                    // src={`${"https://backend.smalloffice.in/"}${profileDetail?.profile_pic}`}
                    // src={`https://backend.smalloffice.in/${loginById?.profile_pic}`}
                    src={
                      selectedFile
                        ? URL.createObjectURL(selectedFile)
                        : `https://backend.smalloffice.in/${loginById?.profile_pic}`
                    }
                    // {`http://localhost:8080/images/${item.image}`}
                    alt=""
                    className="cover animate__animated animate__fadeIn"
                  />
                ) : (
                  <img
                    src={require("../../../../assets/images/no_image.jpeg")}
                    alt=""
                    className="cover animate__animated animate__fadeIn"
                  />
                )}
                <div className="middle">
                  <div
                    aria-disabled="false"
                    role="button"
                    className="icons-profile"
                    data-tab="2"
                    title="Camera"
                    aria-label="Camera"
                  >
                    <div className="form-group1">
                      <label htmlFor="input-files">
                        <span
                          data-testid="camera"
                          data-icon="camera"
                          className=""
                        >
                          <svg
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className=""
                          >
                            <path
                              fill="currentColor"
                              d="M21.317 4.381H10.971L9.078 2.45c-.246-.251-.736-.457-1.089-.457H4.905c-.352 0-.837.211-1.078.468L1.201 5.272C.96 5.529.763 6.028.763 6.38v1.878l-.002.01v11.189a1.92 1.92 0 0 0 1.921 1.921h18.634a1.92 1.92 0 0 0 1.921-1.921V6.302a1.92 1.92 0 0 0-1.92-1.921zM12.076 18.51a5.577 5.577 0 1 1 0-11.154 5.577 5.577 0 0 1 0 11.154zm0-9.506a3.929 3.929 0 1 0 0 7.858 3.929 3.929 0 0 0 0-7.858z"
                            ></path>
                          </svg>
                        </span>
                      </label>
                      <input
                        type="file"
                        name="image"
                        id="input-files"
                        className="form-control-file border"
                        onChange={(e) => handleFileChange(e)}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                  <div className="text">CHANGE PROFILE PHOTO</div>
                </div>
              </div>
            </div>

            {/* <!-- Chats 1 --> */}
            <div className="block">
              {/* <!-- Text --> */}
              <div className="h-text">
                <div className="titlePro">
                  <p>Your Name</p>
                </div>

                <div className="head">
                  {isUsernameEditMode ? (
                    <>
                      <div className="search-bar">
                        <div className="add-source-of-type-section">
                          <input
                            type="text"
                            value={editedUsername}
                            onChange={handleUsernameChange}
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <button onClick={handleUsernameSaveClick}>
                          <span>
                            <svg
                              data-name="Layer 1"
                              height={24}
                              id="Layer_1"
                              viewBox="0 0 200 200"
                            >
                              <title />
                              <path
                                fill="currentColor"
                                d="M177.68,43.9c-4.5-3.5-10.5-3-14,1.5l-74,89.5-55-40c-4.5-3-10.5-2.5-14,2-3,4.5-2.5,10.5,2,14l62.5,45.5a.49.49,0,0,1,.5.5c.5,0,.5.5,1,.5s.5.5,1,.5.5,0,1,.5h6c.5,0,.5,0,1-.5.5,0,.5-.5,1-.5s.5-.5,1-.5.5-.5,1-.5a.49.49,0,0,0,.5-.5l.5-.5,80-97C182.18,53.9,181.68,47.4,177.68,43.9Z"
                              />
                            </svg>
                          </span>
                        </button>
                        <span className="m-1">|</span>
                        <button onClick={handleCancelClick}>
                          <svg
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="#5f6368"
                          >
                            <path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                          </svg>
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <h4>{profileDetail.username}</h4> */}
                      <h4>{editedUsername}</h4>
                      <div
                        aria-disabled="false"
                        role="button"
                        className="icons-prof"
                        data-tab="2"
                        title="Edit"
                        aria-label="Edit"
                        onClick={handleUsernameEditClick}
                      >
                        <span
                          data-testid="pencil"
                          data-icon="pencil"
                          className=""
                        >
                          <svg
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className=""
                          >
                            <path
                              fill="currentColor"
                              d="M3.95 16.7v3.4h3.4l9.8-9.9-3.4-3.4-9.8 9.9zm15.8-9.1c.4-.4.4-.9 0-1.3l-2.1-2.1c-.4-.4-.9-.4-1.3 0l-1.6 1.6 3.4 3.4 1.6-1.6z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="warning">
              <div className="warn-text">
                <h4>
                  This is not your username or pin. This name will be visible to
                  your small office crm contacts.
                </h4>
              </div>
            </div>

            <div className="block">
              {/* <!-- Text --> */}
              <div className="h-text">
                <div className="titlePro">
                  <p>Mobile Number</p>
                </div>

                <div className="head">
                  <h4>{profileDetail?.recovery_mobile}</h4>
                  {/* <div
                    aria-disabled="false"
                    role="button"
                    className="icons-prof"
                    data-tab="2"
                    title="Edit"
                    aria-label="Edit"
                  >
                    <span data-testid="pencil" data-icon="pencil" className="">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className=""
                      >
                        <path
                          fill="currentColor"
                          d="M3.95 16.7v3.4h3.4l9.8-9.9-3.4-3.4-9.8 9.9zm15.8-9.1c.4-.4.4-.9 0-1.3l-2.1-2.1c-.4-.4-.9-.4-1.3 0l-1.6 1.6 3.4 3.4 1.6-1.6z"
                        ></path>
                      </svg>
                    </span>
                  </div> */}
                </div>
              </div>
            </div>

            {/* <!-- Chats 2 --> */}
            <div className="block">
              {/* <!-- Text --> */}
              <div className="h-text">
                <div className="titlePro">
                  <p>Gender</p>
                </div>

                {isGenderEditMode ? (
                  <div className="head">
                    <div className="d-flex gap-2">
                      <label>
                        <input
                          type="radio"
                          className="mx-1"
                          value="1"
                          checked={tempGender === 1}
                          onChange={handleGenderChange}
                        />
                        Male
                      </label>

                      <label>
                        <input
                          type="radio"
                          value="2"
                          className="mx-1"
                          checked={tempGender === 2}
                          onChange={handleGenderChange}
                        />
                        Female
                      </label>

                      <label>
                        <input
                          type="radio"
                          value="3"
                          className="mx-1"
                          checked={tempGender === 3}
                          onChange={handleGenderChange}
                        />
                        Other
                      </label>
                    </div>

                    {isGenderEditMode && (
                      <div className="d-flex">
                        <button onClick={handleConfirmClickGender}>
                          <span>
                            <svg
                              data-name="Layer 1"
                              height={24}
                              id="Layer_1"
                              viewBox="0 0 200 200"
                            >
                              <title />
                              <path
                                fill="currentColor"
                                d="M177.68,43.9c-4.5-3.5-10.5-3-14,1.5l-74,89.5-55-40c-4.5-3-10.5-2.5-14,2-3,4.5-2.5,10.5,2,14l62.5,45.5a.49.49,0,0,1,.5.5c.5,0,.5.5,1,.5s.5.5,1,.5.5,0,1,.5h6c.5,0,.5,0,1-.5.5,0,.5-.5,1-.5s.5-.5,1-.5.5-.5,1-.5a.49.49,0,0,0,.5-.5l.5-.5,80-97C182.18,53.9,181.68,47.4,177.68,43.9Z"
                              />
                            </svg>
                          </span>
                        </button>
                        <span className="m-1">|</span>
                        <button onClick={handleCancelClickGender}>
                          <svg
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="#5f6368"
                          >
                            <path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                          </svg>
                        </button>
                      </div>
                    )}

                    {!isGenderEditMode && (
                      <button onClick={() => setIsGenderEditMode(true)}>
                        Edit Gender
                      </button>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="d-flex justify-content-between">
                      <p>
                        {selectedGender === 1
                          ? "Male"
                          : selectedGender === 2
                          ? "Female"
                          : "Other"}
                      </p>
                      <div
                        aria-disabled="false"
                        role="button"
                        className="icons-prof"
                        data-tab="2"
                        title="Edit"
                        aria-label="Edit"
                        onClick={() => setIsGenderEditMode(true)}
                      >
                        <span
                          data-testid="pencil"
                          data-icon="pencil"
                          className=""
                        >
                          <svg
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className=""
                          >
                            <path
                              fill="currentColor"
                              d="M3.95 16.7v3.4h3.4l9.8-9.9-3.4-3.4-9.8 9.9zm15.8-9.1c.4-.4.4-.9 0-1.3l-2.1-2.1c-.4-.4-.9-.4-1.3 0l-1.6 1.6 3.4 3.4 1.6-1.6z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="block">
              {/* <!-- Text --> */}
              <div className="h-text">
                <div className="titlePro">
                  <p>Email</p>
                </div>

                <div className="head">
                  {/* <h4>{profileDetail.recovery_email}</h4> */}
                  {/* Email Section */}
                  {isEmailEditMode ? (
                    <>
                      <div className="search-bar">
                        <div className="add-source-of-type-section">
                          <input
                            type="email"
                            value={editedEmail}
                            onChange={handleEmailChange}
                            className={emailError ? "error" : ""}
                          />
                        </div>
                      </div>

                      <div className="d-flex">
                        {isEmailValid && (
                          <button onClick={handleEmailSaveClick}>
                            <svg
                              data-name="Layer 1"
                              height={24}
                              id="Layer_1"
                              viewBox="0 0 200 200"
                            >
                              <title />
                              <path
                                fill="currentColor"
                                d="M177.68,43.9c-4.5-3.5-10.5-3-14,1.5l-74,89.5-55-40c-4.5-3-10.5-2.5-14,2-3,4.5-2.5,10.5,2,14l62.5,45.5a.49.49,0,0,1,.5.5c.5,0,.5.5,1,.5s.5.5,1,.5.5,0,1,.5h6c.5,0,.5,0,1-.5.5,0,.5-.5,1-.5s.5-.5,1-.5.5-.5,1-.5a.49.49,0,0,0,.5-.5l.5-.5,80-97C182.18,53.9,181.68,47.4,177.68,43.9Z"
                              />
                            </svg>
                          </button>
                        )}
                        <span className="m-1">|</span>
                        <button onClick={() => setIsEmailEditMode(false)}>
                          <svg
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="#5f6368"
                          >
                            <path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                          </svg>
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <h4>{profileDetail.recovery_email }</h4> */}
                      <h4>{editedEmail}</h4>

                      <div
                        aria-disabled="false"
                        role="button"
                        className="icons-prof"
                        data-tab="2"
                        title="Edit"
                        aria-label="Edit"
                        onClick={handleEmailEditClick}
                      >
                        <span
                          data-testid="pencil"
                          data-icon="pencil"
                          className=""
                        >
                          <svg
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className=""
                          >
                            <path
                              fill="currentColor"
                              d="M3.95 16.7v3.4h3.4l9.8-9.9-3.4-3.4-9.8 9.9zm15.8-9.1c.4-.4.4-.9 0-1.3l-2.1-2.1c-.4-.4-.9-.4-1.3 0l-1.6 1.6 3.4 3.4 1.6-1.6z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </>
                  )}
                </div>
                {emailError && (
                  <div className="error-message ">{emailError}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProfileSetting;
