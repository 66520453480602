import React from "react";

import axios from 'axios';


// const getAuthorizationToken = async () => {
  // const token =   localStorage.getItem("token");
//   if (token) {
//     return token;
//   }
//   return "API_PUBLIC_AUTHORIZATION_TOKEN";
// };

const axiosInstance = axios.create({
  baseURL: 'https://backend.smalloffice.in/api', // Replace with your API base URL
  timeout: 10000, // Timeout after 10 seconds
  headers: {
    'Content-Type': 'application/json',
    // You can add other headers here, such as Authorization headers
    // 'Authorization': `${token}`
    'Access-Control-Allow-Origin':''
  },
});

export default axiosInstance;

