import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import OTPVerificationView from "../otp-verification/OTPVerificationView";
import {
  IRegistration,
  registrationInitialValues,
  registrationSubmit,
  registrationValidationSchema,
} from "./RegistrationController";
import LeftSideView from "../../left-side/LeftSideView";
import LoginView from "../login/LoginView";
interface IRegistrationProps {
  pageRedirect?: boolean;
}
const RegistrationView = ({ pageRedirect }: IRegistrationProps) => {
  let isGroupOpen;
  const [showOtp, setShowOtp] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [saveMobileNumber, setSaveMobileNumber] = useState("");
  const [showLogin, setShowLogin] = useState(false);

  const handleSubmit = async (values: IRegistration) => {
    registrationSubmit(values, setShowOtp, setShowMenu);
    setSaveMobileNumber(values.mobile_number);
  };

  return (
    <>
      {showLogin ? (
        <LoginView pageRedirect={showLogin} />
      ) : (
        <>
          {showMenu ? (
            <LeftSideView isVisible={!isGroupOpen} />
          ) : (
            <>
              {showOtp ? (
                <>
                  <div className="col-12 d-flex justify-content-center align-items-center Intro-Left1">
                    <Formik
                      initialValues={registrationInitialValues}
                      validationSchema={registrationValidationSchema()}
                      onSubmit={handleSubmit}
                    >
                      {({
                        errors,
                        touched,
                        isSubmitting,
                        values,
                        setFieldValue,
                      }) => (
                        <Form>
                          <div className="container-fluid px-0 mx-0 vh-100">
                            <div className="row d-flex justify-content-center align-items-center h-100 px-0 mx-0">
                              <div>
                                <div
                                  className="row justify-content-center mx-0 px-0 "
                                  style={{ marginBottom: "22%" }}
                                >
                                  <div className="col-12 px-5">
                                    <div className="px-0 ">
                                      <div className="px-5 mx-0">
                                        <div className="text-center pb-2">
                                          <img
                                            src={require("../../../assets/images/smalll_office_log.png")}
                                            width={200}
                                            alt=""
                                          />
                                        </div>
                                        <p className="text-center h2 fw-bold  mt-4">
                                          Registration
                                        </p>
                                        <div className="d-flex justify-content-center pb-2">
                                          <p style={{ color: "#999" }}>
                                            {" "}
                                            Please enter your personal detail
                                            for new registration
                                          </p>
                                        </div>
                                        {/* {showOtp ? ( */}
                                        <div>
                                          <div className="form-group mb-4">
                                            <label
                                              htmlFor="username"
                                              className="mb-2  d-flex justify-content-center  fw-semibold font-size-15"
                                            >
                                              Your Name{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              type="text"
                                              name="username"
                                              className={`form-control pl-10 ${
                                                errors.username &&
                                                touched.username &&
                                                "is-invalid input-box-error"
                                              }`}
                                              value={values.username}
                                            />
                                            <ErrorMessage
                                              name="username"
                                              component="div"
                                              className="field-error text-danger"
                                            />
                                          </div>
                                          <div className="form-group mb-4">
                                            <label
                                              htmlFor="mobile_number"
                                              className="mb-2  d-flex justify-content-center  fw-semibold font-size-15"
                                            >
                                              Whatsapp Mobile Number{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              type="text"
                                              name="mobile_number"
                                              className={`form-control pl-10 ${
                                                errors.mobile_number &&
                                                touched.mobile_number &&
                                                "is-invalid input-box-error"
                                              }`}
                                              value={values.mobile_number}
                                              onChange={(e: {
                                                target: { value: string };
                                              }) => {
                                                // Remove non-numeric characters from input
                                                const onlyNumbers =
                                                  e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                  );
                                                // Update the form field value
                                                setFieldValue(
                                                  "mobile_number",
                                                  onlyNumbers
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              name="mobile_number"
                                              component="div"
                                              className="field-error text-danger"
                                            />
                                          </div>
                                          <div className="d-flex justify-content-center  mb-2 mb-lg-3">
                                            <button
                                              type="submit"
                                              className="btn text-light w-100 py-2  rounded-1 fw_500"
                                              onClick={() => registrationSubmit}
                                              style={{
                                                backgroundColor: "#f58634",
                                              }}
                                            >
                                              Registration Now
                                            </button>
                                          </div>
                                          <div className="d-flex justify-content-center mt-4">
                                            Already registered user?
                                            <span
                                              className=" text-primary font-size-15 fw-bold  cursor_pointer"
                                              style={{
                                                marginLeft: "5px",
                                                color: "blue",
                                                fontStyle: "italic",
                                              }}
                                              onClick={() => setShowLogin(true)}
                                            >
                                              Login here
                                            </span>
                                          </div>
                                          <div className="d-flex justify-content-center mt-2 ">
                                            <p>Privacy policy | Contact us</p>
                                          </div>
                                          <small className="d-flex justify-content-center ">
                                            VS 1.0.1{" "}
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </>
              ) : (
                <OTPVerificationView
                  handleSubmit={() => setShowOtp(true)}
                  mobileNumber={saveMobileNumber}
                  setShowMenu={setShowMenu}
                  position={2}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default RegistrationView;
