import React, { useEffect, useRef, useState } from "react";
import {
  createVaiLink,
  fetchCompanyApi,
  ICompany,
} from "./ListCompanyController";
import Skeleton from "react-loading-skeleton";
import { useTheme } from "../../../components/ThemeContext";
import { formatDate } from "../../../common/SharedFunction";
import CreateCompanyView from "../create-company/CreateCompanyView";
import JoinModal from "../../../components/model/JoinModal";
import MyCompanyList from "./MyCompanyList";
// import {
//   createSourceOfType,
//   fetchSourceOfTypesApi,
//   handleDeleteSourceType,
//   ISourceOfTypes,
// } from "./SourceOfTypesController";
// import { TOnChangeInput } from "../../../../../helpers/AppType";
// import ConfirmationModal from "../../../../../components/model/ConfirmationModal";

interface IPropsCompany {
  isCompanyOpen: boolean;
  closeCompany: () => void;
}

const ListCompanyView = ({ isCompanyOpen, closeCompany }: IPropsCompany) => {
  const { darkMode, toggleTheme } = useTheme();
  const dropdownCompanyRef = useRef<Record<number, HTMLUListElement | null>>(
    {}
  );

  const [companyLists, setCompanyLists] = useState<ICompany[]>([]);
  const [companyDropdownOpen, setCompanyDropdownOpen] = useState<any>(null);
  const [hasOneData, setHasOneData] = useState<number>();

  const [loading, setLoading] = useState(false);
  const [refersh, setRefresh] = useState(false);
  const [isJoinConfirmation, setIsJoinConfirmation] = useState(false);
  const [showCompany, setShowCompany] = useState(false);
  const [isCreateCompany, setIsCreateCompany] = useState(false);
  const [companyInfo, setCompanyInfo] = useState<ICompany>();
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const [noDataFound, setNoDataFound] = useState();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [companyToEdit, setCompanyToiEdit] = useState<ICompany>();
  const [showEditCompany, setShowEditCompany] = useState(false);
  
  useEffect(() => {
    // if (isSourceOfTypeOpen) {
    fetchCompanyApi(setCompanyLists ,"" , setNoDataFound);
    // }
  }, [refersh,isJoinConfirmation]);
  const handleJoinCompany = async (data: { remark: string }) => {
    if (data.remark.trim()) {
      createVaiLink(data.remark, setIsJoinConfirmation);
    } else {
      setIsJoinConfirmation(true);
    }
  };

  const openCompanySide = (singleData: ICompany) => {
    setShowCompany(true);
    setCompanyInfo(singleData);
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value.length >= 3 || value === "") {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      setSearchTimeout(
        setTimeout(() => {
          fetchCompanyApi(setCompanyLists ,searchTerm , setNoDataFound);
          // setCurrentPage(0); // Reset page to 0 when search term changes
        }, 1000)
      );
    }
  };
  const toggleDropdownCompany = (id: number) => {
    setHasOneData(id);
    setCompanyDropdownOpen((prevId: any) => (prevId === id ? null : id));
  };
  const handleClickOutside = (event: { target: any }) => {
    const clickedOutside = Object.values(dropdownCompanyRef.current).every(
      (ref) => ref && !ref.contains(event.target)
    );
    if (clickedOutside) {
      setCompanyDropdownOpen(null);
    }
  };
  useEffect(() => {
    if (companyDropdownOpen !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [companyDropdownOpen]);
  const handelChangeEditCompany = (companyById: ICompany) => {
    setShowEditCompany(true);
    setCompanyToiEdit(companyById);
  };
  return (
    <>
      {showCompany ? (
        <MyCompanyList
          isCompanyOpen={showCompany}
          closeCompany={() => setShowCompany(false)}
          companyInfo={companyInfo}
        />
      ) : (
        <>
          {isCompanyOpen ? (
            <>
              <div
                className="notifications animate__animated animate__fadeInLeft"
                id="notifications"
              >
                {/* <!-- Header --> */}

                <div className="header-Chat justify-content-between">
                  {/* <!-- Icons --> */}
                  <div className="d-flex ">
                    <div className="ICON">
                      <div
                        aria-disabled="false"
                        role="button"
                        className="icons"
                        data-tab="2"
                        title="New chat"
                        aria-label="New chat"
                        onClick={closeCompany}
                      >
                        <span data-testid="chat" data-icon="chat" className="">
                          <svg
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className=""
                          >
                            <path
                              fill="currentColor"
                              d="m12 4 1.4 1.4L7.8 11H20v2H7.8l5.6 5.6L12 20l-8-8 8-8z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div className="newText">
                      <h2>My Company</h2>
                    </div>
                  </div>
                  {/* <div
                    className="newText text-end"
                    data-tab="2"
                    role="button"
                    onClick={() => setIsJoinConfirmation(true)}
                  >
                    <h2> Join Company</h2>
                  </div> */}
                </div>

                {/* <!-- Chats --> */}
                <div className="chats-notifications">
                  {/* <!-- Chats 1 --> */}
                  <div className="block">
                    {/* <!-- Text --> */}
                    <div className="h-text">
                      <div className="head" style={{ display: "block" }}>
                        <div className="col-12 d-flex">
                          <div className="col-10">
                            <div className="search-bar ">
                              <div className="add-source-of-type-section ">
                                <input
                                  type="text"
                                  title="Add Source Of Type"
                                  placeholder="Search company"
                                  value={searchTerm}
                                  onChange={handleSearchChange}
                                  // onChange={(e) => handelChange(e)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-2 d-flex justify-content-end align-items-center mx-1">
                              {noDataFound ? 
                            <button
                              className=""
                              onClick={() => setIsCreateCompany(true)}
                            >
                              <span>
                                <svg
                                  height="24px"
                                  viewBox="0 -960 960 960"
                                  width="24px"
                                  fill="currentColor"
                                >
                                  <path d="M720-40v-120H600v-80h120v-120h80v120h120v80H800v120h-80ZM80-160v-240H40v-80l40-200h600l40 200v80h-40v120h-80v-120H440v240H80Zm80-80h200v-160H160v160Zm-38-240h516-516ZM80-720v-80h600v80H80Zm42 240h516l-24-120H146l-24 120Z" />
                                </svg>
                              </span>
                            </button>
                            : <span></span>}
                          </div>
                        </div>
                      </div>

                      <div className="chats">
                        <>
                          {loading
                            ? // Render skeleton placeholders when loading
                              Array.from({ length: 12 }).map((_, index) => (
                                <div className="block chat-list" key={index}>
                                  <Skeleton
                                    width={50}
                                    height={50}
                                    circle={true}
                                    duration={5}
                                    style={{ opacity: darkMode ? "" : 0.5 }}
                                  />
                                  <div className="h-text">
                                    <div className="head">
                                      <h4>
                                        <Skeleton
                                          style={{
                                            marginLeft: "10px",
                                            opacity: darkMode ? "" : 0.5,
                                          }}
                                          width={100}
                                        />
                                      </h4>
                                      <p className="time">
                                        <Skeleton
                                          width={80}
                                          style={{
                                            opacity: darkMode ? "" : 0.5,
                                          }}
                                          height={10}
                                        />
                                      </p>
                                    </div>
                                    <div className="message-chat">
                                      <div className="chat-text-icon">
                                        <span className="thanks">
                                          <Skeleton
                                            style={{
                                              marginLeft: "10px",
                                              opacity: darkMode ? "" : 0.5,
                                            }}
                                            width={100}
                                          />
                                        </span>
                                        <div className="icon-more">
                                          <Skeleton
                                            width={40}
                                            style={{
                                              opacity: darkMode ? "" : 0.5,
                                            }}
                                            height={10}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : // Render actual user data when not loading
                              companyLists &&
                              companyLists.map((item, index) => (
                                <>
                                  <div>
                                  <ul
                                    className={`labelDropLeft ${
                                      hasOneData === item.id &&
                                      companyDropdownOpen
                                        ? "isVisible"
                                        : "isHidden"
                                    } `}
                                    ref={(el) =>
                                      (dropdownCompanyRef.current[item.id] = el)
                                    }
                                  >
                                    <li
                                      className="listItem"
                                      role="button"
                                      onClick={() => handelChangeEditCompany(item)}
                                      
                                    >
                                      Edit
                                    </li>
                                 
                                  </ul>
                                </div>
                                  <div
                                    key={index}
                                    className="block chat-list"
                                    // onClick={() => openCompanySide(item)}
                                  >
                                    <div
                                      className="imgBox"
                                      style={{ backgroundColor: "#CFCFCF" }}
                                    >
                                      <div
                                        className=" text-uppercase"
                                        style={{ paddingTop: "12px" }}
                                      >
                                        {/* {item.person_name[0] +
                                        item.person_name[1]} */}
                                      </div>
                                    </div>
                                    <div className="h-text">
                                      <div
                                        className="head"
                                        style={{
                                          paddingBottom: "unset",
                                          borderBottom: "hidden",
                                        }}
                                        onClick={() => openCompanySide(item)}
                                      >
                                        <h4 >{item.company_name}</h4>
                                        <p className="time">
                                          {item.created_date_time
                                            ? formatDate(item.created_date_time)
                                            : ""}
                                        </p>
                                      </div>
                                      <div
                                        className="head"
                                        style={{
                                          paddingBottom: "unset",
                                          borderBottom: "hidden",
                                        }}
                                        onClick={() => openCompanySide(item)}
                                      >
                                        <h4 className="text-start">
                                          Invitation key :{item.invitation_key}
                                        </h4>
                                      </div>

                                      <div className="message-chat">
                                        <div className="tick-icon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            width="20"
                                            height="20"
                                            aria-label="read"
                                            className="white-tick"
                                          >
                                            <path
                                              fill="currentColor"
                                              d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                                            ></path>
                                          </svg>
                                        </div>
                                        {/* EMAIL,CONTARY,STATE,CITY,NUMBER */}
                                        <div className="chat-text-icon" onClick={() => openCompanySide(item)}>
                                          {/* <div className="d-flex"> */}
                                          <span className="thanks">
                                            {item.company_contact}
                                            {item.company_contact ? "," : ""}
                                          </span>
                                          <span className="thanks">
                                            {item.company_email}
                                            {item.company_email ? "," : ""}
                                          </span>
                                          {/* </div> */}
                                        </div>

                                        <button
                                          className="icon-more"
                                          onClick={() =>
                                            toggleDropdownCompany(item.id)
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 19 20"
                                            width="19"
                                            height="20"
                                            className="hide animate__animated animate__fadeInUp"
                                          >
                                            <path
                                              fill="currentColor"
                                              d="M3.8 6.7l5.7 5.7 5.7-5.7 1.6 1.6-7.3 7.2-7.3-7.2 1.6-1.6z"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                      {/* <div className="message-chat ">
                                      <div className="chat-text-icon">
                                        <span className="thanks">
                                          {item.country_name}
                                          {item.country_name ? "," : ""}
                                        </span>
                                        <span className="thanks">
                                          {item.state_name}
                                          {item.state_name ? "," : ""}
                                        </span>
                                        <span className="thanks">
                                          {item.city_name}
                                        </span>
                                      </div>
                                      <div>
                                        <span
                                          style={{
                                            backgroundColor:
                                              item.source_name_color
                                                ? item.source_name_color
                                                : "#eeeeee ",
                                          }}
                                          className="badge rounded-pill"
                                        >
                                          {item.source_name}
                                        </span>
                                      </div>
                                    </div> */}
                                    </div>
                                  </div>
                                </>
                              ))}
                        </>
                        {/* {searchTerm && noDataFound && (
                        <p className="no_found">No data found</p>
                      )} */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* {isDeleteConfirmation && (
            <ConfirmationModal
              show={isDeleteConfirmation}
              onHide={() => setIsDeleteConfirmation(false)}
              handleSubmit={() =>
                handleDeleteSourceType(
                  hasIdAvail,
                  setIsDeleteConfirmation,
                  setSourceOfTypesLists
                )
              }
              title={"Delete this Source Of Type"}
              message={"Are you sure you want delete is Source Of Type? "}
              btn1="CANCEL"
              btn2="DELETE"
            />
          )} */}
              </div>
              <CreateCompanyView
                show={isCreateCompany}
                onHide={() => setIsCreateCompany(false)}
                // setContact={setUsers1}
                setRefresh={setRefresh}
                headerName={"Create Company"}
              />
               <CreateCompanyView
                show={showEditCompany}
                onHide={() => setShowEditCompany(false)}
                companyToEdit={companyToEdit}
                setRefresh={setRefresh}
                headerName={"Edit Company"}

              />
              {isJoinConfirmation && (
                <JoinModal
                  show={isJoinConfirmation}
                  onHide={() => setIsJoinConfirmation(false)}
                  handleSubmit={handleJoinCompany}
                  title={"Join Company"}
                  message={"Are you sure you want delete is Source Of Type? "}
                  btn1="CANCEL"
                  btn2="join"
                />
              )}
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default ListCompanyView;
