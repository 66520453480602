import { toast } from "react-toastify";
import { TReactSetState } from "../../../helpers/AppType";
import axiosInstance from "../../../services/axiosInstance";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../helpers/AppConstants";

export interface ICompany {
  id: number;
  company_name: string;
  company_email: string;
  company_contact: string;
  created_date_time: string;
  invitation_key:string
}

export interface ICompanyTeam {
  id: number;
  username: string;
  recovery_email: string;
  recovery_mobile: string;
  created_date_time: string;
 
  //   gender: 1;
  //   profile_pic: "1723179600000.jpeg";
  //   s_timestemp: "2024-08-13T10:32:17.000Z";
  //   session_token: "";
  //   login_pin: "";
  //   otp: 72084;
  //   isDelete: 0;
  //   isActive: 1;
}
export const handleDeleteSourceType = async (
  sourceOfId: number | undefined,
  setIsDeleteConfirmation: TReactSetState<boolean>,
  setCompanyLists: TReactSetState<ICompany[]>
) => {
  const requestData = {
    table: "source_types",
    where: `{"id":${sourceOfId}}`,
    data: `{"isDelete":"1"}`,
  };
  try {
    const data = await axiosInstance.post("commonUpdate", requestData);
    if (data.data.code === 200) {
      if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        setIsDeleteConfirmation(false);
        // fetchCompanyApi(setCompanyLists);
      } else {
        toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const fetchCompanyApi = async (
  setCompanyLists: TReactSetState<ICompany[]>,
  term:string,
  setNoDataFound : TReactSetState<any>
) => {
  const token = await localStorage.getItem("token");
  const getUUID = await localStorage.getItem("UUID");
  const requestData = {
    a_application_login_id: getUUID,
    searchTerm:term
  };
  try {
    const data = await axiosInstance.post("company", requestData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    if (data.data.ack !== DEFAULT_STATUS_CODE_SUCCESS) {
      setCompanyLists([]);
      // toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  
    
    setCompanyLists(data.data.data.item);
    setNoDataFound(data.data.data.item.length === 0);
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const fetchCompanyTeamApi = async (
  setCompanyTeamLists: TReactSetState<ICompanyTeam[]>,
  companyMastersId: number
) => {
  const token = await localStorage.getItem("token");

  const requestData = {
    company_masters_id: companyMastersId,
  };
  try {
    const data = await axiosInstance.post(
      "my-team",
      requestData,

      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    if (data.data.ack !== DEFAULT_STATUS_CODE_SUCCESS) {
      setCompanyTeamLists([]);
      // toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
    setCompanyTeamLists(data.data.data.item);
   
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
export const createVaiLink = async (
  data: string,
  setIsJoinConfirmation: TReactSetState<boolean>
) => {
  // if (
  //   !(await checkDuplication(
  //     values.company_contact,
  //     "company_masters",
  //     "company_contact"
  //   ))
  // ) {
  const getUUID = await localStorage.getItem("UUID");
  const token = await localStorage.getItem("token");

  const requestData = {
    a_application_login_id: getUUID,
    invitation_key: data,
  };
  // setRefresh(false)
  try {
    const { data } = await axiosInstance.post("invitation_key", requestData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    if (data.code === 200) {
      if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        // setRefresh(true)
        setIsJoinConfirmation(false);
        toast.success(data.ack_msg);
      }
    } else {
      setIsJoinConfirmation(true);
      toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
  // } else {
  //   toast.error("Company number is already used");
  // }
};


export const companyTeamListRemove = async (
  companyTeamId: number | undefined,
  setIsRemoveCompanyTeamListConfirmation: TReactSetState<boolean>,
  setCompanyTeamLists: TReactSetState<ICompanyTeam[]>,
  companyMastersId:number
) => {
  const token = await localStorage.getItem("token");
  try {

    const data = await axiosInstance.post(
      "my-team-remove",
      {
        id: companyTeamId,
        company_masters_id:companyMastersId,
        isDelete:1
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );;
    if (data.data.code === 200) {
      if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        setIsRemoveCompanyTeamListConfirmation(false);
        fetchCompanyTeamApi(setCompanyTeamLists ,companyMastersId);
      } else {
        toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};