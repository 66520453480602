import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  createInquiry,
  createInquiryInitialValues,
  createInquiryValidationSchema,
  ICreateInquiry,
  updateInquiry,
} from "./CreateInquiryController";
import axiosInstance from "../../../services/axiosInstance";
import { fetchCategoryApiForProduct } from "../../left-side/header/Setting/product/ProductController";
import { TReactSetState } from "../../../helpers/AppType";
import FormikCustomSearchDropdown from "../../../components/FormikCustomSearchDropdown";
import { SingleValue } from "react-select";
import { IOption } from "../../../helpers/AppoInterface";
interface IPropsCreateInquiry {
  show: boolean;
  onHide: () => void;
  contactData?: any;
  setContact: any;
  contact_id: any;
  headerName: string;
}
const CreateInquiryView = ({
  show,
  onHide,
  setContact,
  contactData,
  contact_id,
  headerName,
}: IPropsCreateInquiry) => {
  const [categoryList, setCategoryList] = useState<any>([]);
  const [productList, setProductList] = useState<any>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>();

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent default behavior for Enter key
      }
    };

    if (show) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [show]);

  const [sourceOfTypesList, setSourceOfTypesList] = useState([]);
  const requirementTypesList = [
    { id: "0", requirement_name: "One time" },
    { id: "1", requirement_name: "Recurring" },
  ];

  // const [addOptionList, setAddOptionList] = useState();

  const handleSubmit = async (values: ICreateInquiry) => {
    // setContact(false);
    if (contactData?.id) {
      updateInquiry(values, setContact, contactData, contact_id);
    } else {
      createInquiry(values, setContact, contact_id);
    }
    onHide(); // Example: Close modal after submission using onHide prop
  };

  const fetchSourceTypeApi = async () => {
    const getUUID = await localStorage.getItem("UUID");

    const requestData = {
      table: "source_types",
      columns: "id,source_name",
      // where: `{"isDelete": "0","a_application_login_id":"${getUUID}"}`,
      where: ["isDelete=0", `a_application_login_id=${getUUID}||0 ,"id=-1"`],
      request_flag: 0,
    };

    try {
      const response = await axiosInstance.post("commonGet", requestData);

      setSourceOfTypesList(response.data.data); // Assuming API response is an array of countries
    } catch (error) {
      console.error("Error fetching countries:", error);
      // Handle error (e.g., show error message, clear filtered list)
      setSourceOfTypesList([]);
    }
  };

  const fetchCategoryApiForInquiry = async () => {
    const getUUID = await localStorage.getItem("UUID");
    const requestData = {
      table: "categories",
      columns: "id,category_name",
      // where: `{"isDelete":"0","a_application_login_id":"${getUUID}"}`,
      where: ["isDelete=0", `a_application_login_id=${getUUID}||0`],
      request_flag: 0,
      order: `{"id":"DESC"}`,
    };
    try {
      const response = await axiosInstance.post("commonGet", requestData);

      setCategoryList(response.data.data); // Assuming API response is an array of countries
    } catch (error) {
      console.error("Error fetching countries:", error);
      // Handle error (e.g., show error message, clear filtered list)
      setCategoryList([]);
    }
  };
  const handleSourceTypeChange = async (event: any, setFieldValue: any) => {
    const { value } = event.target;
    // console.log("valuevalue", value);

    setFieldValue("source_type_id", value);
    // setSourceOfTypesList(value);
  };
  // console.log("fetchData",selectedCountryId);
  const fetchProductApiForInquiry = async () => {
    // console.log("fetchData",selectedCountryId);
    const getUUID = await localStorage.getItem("UUID");
    const requestData = {
      table: "products",
      columns: "id,product_name",
      // where: `{"isDelete":"0","a_application_login_id":"${getUUID}"}`,
      where: [
        "isDelete=0",
        `category_id=${selectedCategoryId}`,
        `a_application_login_id=${getUUID}||0`,
      ],
      request_flag: 0,
      order: `{"id":"DESC"}`,
    };
    try {
      const response = await axiosInstance.post("commonGet", requestData);

      setProductList(response.data.data); // Assuming API response is an array of countries
    } catch (error) {
      console.error("Error fetching countries:", error);
      // Handle error (e.g., show error message, clear filtered list)
      setProductList([]);
    }
  };

  // const handleCountriesChange = async (event: any, setFieldValue: any) => {
  //   const { value } = event.target;

  //   if (value) {
  //     setFieldValue("category_id", value);
  //     setSelectedCategoryId(value);
  //   } else {
  //     setFieldValue("category_id", "");
  //     setSelectedCategoryId(undefined);
  //     setProductList([]);
  //   }
  // };
  const handleCountriesChange = async (
    selectedOption: SingleValue<IOption>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    if (selectedOption) {
      setFieldValue("country", selectedOption.value);
      setSelectedCategoryId(selectedOption.value as number);
    } else {
      setFieldValue("country", "");
      setSelectedCategoryId(undefined);
      setProductList([]);
    }
  };                            
  useEffect(() => {
    const fetchData = async () => {
      await fetchSourceTypeApi();
      if (contactData?.category_id && !selectedCategoryId) {
        setSelectedCategoryId(contactData?.category_id || undefined);
        await fetchProductApiForInquiry();
        await fetchCategoryApiForInquiry();
      } else if (contactData?.category_id) {
        setSelectedCategoryId(selectedCategoryId || undefined);
        await fetchProductApiForInquiry();
        await fetchCategoryApiForInquiry();
      } else {
        if (selectedCategoryId) {
          setSelectedCategoryId(undefined);
          await fetchProductApiForInquiry();
        } else {
          await fetchSourceTypeApi();
          await fetchCategoryApiForInquiry();
        }
      }
    };

    fetchData();
  }, [contactData?.category_id, selectedCategoryId]);
  const categoryOptions = categoryList.map((itemState: any) => ({
    value: itemState.id,
    label: itemState.category_name,
  }));
  const productOptions = productList.map((itemState: any) => ({
    value: itemState.id,
    label: itemState.product_name,
  }));
  const requirementTypesOptions = requirementTypesList.map((itemState) => ({
    value: itemState.id,
    label: itemState.requirement_name,
  }));
  const sourceTypeOptions = sourceOfTypesList.map((itemState:any) => ({
    value: itemState.id,
    label: itemState.source_name,
  }));
  return (
    <React.Fragment>
      {show && (
        <div className="modal1 ">
          <div className="modal-content1">
            <span className="close" onClick={onHide}>
              &times;
            </span>
            <h2 className="modal-title1 form_header_text">{headerName}</h2>
            <p className="text-center " style={{ color: "#999" }}>
              Please enter your inquiry detail.
            </p>
            <Formik
              enableReinitialize
              initialValues={createInquiryInitialValues(contactData)}
              validationSchema={createInquiryValidationSchema()}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, validateForm }) => (
                <Form>
                  <div className="  mt-3    d-flex justify-content-center">
                    {/* {errors.submit && <div className="error">{errors.submit}</div>} */}

                    <div className="mb-3 py-4  ">
                      <div className="row  mx-0 px-2 gy-3  d-flex justify-content-center">
                        <div className="col-6 col-md-6 ">
                          <div className="form-group">
                            <label
                              htmlFor="category_id"
                              className="pb-2 mb-1 form_label"
                            >
                              Category Name
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <FormikCustomSearchDropdown
                              name="category_id"
                              options={categoryOptions}
                              className={`  ${
                                errors.category_id &&
                                touched.category_id &&
                                "is-invalid input-box-error"
                              }`}
                              onChange={handleCountriesChange}
                            />

                            {/* <Field
                              name="category_id"
                              render={({ field, form }: any) => (
                                <React.Fragment>
                                  <select
                                    {...field}
                                    className={`form-control font-size-15 rounded-1 ${
                                      form.errors.category_id &&
                                      form.touched.category_id &&
                                      "is-invalid input-box-error"
                                    }`}
                                    onChange={(e) =>
                                      handleCountriesChange(
                                        e,
                                        form.setFieldValue
                                      )
                                    }
                                  >
                                    <option value="">select category</option>

                                    {categoryList &&
                                      categoryList.map((categoryItem: any) => (
                                        <option
                                          key={categoryItem.id}
                                          value={categoryItem.id}
                                        >
                                          {categoryItem.category_name}
                                        </option>
                                      ))}
                                  </select>
                                </React.Fragment>
                              )}
                            /> */}
                            <ErrorMessage
                              name="category_id"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-6 col-md-6 ">
                          <div className="form-group">
                            <label htmlFor="name " className="pb-2 form_label">
                              Product Name
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <FormikCustomSearchDropdown
                              name="product_id"
                              options={productOptions}
                              className={`  ${
                                errors.product_id &&
                                touched.product_id &&
                                "is-invalid input-box-error"
                              }`}
                              // onChange={handleCountriesChange}
                            />
                            {/* <Field
                              name="product_id"
                              render={({ field, form }: any) => (
                                <React.Fragment>
                                  <select
                                    {...field}
                                    className={`form-control font-size-15 rounded-1 ${
                                      form.errors.product_id &&
                                      form.touched.product_id &&
                                      "is-invalid input-box-error"
                                    }`}
                                    onChange={(e) =>
                                      handleProductChange(
                                        e,
                                        form.setFieldValue
                                      )
                                    }
                                  >
                                    <option value="">select Product</option>

                                    {productList &&
                                      productList.map((categoryItem: any) => (
                                        <option
                                          key={categoryItem.id}
                                          value={categoryItem.id}
                                        >
                                          {categoryItem.product_name}
                                        </option>
                                      ))}
                                  </select>
                                 
                                </React.Fragment>
                              )}
                            /> */}
                            <ErrorMessage
                              name="product_id"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-6 col-md-6 ">
                          <div className="form-group">
                            <label htmlFor="name " className="pb-2 form_label">
                              Required Quantity
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Field
                              type="text"
                              name="qty"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.qty &&
                                touched.qty &&
                                "is-invalid input-box-error"
                              }`}
                            />
                            <ErrorMessage
                              name="qty"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-6 col-md-6 ">
                          <div className="form-group">
                            <label htmlFor="static" className="mb-1 form_label">
                              Requirement Type
                            </label>
                            <FormikCustomSearchDropdown
                              name="static"
                              options={requirementTypesOptions}
                              className={`  ${
                                errors.static &&
                                touched.static &&
                                "is-invalid input-box-error"
                              }`}
                              // onChange={handleCountriesChange}
                            />
                            {/* <Field
                              as="select"
                              name="static"
                              className={`form-control font-size-15 rounded-1  ${
                                errors.static &&
                                touched.static &&
                                "is-invalid input-box-error"
                              }`}
                              render={({ field, form }: any) => (
                                <React.Fragment>
                                  <select
                                    {...field}
                                    className={`form-control font-size-15 rounded-1 ${
                                      form.errors.static &&
                                      form.touched.static &&
                                      "is-invalid input-box-error"
                                    }`}
                                    // onChange={(e) =>
                                    //   handleSourceTypeChange(
                                    //     e,
                                    //     form.setFieldValue
                                    //   )
                                    // }
                                  >
                                    <option value="">
                                      select requirement type
                                    </option>
                                    {requirementTypesList &&
                                      requirementTypesList.map(
                                        (itemState: any) => (
                                          <option
                                            key={itemState.id}
                                            value={itemState.id}
                                          >
                                            {itemState.requirement_name}
                                          </option>
                                        )
                                      )}
                                  </select>
                                </React.Fragment>
                              )}
                            ></Field> */}
                            <ErrorMessage
                              name="static"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-6 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="description"
                              className="pb-2 form_label"
                            >
                              Description <span className="text-danger">*</span>
                            </label>
                            <Field
                              as="textarea"
                              name="description"
                              className={`form-control ${
                                errors.description && touched.description
                                  ? "is-invalid input-box-error"
                                  : ""
                              }`}
                              rows={1}
                            />
                            <ErrorMessage
                              name="description"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-6 ">
                          <div className="form-group">
                            <label htmlFor="city" className="mb-1 form_label">
                              Source type
                            </label>
                            <FormikCustomSearchDropdown
                              name="source_type_id"
                              options={sourceTypeOptions}
                              className={`  ${
                                errors.source_type_id &&
                                touched.source_type_id &&
                                "is-invalid input-box-error"
                              }`}
                              // onChange={handleCountriesChange}
                            />
                            {/* <Field
                              as="select"
                              name="source_type_id"
                              className={`form-control font-size-15 rounded-1  ${
                                errors.source_type_id &&
                                touched.source_type_id &&
                                "is-invalid input-box-error"
                              }`}
                              render={({ field, form }: any) => (
                                <React.Fragment>
                                  <select
                                    {...field}
                                    className={`form-control font-size-15 rounded-1 ${
                                      form.errors.source_type_id &&
                                      form.touched.source_type_id &&
                                      "is-invalid input-box-error"
                                    }`}
                                    onChange={(e) =>
                                      handleSourceTypeChange(
                                        e,
                                        form.setFieldValue
                                      )
                                    }
                                  >
                                    <option value="">select source type</option>
                                    {sourceOfTypesList &&
                                      sourceOfTypesList.map(
                                        (itemState: any) => (
                                          <option
                                            key={itemState.id}
                                            value={itemState.id}
                                          >
                                            {itemState.source_name}
                                          </option>
                                        )
                                      )}
                                  </select>
                                </React.Fragment>
                              )}
                            ></Field> */}
                            <ErrorMessage
                              name="source_type_id"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-6"></div>
                        <div className="col-12 col-12 pt-4 d-flex justify-content-center">
                          {/* px-4 me-2 py-2 */}
                          <button
                            className="border border-1 bg-danger px-4 me-2 py-2 text-light rounded-1 form_label"
                            // onClick={() => compareValues(values)}
                            onClick={onHide}
                          >
                            Close
                          </button>
                          {/* px-4 py-2 ms-2 */}
                          <button
                            type="submit"
                            className="btn btn-primary px-4 py-2 ms-2  text-light form_label rounded-1"
                            // disabled={isSubmitting}
                            style={{
                              backgroundColor: "#f58634",
                            }}
                          >
                            Save Inquiry
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CreateInquiryView;
