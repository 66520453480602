import React, { useEffect, useRef, useState } from "react";
import {
  createRescheduleReminder,
  fetchReminderApi,
  handleDeleteReminder,
  IReminderList,
} from "./ListReminderController";
import {
  formatDate,
  isCurrentDateTime,
} from "../../../../common/SharedFunction";
import { TOnChangeInput } from "../../../../helpers/AppType";
import SafeHtml from "../../../../components/SafeHtml";
import axiosInstance from "../../../../services/axiosInstance";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../../helpers/AppConstants";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../../components/model/ConfirmationModal";
import ReminderModal from "../../../../components/model/ReminderModal";
interface IPropReminder {
  isReminderOpen: boolean;
  closeReminder: () => void;
}
const ListReminderView = ({ isReminderOpen, closeReminder }: IPropReminder) => {
  const [reminderList, setReminderList] = useState<IReminderList[]>([]);
  const [searchDate, setSearchDate] = useState<string>("");
  const [hasOneData, setHasOneData] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [labelDropdownOpen, setLabelDropdownOpen] = useState<any>(null);
  const [isReminderConfirmationStatus, setIsReminderConfirmationStatus] =
    useState(false);
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const [isReminderConfirmation, setIsReminderConfirmation] = useState(false);
  const [reminderRescheduleData, setReminderRescheduleData] =
    useState<IReminderList>();
  const [isReminderConfirmationStatus1, setIsReminderConfirmationStatus1] =
    useState<IReminderList>();
  const dropdownContactRef = useRef<Record<number, HTMLUListElement | null>>(
    {}
  );

  const handleDateChange = (event: TOnChangeInput) => {
    setSearchDate(event.target.value);
  };
  useEffect(() => {
    fetchReminderApi(setReminderList, searchDate);
  }, [searchDate, isReminderConfirmationStatus]);
  const handleDateClear = () => {
    setSearchDate("");
  };
  const toggleDropdownLabel = (id: number) => {
    setHasOneData(id);
    // setLabelDropdownOpen(!labelDropdownOpen);
    setLabelDropdownOpen((prevId: any) => (prevId === id ? null : id));
  };
  const handleChangeStatusOfReminder1 = (messageData: IReminderList) => {
    setIsReminderConfirmationStatus(true);
    setIsReminderConfirmationStatus1(messageData);
  };
  const handleChangeStatusOfReminder = async () => {
    const getUUID = await localStorage.getItem("UUID");

    const requestData = {
      table: "reminder_messages",
      where: `{"a_application_logins_id":"${getUUID}","id":"${isReminderConfirmationStatus1?.id}"}`,
      data: `{"status":"1"}`,
    };
    try {
      const { data } = await axiosInstance.post("commonUpdate", requestData);
      if (data.code === 200) {
        if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
          // console.log("dadad", data);
          setIsReminderConfirmationStatus(false);

          // console.log("data", data);
        } else {
          toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
        }
      }
    } catch (error: any) {
      toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  };
  const handleClickOutside = (event: { target: any }) => {
    const clickedOutside = Object.values(dropdownContactRef.current).every(
      (ref) => ref && !ref.contains(event.target)
    );
    if (clickedOutside) {
      setLabelDropdownOpen(null);
    }
  };
  useEffect(() => {
    if (labelDropdownOpen !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [labelDropdownOpen]);
  const handleReminder = async (data: {
    dateTime: string;
    remark: string;
    status: string;
  }) => {
    if (data.dateTime.trim() && data.remark.trim()) {
      createRescheduleReminder(
        reminderRescheduleData?.id,
        data,
        setIsReminderConfirmation,
        setLoading,
        setReminderList
      );
    } else {
      setIsReminderConfirmation(true);
    }
  };
  const handleChangeReminderReschedule = (messageData: IReminderList) => {
    setIsReminderConfirmation(true);
    setReminderRescheduleData(messageData);
  };

  const handelRefreshReminder = () => {
    fetchReminderApi(setReminderList, searchDate);
  };
  return (
    <>
      {isReminderOpen ? (
        <>
          <div
            className="notifications animate__animated animate__fadeInLeft"
            style={{ overflow: "hidden" }}
            id="group"
          >
            {/* <!-- Header --> */}
            <div className="header-Chat">
              {/* <!-- Icons --> */}
              <div className="ICON">
                <button className="icons" onClick={closeReminder}>
                  <span className="">
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className=""
                    >
                      <path
                        fill="currentColor"
                        d="m12 4 1.4 1.4L7.8 11H20v2H7.8l5.6 5.6L12 20l-8-8 8-8z"
                      ></path>
                    </svg>
                  </span>
                </button>
              </div>

              <div className="newText">
                <h2>Reminder List</h2>
              </div>

              <button
                className="icons pP text-end"
                style={{ marginBottom: "50px", marginLeft: "auto" }}
                onClick={handelRefreshReminder}
              >
                <span className="text-white">
                  <svg width="30" height="30" viewBox="0 0 50 50">
                    <path
                      fill="currentColor"
                      d="M25 38c-7.2 0-13-5.8-13-13 0-3.2 1.2-6.2 3.3-8.6l1.5 1.3C15 19.7 14 22.3 14 25c0 6.1 4.9 11 11 11 1.6 0 3.1-.3 4.6-1l.8 1.8c-1.7.8-3.5 1.2-5.4 1.2z"
                    />
                    <path
                      fill="currentColor"
                      d="M34.7 33.7l-1.5-1.3c1.8-2 2.8-4.6 2.8-7.3 0-6.1-4.9-11-11-11-1.6 0-3.1.3-4.6 1l-.8-1.8c1.7-.8 3.5-1.2 5.4-1.2 7.2 0 13 5.8 13 13 0 3.1-1.2 6.2-3.3 8.6z"
                    />
                    <path fill="currentColor" d="M18 24h-2v-6h-6v-2h8z" />
                    <path fill="currentColor" d="M40 34h-8v-8h2v6h6z" />
                  </svg>
                </span>
              </button>
            </div>
            <div className="col-12 d-flex">
              <div className="col-11  px-3">
                <label className="">Filter date :</label>
                <input
                  type="date"
                  value={searchDate}
                  onChange={handleDateChange}
                  className="form-control font-size-15 rounded-1"
                />
              </div>
              <div className="col-1 d-flex align-items-center">
                <button className="pt-2" onClick={handleDateClear}>
                  <svg
                    height="22px"
                    viewBox="0 -960 960 960"
                    width="22px"
                    fill="currentColor"
                  >
                    <path d="m592-481-57-57 143-182H353l-80-80h487q25 0 36 22t-4 42L592-481ZM791-56 560-287v87q0 17-11.5 28.5T520-160h-80q-17 0-28.5-11.5T400-200v-247L56-791l56-57 736 736-57 56ZM535-538Z" />
                  </svg>
                </button>
              </div>
            </div>

            <div className="chats">
              {loading ? (
                ""
              ) : (
                <>
                  {reminderList &&
                    reminderList.map((item, index) => {
                      const currentDate = new Date();
                      const reminderDate = new Date(item.reminder_data_time);
                      const isDue = reminderDate < currentDate;
                      return (
                        <>
                          <div>
                            <ul
                              className={`labelDropLeft ${
                                hasOneData === item.id && labelDropdownOpen
                                  ? "isVisible"
                                  : "isHidden"
                              } `}
                              // onClick={(e) => e.stopPropagation()}
                              ref={(el) =>
                                (dropdownContactRef.current[item.id] = el)
                              }
                              style={{ marginTop: "8%" }}
                            >
                              <li
                                className="listItem"
                                role="button"
                                // onClick={handleModalOpen}
                                onClick={() => setIsDeleteConfirmation(true)}
                              >
                                Delete
                              </li>
                              {/* {item.status !== 1 ?/ */}
                              <li
                                className="listItem"
                                role="button"
                                onClick={() =>
                                  handleChangeStatusOfReminder1(item)
                                }
                              >
                                Complete
                              </li>
                              {/* : ""} */}
                              <li
                                className="listItem"
                                role="button"
                                onClick={() =>
                                  handleChangeReminderReschedule(item)
                                }
                              >
                                Reschedule
                              </li>
                            </ul>
                          </div>
                          <button
                            key={index}
                            className="block chat-list"
                            //   onClick={() => openRightSide(item)}
                          >
                            <div className="h-text">
                              <div className="col-12 d-flex">
                                <div className="col-10 text-start">
                                  {/* <div className="chat-text-icon"> */}

                                  <span className="reminder_list_text ">
                                    <b>Person Name :</b> {item.person_name}
                                    <span
                                      style={{
                                        backgroundColor: "red",
                                        float: "right",
                                      }}
                                      className="badge rounded-pill text-end"
                                    >
                                      {isDue ? "Due" : ""}
                                    </span>
                                  </span>

                                  {/* <span className="thanks">{item.city_name}</span> */}

                                  {/* </div> */}

                                  <span className="d-flex">
                                    <span className="reminder_list_text">
                                      <b> Messages :</b>{" "}
                                    </span>{" "}
                                    <span className="reminder_list_text">
                                      <SafeHtml
                                        htmlContent={item.contact_message}
                                      />
                                    </span>
                                  </span>

                                  {/* <div>
                          <span
                              style={{
                                backgroundColor: "red"
                              }}
                            className="badge rounded-pill"
                          >
                            {isDue ? "Due" : "kunj"}
                          </span>
                        </div> */}
                                </div>
                                <div className="col-2">
                                  <button
                                    className="icon-more"
                                    onClick={() => toggleDropdownLabel(item.id)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 19 20"
                                      width="19"
                                      height="20"
                                      className="hide animate__animated animate__fadeInUp"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M3.8 6.7l5.7 5.7 5.7-5.7 1.6 1.6-7.3 7.2-7.3-7.2 1.6-1.6z"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>

                              <div className="head">
                                <h4
                                  className="text-start"
                                  style={{
                                    wordWrap: "break-word",
                                    width: "50%",
                                  }}
                                >
                                  {" "}
                                  <b>Remark : </b>
                                  {item.remark}
                                </h4>
                                <p
                                  className="time"
                                  // style={{
                                  //   color: item.status === 0 ? "#F45156" : "black",
                                  // }}
                                >
                                  <b>Reminder Date :</b>
                                  {item.reminder_data_time
                                    ? formatDate(item.reminder_data_time)
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </button>
                        </>
                      );
                    })}
                </>
              )}
            </div>
            {/* <!-- Search Bar --> */}

            {/* <!-- Chats --> */}
          </div>
          {isReminderConfirmationStatus && (
            <ConfirmationModal
              show={isReminderConfirmationStatus}
              onHide={() => setIsReminderConfirmationStatus(false)}
              handleSubmit={handleChangeStatusOfReminder}
              title={"Are you sure you want complete this Reminder?"}
              message={`Remark : ${
                isReminderConfirmationStatus1 &&
                isReminderConfirmationStatus1.remark
              }`}
              btn1="CANCEL"
              btn2="Complete Reminder Now"
              message1={`Reminder Date : ${
                isReminderConfirmationStatus1 &&
                formatDate(isReminderConfirmationStatus1.reminder_data_time)
              }`}
            />
          )}
          {isDeleteConfirmation && (
            <ConfirmationModal
              show={isDeleteConfirmation}
              onHide={() => setIsDeleteConfirmation(false)}
              handleSubmit={() =>
                handleDeleteReminder(
                  hasOneData,
                  setIsDeleteConfirmation,
                  setReminderList
                )
              }
              title={"Delete this Reminder"}
              message={"Are You Sure You Want To Delete This Reminder?"}
              btn1="CANCEL"
              btn2="DELETE"
            />
          )}
          {isReminderConfirmation && (
            <ReminderModal
              show={isReminderConfirmation}
              onHide={() => setIsReminderConfirmation(false)}
              handleSubmit={handleReminder}
              title={" Reminder Reschedule  "}
              message={"Are you sure you want delete is message? "}
              btn1="CANCEL"
              btn2="set Reminder"
              remarkMsg={reminderRescheduleData?.remark}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default ListReminderView;
