import * as Yup from "yup";
import axiosInstance from "../../../services/axiosInstance";
import { toast } from "react-toastify";
import { TReactSetState } from "../../../helpers/AppType";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../helpers/AppConstants";

export interface IRegistration {
  mobile_number: string;
  username: string;
}
export const registrationInitialValues = {
  mobile_number: "",
  username: "",
};

export const ContactNumberRegex = /^\+?[0-9]{2} ?[0-9]{6,12}$/;
// Match strings with characters and numbers (alphanumeric)
const regexAlphanumeric = /^(?=.*[a-zA-Z\s])(?=.*[0-9])[a-zA-Z0-9]+$/;
// Match strings with only characters (letters)
const regexOnlyCharacters = /^[a-zA-Z\s]+$/;

// Combine the two regex patterns using the OR operator (|)
const regexCombined = new RegExp(
  `(${regexAlphanumeric.source})|(${regexOnlyCharacters.source})`
);

export const registrationValidationSchema = () =>
  Yup.object().shape({
    mobile_number: Yup.string()
      // .matches(ContactNumberRegex, ("Contact Number Match"))
      .max(10, "max number 10")
      .min(10, "min number 10")
      .required(" Mobile Number Required"),
    username: Yup.string()
      .matches(regexCombined, "Username must contain only letters")
      .max(20, "max char 20")
      .min(2, "min char 2")
      .required("Your Name Required"),
  });

export const registrationSubmit = async (
  values: IRegistration,
  setShowOtp: TReactSetState<boolean>,
  setShowMenu: TReactSetState<boolean>
) => {
  try {
    const response = await axiosInstance.post("register", {
      username: values.username,
      contact_number: values.mobile_number,
      // request_flag :0
    });

    if (response.data.code === 200) {
      if (response.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        setShowOtp(false);
        setShowMenu(false);
      } else {
        setShowOtp(true);
        toast.error(response.data.ack_msg);
      }
    } else {
      setShowOtp(true);
      toast.error(response.data.ack_msg);
    }
  } catch (error: any) {
    toast.error(error.response.data.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
