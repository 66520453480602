import { toast } from "react-toastify";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../helpers/AppConstants";
import { TReactSetState } from "../../helpers/AppType";
import axiosInstance from "../../services/axiosInstance";

export interface IMessageList {
  id: number;
  description: string;
  current_status: number;
  created_date_time: Date;
  s_timestemp?: Date;
  company_masters_id?: number;
  contact_masters_id: number;
  message_side?: number;
  lable?: string;
  message_type_id: number;
  isReminder: number;
  reminder_data_time: string;
  reminder_remark: string;
  media_name: string;
  media_url: string;
  application_login_name:string
  contact_name:string
  isDelete?: number;
}
export interface IInsertObj {
  message_side?: number;
  description: string;
  contact_masters_id?: number;
}

export interface IReminder {
  dateTime: string;
  remark: string;
  status: string;
}
export const fetchMessageData = async (
  setNoDataFound: TReactSetState<boolean>,
  term: string,
  setLoading: TReactSetState<boolean>,
  setMessageList: TReactSetState<IMessageList[]>,
  setHasMore: TReactSetState<boolean>,
  currentPage: number,
  contactMastersId?: number,
  token?: string | null,
  itemsPerPage?: any
) => {
  try {
    // console.log("currentPage",currentPage);

    // setLoading(true);
    //  const   token =  await localStorage.getItem("token");

    // const itemsPerPage = 5;
    // const start = currentPage * itemsPerPage;
    const getUUID = await localStorage.getItem("UUID");

    const start = 0;

    const response = await axiosInstance.post(
      "messageHistory",
      {
        contact_masters_id: contactMastersId,
        ul: start, // Upper limit based on page number
        ll: itemsPerPage, // Lower limit based on page number
        a_application_login_id: getUUID,
        searchTerm: term,
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    if (response.data.code === 200) {
      if (response.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        setLoading(true);

        const newItems = response.data.data.item;
        setMessageList(newItems); // Prepend new messages to the current list

        // setMessageList((prevUsers) => [...prevUsers ,...response.data.data.item ]); // Prepend new messages to the current list
        if (newItems.length < itemsPerPage) {
          setHasMore(false); // No more data to load
        }
      } else {
        toast.error(response.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    } else {
      toast.error(response.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
    setNoDataFound(response.data.data.item.length === 0);
  } catch (error: any) {
    // Handle error cases
    toast.error(error?.response.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  } finally {
    setTimeout(() => {
      setLoading(false); // Set loading to false after minimum time
    }, 1000); // 1000 milliseconds (1 seconds)
  }
};

export const insertMessage = async (insertObj: IInsertObj) => {
  const getUUID = await localStorage.getItem("UUID");
  const date = new Date();
  const formattedDateTime = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
    date.getHours()
  ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(
    date.getSeconds()
  ).padStart(2, "0")}`;
  const requestData = {
    table: "contact_message_histories",
    data: JSON.stringify({
      // message_side: insertObj.message_side,
      // a_application_id: Number(getUUID),
      // description: insertObj.description, // Include the HTML content here
      // contact_masters_id: insertObj.contact_masters_id,
      // created_date_time:formattedDateTime
    }),
    // data: `{"message_side":"${insertObj.message_side}","a_application_id":${Number(getUUID)},"description":"${insertObj.description}","contact_masters_id":"${insertObj.contact_masters_id}"}`,
  };
  console.log("rerererere",requestData.data);
  
  try {
    const data = await axiosInstance.post("commonCreate", requestData);
    if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
      return true;
    } else {
      return false;
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const deleteContact = async (contact_id: number | undefined) => {
  const requestData = {
    table: "contact_masters",
    where: `{"id":${contact_id}}`,
    data: `{"isDelete":"1"}`,
  };
  try {
    const data = await axiosInstance.post("commonUpdate", requestData);
    if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
      return true;
    } else {
      return false;
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const deleteMessages = async (contact_id: number | undefined) => {
  const requestData = {
    table: "contact_message_histories",
    where: `{"contact_masters_id":${contact_id}}`,
    data: `{"isDelete":"1"}`,
  };
  try {
    const data = await axiosInstance.post("commonUpdate", requestData);
    if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
      return true;
    } else {
      return false;
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const createReminder = async (
  insertObj: IReminder,
  contactId: number | undefined,
  messageSideRight: number | undefined,
  setIsReminderConfirmation: TReactSetState<boolean>
) => {
  const getUUID = await localStorage.getItem("UUID");
  const date = new Date(insertObj.dateTime);

  const formattedDateTime = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
    date.getHours()
  ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(
    date.getSeconds()
  ).padStart(2, "0")}`;

  const requestData = {
    table: "reminder_messages",
    data: JSON.stringify({
      a_application_logins_id: Number(getUUID),
      contact_masters_id: contactId,
      // contact_message_histories_id:599,
      contact_message_histories_id: messageSideRight,
      reminder_data_time: formattedDateTime,
      // status:Number(insertObj.status),
      remark: insertObj.remark,
    }),
  };
  try {
    // setIsReminderConfirmation(false)
    const data = await axiosInstance.post("commonCreate", requestData);
    if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
      setIsReminderConfirmation(false);
    } else {
      toast.error(data.data.ack_msg);
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
